:root{
    --ppolOrange: #E8542F;
    --ppolBlack: #141414;
    --ppolWhite: #F5F5F5;
    --ppolTextBlack: #323232;
}

body{
    margin: auto;
    background: linear-gradient(90deg, var(--ppolBlack) 50%, var(--ppolWhite) 50%);
    background-color: var(--ppolWhite);
    max-width: 1920px;
}

button{
    cursor: pointer;
}

/*------ CONFIGURACIÓN DEL SCROLLBAR ------*/
::-webkit-scrollbar{
    width: 10px;
}

::-webkit-scrollbar-track{
    box-shadow: inset 0 0 2px #757575;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb{
    background: #757575;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover{
    background: #5A5A5A;
}

/*------ OCULTAR EL ÍCONO AUTOMÁTICO DE VER CONTRASEÑA DE MICROSOFT EDGE ------*/
::-ms-reveal {
    display: none;
}

/*------ DECLARACIÓN DE FUENTES ------*/
@font-face {
    font-family: gilroyRegular;
    src: url(./fonts/Gilroy-Regular.woff);
    font-display: block;
}

@font-face {
    font-family: gilroyMedium;
    src: url(./fonts/Gilroy-Medium.woff);
    font-display: block;
}

@font-face {
    font-family: gilroySemiBold;
    src: url(./fonts/Gilroy-SemiBold.woff);
    font-display: block;
}

@font-face {
    font-family: gilroyBold;
    src: url(./fonts/Gilroy-Bold.woff);
    font-display: block;
}

@font-face {
    font-family: gilroyExtraBold;
    src: url(./fonts/Gilroy-ExtraBold.woff);
    font-display: block;
}

@font-face {
    font-family: gilroyBlack;
    src: url(./fonts/Gilroy-Black.woff);
    font-display: block;
}

@font-face {
    font-family: gilroyBlackItalic;
    src: url(./fonts/Gilroy-BlackItalic.woff);
    font-display: block;
}

@font-face {
    font-family: interRegular;
    src: url(./fonts/Inter-Regular.ttf);
    font-display: block;
}

@font-face {
    font-family: interMedium;
    src: url(./fonts/Inter-Medium.ttf);
    font-display: block;
}

@font-face {
    font-family: interSemiBold;
    src: url(./fonts/Inter-SemiBold.ttf);
    font-display: block;
}

@font-face {
    font-family: interBold;
    src: url(./fonts/Inter-Bold.ttf);
    font-display: block;
}

@font-face {
    font-family: interBlack;
    src: url(./fonts/Inter-Black.ttf);
    font-display: block;
}

@font-face {
    font-family: nimbusSansLight;
    src: url(./fonts/NimbusSansDOT-Light.woff);
    font-display: block;
}

@font-face {
    font-family: nimbusSans;
    src: url(./fonts/NimbusSansDOT-Regular.woff);
    font-display: block;
}

@font-face {
    font-family: nimbusSansBold;
    src: url(./fonts/NimbusSansDOT-Bold.woff);
    font-display: block;
}

@font-face {
    font-family: nimbusSansBlack;
    src: url(./fonts/NimbusSansDOT-Black.woff);
    font-display: block;
}