.navbarCont{
    width: 50%;
    max-width: 300px;
    height: 100vh;
    background-color: var(--ppolBlack);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    top: 0;
    overflow: hidden;
    overflow-y: auto;
}

.navbarUpSection{
    height: fit-content;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.navbarBottomSection{
    height: fit-content;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.navbarLogoContainer{
    width: 50%;
    height: 35px;
}

.navbarLogo{
    width: 100%;
    height: 100%;
    filter: invert(1);
    -webkit-filter: invert(1);
}

.navbarListBtn{
    width: calc(100% - 16px);
    height: 48px;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding-left: 16px;
    gap: 8px;
    color: #7F7F7F;
    border: 0;
    font-family: interMedium;
    font-size: 16px;
    margin: 0;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    cursor: pointer;
}

.navbarListActive{
    background-color: #414141;
}

.navbarListBtn:hover{
    background-color: #222222;
}

.navbarListCheck{
    width: 14px;
    height: 30px;
    position: absolute;
    left: calc(0% - 7px);
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.navbarListCheckActive{
    background-color: var(--ppolOrange);
}

.navbarProfile{
    background-color: transparent;
    width: 100%;
    height: 54px;
    border: 1px solid #7F7F7F;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    color: #7F7F7F;
}

.navbarProfile:hover{
    border-color: var(--ppolOrange);
}

.navbarProfileCopy{
    background-color: var(--ppolOrange);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
    color: #F5F5F5;
    border-color: var(--ppolOrange);
}

.navbarProfileImg{
    height: 32px;
    width: 32px;
    object-fit: cover;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    padding: 11px;
}

.navbarProfileUsername{
    font-size: 16px;
    font-family: interMedium;
    white-space: nowrap;
    line-height: 20px;
}

@media only screen and (max-width: 1000px) {
    .navbarCont{
        display: none;
    }
}