.navbar{
    z-index: 2;
}

.cardsContainer{
    margin-top: 100px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.mainCardContainer{
    width: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    background-color: transparent;
    gap: 40px;
}

.mainCardContainerCarousel{
    width: 450px;
    height: 580px;
    border-radius: 9px;
}

.mainCardContainerCarousel img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cardInfo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: fit-content;
    width: 320px;
}

.cardInfoTitle{
    font-size: 34px;
    font-family: gilroyBold;
    margin: 0;
    line-height: 105%;
}

.cardInfoPrice{
    font-size: 32px;
    font-family: gilroyBold;
    margin: 0;
    line-height: 105%;
    margin: 9px 0px 32px 0px;
}

.cardInfoPriceMini{
    font-size: 20px;
}

.cardInfoPriceSep{
    font-size: 32px;
    font-family: interRegular;
}

.cardInfoDesc{
    font-size: 14px;
    font-family: interRegular;
    margin: 0px 0px 32px 0px;
}

.cardInfoColor{
    display: flex;
    flex-direction: row;
    width: 275px;
    align-items: center;
    justify-content: space-between;
}

.cardInfoColorBtn{
    width: 22px;
    height: 22px;
    margin: 0;
    padding: 0;
}

.cardCode{
    font-size: 14px;
    font-family: interRegular;
    margin: 16px 0px 64px 0px;
}

.cardCodeTitle{
    font-family: interBold;
}

.cardQuantity{
    width: 308px;
    height: 44px;
    border: 1px solid white;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cardQuantityChange{
    height: 44px;
    width: 44px;
    padding: 0;
    border: 0;
    margin: 0;
    background-color: transparent;
    color: white;
    font-family: gilroyBold;
    font-size: 22px;
    cursor: pointer;
}

.cardQuantityChange:hover{
    color: #E8542F;
}

.cardQuantityNumber{
    font-family: gilroyBold;
    font-size: 22px;
}

.confirmCards{
    width: 308px;
    height: 44px;
    margin-top: 16px;
    color: white;
    background-color: #E8542F;
    border: 0;
    padding: 0;
    border-radius: 9px;
    font-size: 16px;
    font-family: gilroyBold;
    cursor: pointer;
}

.mainCardPersonalization{
    width: 807px;
    height: fit-content;
    font-size: 12px;
    font-family: interRegular;
    margin-top: 40px;
}

.mainCardPersonalizationText{
    margin: 0;
}

.cardShopInfo{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    margin-top: 30px;
}

.customerInfo{
    max-width: 66.6%;
    width: 720px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: transparent;
    gap:50px
}

.personalData{
    display: flex;
    flex-direction: column;
    width: 90%;
    background-color: transparent;
    gap: 15px;
}

.personalDataTitle{
    font-family: gilroyBold;
    font-size: 30px;
    margin: 0px 0px 25px 0px;
}

.dataLabels{
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: transparent;
    gap: 20px;
}

.dataLabelsMid{
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 5px;
    background-color: transparent;
}

.personalDataBtn{
    width: 50%;
    height: 50px;
    padding: 0;
    border: 2px solid white;
    border-radius: 9px;
    background-color: white;
    cursor: pointer;
    font-size: 15px;
    font-family: interBold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.personalDataBtn:hover, .personalDataBtn:focus{
    background-color: #E8542F;
    color: white;
}

.personalDataBtn img{
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.personalDataBtn:hover img, .personalDataBtn:focus img{
    filter: invert(1);
}

.dataLabelsComplete{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    background-color: transparent;
    font-family: interRegular;
}

.dataLabelsComplete iframe{
    border-radius: 12px;
}

.dataLabelsInput{
    padding: 10px;
    width: calc(100% - 24px);
    height: calc(30px);
    background-color: white;
    border-radius: 9px;
    border: 2px solid white;
    outline: none;
    font-family: interRegular;
    font-size: 18px;
}

.dataLabelsInput:hover, .dataLabelsInput:focus{
    border: 2px solid #E8542F
}

.dataLabelsInputAlt:hover, .dataLabelsInputAlt:focus{
    border: 2px solid #415AD2
}

.dataLabelsBtn{
    width: 100%;
    height: 50px;
    padding: 0;
    border: 2px solid white;
    border-radius: 9px;
    background-color: #415AD2;
    cursor: pointer;
    font-size: 15px;
    font-family: interBold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
    color: white;
}

.dataLabelsBtn:hover{
    background-color: #E8542F;
}

.dataLabelsSelect{
    padding: 10px;
    width: 100%;
    height: 54px;
    background-color: white;
    border-radius: 9px;
    border: 2px solid white;
    outline: none;
    font-family: interRegular;
    font-size: 18px;
}

.dataLabelsSelect:hover, .dataLabelsSelect:focus{
    border: 2px solid #E8542F
}

.dataLabelsTitle{
    font-family: InterBold;
    font-size: 12px;
}

.shopGoBackBtn{
    width: fit-content;
    height: fit-content;
    padding: 2px 10px 2px 0px;
    margin: 0;
    border: 0;
    cursor: pointer;
    font-size: 15px;
    font-family: interRegular;
    color: white;
    background-color: transparent;
}

.shopGoBackBtn:hover{
    text-decoration: underline;
}

.shoppingInfo{
    max-width: 41.6%;
    width: 470px;
    padding: 10px 20px 20px 20px;
    border: 2px solid white;
    border-radius: 9px;
    font-family: interBold;
    margin-top: calc(50px + 23px);
    margin-right: 30px;
}

.shoppingInfoLine{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: interRegular;
    font-size: 15px;
}

.shoppingErrorLine{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: interRegular;
    font-size: 15px;
    margin-bottom: 5px;
    color: #E8542F;
    border-top: 2px solid white;
    padding: 10px 0px;
}

.shoppingInfoTable{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 15px 0px;
    font-family: interRegular;
    font-size: 13px;
}

.shoppingInfoTable img{
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.shoppingInfoConfirm{
    width: 100%;
    height: 40px;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: #E8542F;
    border-radius: 9px;
    color: white;
    font-family: interBold;
    font-size: 15px;
    cursor: pointer;
}

.shoppingInfoConfirm:hover{
    background-color: #b8482c;
}

.errorLine{
    color: #E8542F;
    font-size: 18px;
    font-family: interBold;
    margin-bottom: 15px;
}

.paymentZoneOut{
    width: 100%;
    height: 100vh;
    position:absolute;
    top: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    background-color: #141414;
}

.paymentZoneOut img{
    width: fit-content;
    height: fit-content;
}

#myPaymentForm{
    background-color: #353535;
    padding: 15px;
    border-radius: 9px;
}

.paymentStatus{
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 1000px;
    min-width: 330px;
    height: 100%;
    margin-left: 10%;
    margin-right: 10%;
    z-index: 0;
}

.paymentStatusTitle{
    font-family: gilroyBold;
    font-size: 30px;
    text-align: center;
    margin-bottom: 50px;
}

.paymentStatusContact{
    width: calc(60% - 80px);
    background-color: transparent;
    min-width: calc(340px - 84px);
    border-radius: 12px;
    border: 2px dashed white;
    padding: 40px;
    margin-top: 30px;
}

.paymentStatusSubtitle{
    width: 100%;
    text-align: center;
    font-family: gilroyBold;
    font-size: 25px;
    text-decoration: underline;
    margin: 0;
    margin-bottom: 50px;
    color: white;
}

.paymentStatusText{
    font-family: interRegular;
    font-size: 15px;
    width: 100%;
    text-align: start;
}

.paymentStatusTextTitle{
    font-family: interBold;
    font-size: 15px;
}

.paymentStatusLink{
    font-family: gilroyBold;
    margin-top: 90px;
    border-radius: 9px;
    color: #fff;
    font-size: 16px;
    height: 40px;
    width: 116px;
    background-color: #E8542F;
    border: 1px solid #E8542F;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paymentStatusLink:hover{
    color: black;
    background-color: white;
    border: 1px solid white;
}

@media only screen and (max-width: 900px){
    .mainCardContainer{
        flex-direction: column;
        align-items: row;
    }

    .cardInfo, .cardQuantity, .confirmCards, .mainCardPersonalization{
        width: 450px;
    }

    .cardShopInfo{
        flex-direction: column;
    }

    .customerInfo{
        width: 100%;
        max-width: 100%;
    }

    .shoppingInfo{
        margin-left: auto;
        margin-right: auto;
        min-width: 290px;
    }
}

@media only screen and (max-width: 500px){
    .cardsContainer{
        margin-top: 50px;
    }

    .mainCardContainerCarousel{
        width: 340px;
        height: 438px;
        border-radius: 9px;
    }

    .cardInfo, .cardQuantity, .confirmCards, .mainCardPersonalization{
        width: 340px;
    }
}