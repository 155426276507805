.adminMainContainer{
    width: calc(100% - 140px);
    padding: 20px 70px;
    margin: auto;
}

.adminBtnsCont{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-bottom: 60px;
}

.adminBtn{
    width: 160px;
    padding: 10px 0;
    font-size: 26px;
    font-family: interMedium;
    color: var(--ppolBlack);
    margin: 0;
    border: 0;
    background-color: transparent;
    border-bottom: 2px solid #8D8D8D;
    flex-shrink: 0;
}

/* ------ METRICAS ------ */
.metricAdminVisitorTableRow{
    width: 24%;
}

.metricAdminVisitorUserTableRow{
    width: 20%;
    max-width: 20%;
}

.metricAdminVisitorUserTableNameRow{
    width: 40%;
    max-width: 40%;
}

/* ------ PLANTILLAS ------ */
.adminTemplatesSectionCont{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 8px;
}

.adminTemplatesSectionTitle{
    font-size: 33px;
    font-family: interSemiBold;
    color: var(--ppolBlack);
    margin: 0;
}

.adminTemplatesSectionSubtitle{
    font-size: 16px;
    font-family: interMedium;
    color: #8D8D8D;
    margin: 0;
}

.adminTemplatesSectionCards{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    margin-top: 20px;
}

.adminTemplatesSectionCard{
    width: calc(50% - 25px - 32px);
    height: calc(135px - 32px);
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.adminTemplatesCardNewTitle{
    font-size: 18px;
    font-family: interMedium;
    color: var(--ppolBlack);
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.adminTemplatesCardNewBtn{
    width: 136px;
    height: 42px;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: var(--ppolOrange);
    color: var(--ppolWhite);
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    font-size: 13.5px;
    font-family: interMedium;
    margin-right: 20px;
}

.adminTemplatesCardNewBtn:hover{
    background-color: #FF8061;
}

.adminTemplatesCardLeftCont{
    display: flex;
    flex-direction: column;
    width: 287px;
    justify-content: space-between;
}

.adminTemplatesCardTitleCont{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.adminTemplatesCardCont{
    width: 74px;
    height: 74px;
    background-color: #F5F5F5;
    border: 1px solid #D9D9D9;
    font-family: gilroyBold;
    font-size: 60px;
    color: #86898E;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.adminTemplatesCardTitle{
    font-size: 13.5px;
    font-family: interMedium;
    color: var(--ppolBlack);
    margin: 12px 0px 0px 12px;
}

.adminTemplatesCardDate{
    font-size: 12px;
    font-family: interMedium;
    color: var(--ppolBlack);
}

.adminTemplatesCardRightCont{
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.adminTemplatesCardBtn{
    width: 136px;
    height: 42px;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    border: 1px solid #415AD2;
    font-size: 13.5px;
    font-family: interMedium;
    margin: 0;
    background-color: #415AD2;
    color: var(--ppolWhite);
}

.adminTemplatesCardBtn:hover{
    background-color: transparent;
    color: var(--ppolBlack);
    border-color: #D9D9D9;
}

/* ------ USUARIOS ------ */

.adminUsersMainCont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    max-width: 1030px;
}

.adminUsersBtns{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 12px;
}

.adminUsersBtnsRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.adminUsersUsersQuantityText{
    width: calc(100% - 28px);
    text-align: left;
    padding: 0px 14px;
    font-size: 12.5px;
    font-family: interMedium;
    color: #8C8E90;
}

.adminUsersBtn{
    width: 130px;
    height: 43px;
    border: 1px solid #D9D9D9;
    background-color: var(--ppolWhite);
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    color: var(--ppolBlack);
    font-size: 15px;
    font-family: interMedium;
    display:flex;
    align-items:center;
    justify-content:center;
    gap:8px
}

.adminUsersFilterBtn{
    width: 105px;
    height: 43px;
    border: 1px solid #D9D9D9;
    background-color: var(--ppolWhite);
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    color: var(--ppolBlack);
    font-size: 15px;
    font-family: interMedium;
    display:flex;
    align-items:center;
    justify-content:center;
    gap:8px
}

.adminUsersFilterBtn:hover, .adminUsersBtn:hover{
    border-color: var(--ppolBlack);
}

.adminUsersFilterInputCont{
    position: relative;
}

.adminUsersFilterInput{
    width: calc(307px - 12px - 40px);
    padding: 0px 12px 0px 40px;
    height: 43px;
    border: 1px solid #D9D9D9;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    background-color: var(--ppolWhite);
    color: var(--ppolBlack);
    font-size: 15px;
    font-family: interMedium;
    position: relative;
}

.adminUsersFilterInputIcon{
    position: absolute;
    z-index: 1;
    top: 12px;
    left: 12px;
}

.adminUsersBoxCont{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.adminUserBox{
    display: flex;
    background-color: #141414;
    width: 242px;
    flex-direction: column;
    position: relative;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    border: 1px solid #757575;
    align-items: center;
    overflow: hidden;
}

.adminUserBoxCheckboxCont{
    width: calc(100% - 28px - 10px);
    background-color: #FFFFFF;
    position: absolute;
    top: 10px;
    left: 14px;
    padding: 4px 5px;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.adminUserBoxCheckboxTextCont{
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.adminUserBoxCheckboxTextColor{
    width: 14px;
    height: 14px;
    background-color: #7400D1;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.adminUserBoxCheckboxText{
    color: var(--ppolBlack);
    font-family: interMedium;
    font-size: 12px;
}

.adminUserBoxBannerImg{
    width: 100%;
    height: 100px;
    object-fit: cover;
    margin-bottom: 70px;
}

.adminUserBoxProfileImg{
    width: 105px;
    height: 105px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    position: absolute;
    top: 54px;
    object-fit: cover;
    left: calc(50% - 52.5px)
}

.adminUserBoxVerifiedIcon{
    width: 24px;
    height: 24px;
    z-index: 1;
    position: absolute;
    top: 132px;
    right: 75px;
}

.adminUserBoxProfileName{
    width: calc(100% - 28px);
    font-size: 16px;
    font-family: gilroyBold;
    text-align: center;
}

.adminUserBoxProfileTitle{
    width: calc(100% - 28px);
    font-size: 12px;
    font-family: gilroyMedium;
    text-align: center;
}

.adminUserBoxProfileBtnCont{
    width: calc(100% - 28px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 12px;
    margin-bottom: 14px;
}

.adminUserBoxProfileBtn{
    width: 50%;
    height: 30px;
    background-color: #415AD2;
    border: 0;
    color: var(--ppolWhite);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10.5px;
    font-family: interSemiBold;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.floatAdminUserCont{
    background-color: var(--ppolWhite);
    max-height: calc(90vh - 44px);
    width: calc(90% - 56px);
    max-width: calc(1028px - 56px);
    overflow: hidden;
    overflow-y: scroll;
    padding: 22px 28px;
    border-radius: 22px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
}

.floatAdminUserTitleCont{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    gap: 20px;
}

.floatAdminUserTitleContBtn{
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border: 1px solid var(--ppolBlack);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 43px;
    background-color: transparent;
    font-size: 15px;
    font-family: interMedium;
}

.floatAdminUserTitleContBtn:hover{
    background-color: var(--ppolOrange);
}

.floatAdminUserTitle{
    font-size: 22px;
    font-family: interBold;
    color: var(--ppolBlack);
    margin: 0;
}

.floatAdminUserTemplateTitle{
    width: calc(100% - 12px);
    font-family: interBold;
    border: 1px solid var(--ppolBlack);
    padding: 5px;
    margin: 0;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    text-align: center;
}

.floatAdminUserDetailsCont{
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.floatAdminUserDetailsMain{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.floatAdminUserProfileImg{
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.floatAdminUserProfileTitleCont{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.floatAdminUserProfileTitle{
    font-size: 22px;
    font-family: interBold;
    color: var(--ppolBlack)
}

.floatAdminUserProfileSubtitle{
    font-size: 18px;
    font-family: interRegular;
    color: var(--ppolBlack)
}

.floatAdminUserProfileLabelCont{
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 5px;
}

.floatAdminUserProfileLabelCircle{
    width: 14px;
    height: 14px;
    background-color: red;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.floatAdminUserProfileLabelText{
    font-size: 12px;
    font-family: interRegular;
    color: var(--ppolBlack)
}

.floatAdminUserDetailsBtnsCont{
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr 1fr;
}

.floatAdminUserDetailsBtn{
    padding: 12px 18px;
    border: 0;
    background-color: #757575;
    color: var(--ppolWhite);
    font-family: interMedium;
    font-size: 15px;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    text-align: center;
}

.floatAdminUserDetailsBtn:hover{
    background-color: #415AD2;
}

.floatAdminSectionTitle{
    font-size: 25px;
    font-family: interSemiBold;
    margin: 0;
    color: var(--ppolBlack);
}

.floatAdminSectionSubtitle{
    font-size: 15px;
    font-family: interMedium;
    margin: 5px 0px;
    color: #8D8D8D;
}

.floatAdminPermitSectionCont{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    gap: 50px;
}

.floatAdminPermitSectionColumn{
    display: flex;
    flex-direction: column;
    height: 580px;
    align-items: flex-start;
    justify-content: space-between;
    margin: 30px 0px;
    width: 310px;
    flex-shrink: 0;
}

.floatAdminPermitSection{
    width: 100%;
}

.floatAdminPermitSectionTitle{
    color: var(--ppolBlack);
    font-size: 16px;
    font-family: interMedium;
    margin: 0;
    margin-bottom: 8px;
}

.floatAdminPermitSectionCheckRow{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.floatAdminPermitSectionCheckRowText{
    color: #8C8E90;
    font-size: 14px;
    font-family: interMedium;
    margin: 0;
}

.floatAdminPermitSectionCheckRowCont{
    width: calc(100% - 24px - 2px);
    padding: 12px;
    border: 1px solid #D9D9D9;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 12px;
}

/* ------ GENERAL ------ */

.floatAdminSeparation{
    margin: 20px 0px;
    background-color: #DDDDDD;
    padding: 0;
    height: 1px;
    width: 100%;
}

@media only screen and (max-width:1380px){
    .adminUsersBtns{
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}