:root {
    --background-color-primary: #141414;
    --background-color-secondary: #f3f3f3;
    --background-color-dark: #111;
    --background-color-navbar: #1b1b1b;
}

.landing-bg {
    /*background-color: var(--background-color-dark);*/
    background-color: #000;
}

/******************* NAVBAR ****************+*/  

.navbar-container {
    margin: 0 auto;
    max-width: 1920px;
    display: flex;
    justify-content: center;
}

.navbar-2 {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background-color: var(--background-color-navbar);
    margin: 1.5% auto 0 auto;
    border-radius: 70px;
    padding: 0 3.5%;
    position: fixed;
    height: min(10.5%, 111px);
    min-height: 85px;
    width: min(90%, 1720px); 
    z-index: 2;
}

.navbar-header {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
    gap: 7%;
}

.navbar-logo-1 {
    width: 20%;
}

.navbar-logo-1 svg {
    width: 100%;
}

.navbar-links {
    display: flex;
    align-items: center;
    gap: 20%;
}

.navbar-links a::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1.5px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.navbar-links a:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.navbar-links li {
    list-style: none; 
}

.navbar-links li a {
    font-family: interBold;
    font-size: clamp(18px, 1vw, 23px);
    text-wrap: nowrap;
    display: inline-block;
	position: relative;
	color: #fff;
}

.navbar-right {
    box-sizing: border-box;
    font-size: clamp(16px, 1vw, 21px);
    font-family: interBold;
    display: flex;
    gap: 3%;
    flex-grow: 1;
    justify-content: flex-end;
    flex-basis: 0;
}

.navbar-right a {
    display: flex;
    align-items: center;
    padding: 2% 5%;
    text-wrap: nowrap;
    background-color: #E8542F;
    border-radius: 9px;
    transition: all 0.5s;
}

.navbar-right a:hover {
    background-color: #ff8061;
}

.navbar-right a:nth-child(2) {
    border: 2px solid #fff;
    background-color: transparent;
    padding: 2% 8.3%;
}

.navbar-right a:nth-child(2):hover {
    background-color: #fff;
    color: var(--background-color-navbar);
}

.navbar-mobile-container {
    display: none;
}

.navbar-mobile {
    display: none;
}

.navbar-logo-2  {
    display: none;
}

@media only screen and (max-width: 1200px){

    .navbar-2 {
        height: min(10.5%, 90px);
        min-height: 70px;
    }

    .navbar-logo-1 {
        display: none;
    }

    .bg-buy-container .navbar-logo-1 {
        display: block;
        min-width: 100px;
    }

    .bg-buy-container .navbar-logo-2 {
        display: none;
    }

    .navbar-logo-2 {
        display: block;
        height: 40px;
    }

    .navbar-logo-2 svg {
        height: 100%;
    }

    .navbar-links {
        gap: 10%;
    }

    .navbar-links {
        padding: 15px;
    }
}


@media only screen and (max-width: 867px){

    .navbar-container {
        display: none;
    }

    .navbar-mobile-container {
        display: contents;
        background-color: var(--background-color-navbar);
        display: flex;
        justify-content: center;
        height: 0;
    }

    .open {
        height: 100vh;
        width: 100vw;
        position: fixed;
        z-index: 10;
    }

    .navbar-body-container {
        width: 90%;
        padding-top: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: -1;
    }

    .navbar-links-open {
        display: block;
        font-size: 30px;
        font-family: interRegular;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .bg-buy-container .navbar-links-open {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60%;
    }

    .bg-buy-container .navbar-links-open img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .navbar-links-open ul {
        padding: 0 ;
    }

    .navbar-links-open li{
        list-style: none;
        padding: 5% 0;
        font-size: 32px;
    }

    .navbar-right-open {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        font-family: interRegular;
        font-size: 18px;
        padding-bottom: 5%;
    }

    .navbar-right {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
    }

    .navbar-right a, .navbar-right a:nth-child(2) {
        height: 58px;
        font-size: 18px;
        display: flex;
        justify-content: center;
        padding: 0;
    }

    .navbar-right-open {
        color: #979797;
    }

    .navbar-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        border-radius: 75px;
        z-index: 10;
        width: 90%;
        background-color: var(--background-color-navbar);
        margin: 0 auto;
        position: fixed;
        margin-top: 20px;
    }

    .navbar-mobile button {
        position: absolute;
        left: 30px;
    }

    .navbar-mobile a {
        height: 27px;
    }

    .navbar-mobile a svg {
        height: 100%;
        width: fit-content;
    }

    .nav-toggle {
        position: absolute;
        left: 30px;
        background: transparent;
        border: 1px solid transparent;
    }

    .nav-toggle [class*='bar-'] {
        background: white;
        display: block;
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        -webkit-transition: .2s ease all;
                transition: .2s ease all;
        border-radius: 8px;
        height: 3px;
        width: 20px;
        margin-bottom: 5px;
      }
      
      .nav-toggle .bar-bot {
        margin-bottom: 0;
      }
      
      .open .bar-top {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                -webkit-transform-origin: 50% 50%;
                transform-origin: 0% 100%;
      }
      .open .bar-mid {
        opacity: 0;
      }
      .open .bar-bot {
        -webkit-transform: rotate(45deg);
                transform: rotate(-45deg);
                -webkit-transform-origin: 20% 90%;
                transform-origin: -5% 0%;
      }
}

/**************************** HERO SECTION ************************************/

.hero-section-container {
    /*background-color: var(--background-color-dark);*/
    background-color: #000000;
    box-sizing: border-box;
    min-height: 100vh;
    padding-top: 100px;
    display: flex;
    align-items:center;
}

.hero-section {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 48% 48%;
    align-items: center;
    width: min(90%, 1720px);
    gap:4%;
}

.hero-section-video {
    display: none;
}

.hero-section-info {
    max-width: 848px;
}

.hero-section h1{
    color: #FFF;
    font-family: interBold;
    font-size: clamp(36px, 6.5vmin, 66px);
    letter-spacing: -1.32px;
    margin: 0;
    text-wrap:pretty;
}

.hero-section-img {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.hero-section-img video {
    height: 100%;
    width: 100%;
    object-fit:cover;
}

.hero-section p {
    font-family: interRegular;
    font-weight: 400;
    color: #fff;
    font-size: clamp(1px, 2.9vmin, 27px);
}

.hero-section-links {
    display: flex;
    gap: 2.5%;
    margin-top: 7%;
}

.hero-section-links a {
    border-radius: 9px;
    padding: 1.9% 6.1%;
    font-family: interSemiBold;
    font-size: clamp(1px, 2.7vmin, 25px);
    text-wrap: nowrap;
    transition: all 0.5s;
}

.hero-section-links a:first-child  {
    background-color: #e8542f;
}

.hero-section-links a:hover {
    background-color: #ff8061;
}

.hero-section-links a:nth-child(2) {
    color: #fff;
    border: 1px solid #fff;
}

.hero-section-links a:nth-child(2):hover {
    background-color: #fff;
    color: #1b1b1b;
}

@media only screen and (max-width: 1200px){

    .hero-section-container {
        align-items: flex-start;
        padding-top: 180px;
        height: 100%;
    }

    .hero-section {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .hero-section-info {
        padding-right: 0;
    }

    .hero-section-links {
        flex-direction: column;
    }

    .hero-section-links a {
        width: fit-content;
        margin-bottom: 2%;
    }
}

@media only screen and (max-width: 767px){
    .hero-section {
        display: none;
    }

    .hero-section-container{
        position: relative;
        padding: 0;
    }

    .hero-section-video {
        display:flex;
        flex-direction: column;
		top: 0;
        margin: 0 auto;
        height: 100vh;
        justify-content: space-between;
        align-items: center;
    }

    .hero-video-container {
        width: 100%;
        height: auto;
    }
    
    .hero-video {
        width: 100%;
        padding: 8px 0;
    }

    .hero-video-title {
        color: #FFF;
        text-align: center;
        font-family: interBold;
        font-size: 33px;
        font-style: normal;
        line-height: 118%;
        letter-spacing: -0.78px;
        width: 100%;
        padding: 0 10%;
        box-sizing: border-box;
        margin: 0;
        padding-top: 25%;
    }

    .hero-video-buttons {
        bottom: 8%;
        display: flex;
        gap: 10px;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        align-items: center;
        padding-bottom: 10%;
    }

    .hero-video-buttons a {
        text-align: center;
        max-height: 58px;
        border-radius: 9px;
        padding: 18px 0;
        font-family: interRegular;
        font-size: 18px;
        font-weight: 600;
        width: 90%;
        box-sizing: border-box;
        border: 2px solid #fff;
    }

    .hero-video-buttons a:first-child  {
        background-color: #e8542f; 
        border: none;
    }
}


/********************* FEATURES SECTION ************************************/

.features-section-container {
    background-color: #f3f3f3;
    height: auto;
    color: #141414;
    border-radius: 75px 75px 0px 0px;
    margin: 0;
    padding-bottom: 3%;
}

.features-section {
    text-align: center;
    width: min(90%, 1720px);
    margin: 0 auto;
    height: auto;
    box-sizing: border-box;
    padding: 10% 0;
}

.features-section h1 {
    color: #141414;
    text-align: center;
    font-family: interBold;
    font-size: clamp(30px, 6.8vmin, 66px);
    font-style: normal;
    line-height: 118%; /* 77.88px */
    letter-spacing: -1.32px;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0 9% 8% 9%;
}

.features-article-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3.5%;
    max-height: fit-content;

    margin: 40px 0;
    perspective: 1900px;
    margin: 0 auto;
    place-content: center;
    align-items: center;
    justify-content: center;

}

.features-article-item {
    color: #141414;
    max-width: 100%;
    min-height: 100%;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 4px 4px 5px 0px rgba(186, 186, 186, 0.20);
    font-family: interRegular;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.features-article-item video {
    width: 100%;
    height: auto;
}

.features-article-item p {
    font-size: clamp(18px, 2.8vmin, 27px);
    height: auto;
    text-align: center;
    margin: 0 auto;
    padding: 0 15% ;
    margin-bottom: 10%;
    color: #000;
}

.card {
    width: 100%;
    height: 420px;
    transition: transform 1s;
    transform-style: preserve-3d;
    position: relative;
  }
  
  .card.is-flipped {
    transform: rotateY(180deg);
  }
  
  .card__face {
    position: absolute;
    width: 100%;
    text-align: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: auto;
    box-shadow: 4px 4px 5px 0px rgba(186, 186, 186, 0.20);
  }
  
  .card__face--front {
    background: #fff;
  }
  
  .card__face--back {
      display: flex;
      flex-direction: column;
      align-items: center;
    transform: rotateY(180deg);
  }
  
  .card__face video {
      width: 100%;
      border-radius: 15px;
  }


@media only screen and (max-width: 767px){

    .features-section-container {
        padding-top: 15%;
        border-radius: 35px 35px 0 0;
        margin-top: 0;
        height: 100%;
        padding-bottom: 30%;
    }
  
    .features-article-container {
        perspective: none;
        grid-template-columns: 1fr; 
        height: auto;
        gap: 5%;
    }

    .card {
        padding: 10% 0;
        width: 100%;
        height: 450px;
    }

    .features-section article {
        width: 100%;
        height: 100%;
    }

    .features-article-container div img{
        width: 90%;
    }

    .features-article-item p {
        font-size: 25px;
    }
}

@media only screen and (max-width: 550px){
    .card {
        height: 350px;
    }
}

@media only screen and (max-width: 400px){
    .card {
        height: 250px;
    }
    .features-article-container {
        gap: 60px;
    }

    .features-article-item p {
        font-size: 20px;
    }
}




/********************* METRICS SECTION ************************************/

.metrics-section {
    background-color: #f3f3f3;
    color: #141414;
    font-family: interRegular;
    height: auto;
}

.metrics-slide {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    height: 12vmin;
    max-height: 130px;
}

.slide-item {
    display: flex;
    animation: desplazarLeft 25s linear infinite;
}

.slide-item div {
    text-align: center;
    font-size: clamp(25px, 6vmin, 60px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.slide-item-2 div {
    font-size: clamp(25px, 6vmin, 60px);
    display: flex;
    align-items: center;   
}

.slide-item div {
    min-width: 55vw;
    border: 2.5px solid #000; 
    border-right: none;
    padding: 0 15px;
}

@keyframes desplazarLeft {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}


@media only screen and (max-width: 1500px) {
    .slide-item div {
        padding: 0 80px;
    }

}

@media only screen and (max-width: 767px) {

    .metrics-slide {
        height: 13.5vmin;
    }

    .metrics-section {
        padding-bottom: 15%;
    }

    .slide-item div {
        min-width: 70vw;
        height:auto;
        padding: 0 40px;
    }

    .slide-item-2 div svg {
        height: 35px;
    }
}

@media only screen and (max-width: 600px) {
    .slide-item-2 div svg {
        height: 25px;
    }
}

.metrics-section .swiper-slide {
    text-align: center;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.metrics-section .swiper-wrapper {
    transition-timing-function: linear !important;
    pointer-events: none;
}


/***************** SHARE SECTION ************************/

.share-section-container {
    background-color: #f3f3f3; 
    height: auto;
    padding: 8% 0 0 0;
}

.share-section {
    width: min(90%, 1720px);
    margin: 0 auto;
}

.share-section main {
    display: grid;
    grid-template-columns: 45% 50%;
    gap: 5%;
}

.share-section h1 {
    color: #141414;
    text-align: center;
    font-family: interBold;
    font-size: clamp(33px, 6.7vmin, 66px);
    font-style: normal;
    padding: 0 15%;
    margin-top: 0;
    margin-bottom: 5.6%;
    box-sizing: border-box;
    width: 100%;
}

.share-text {
    color: #141414;
    font-family: interRegular;
    display: flex;
    flex-direction: column;
    padding: 7% 0;
    justify-content: space-around;
}

.share-text-title {
    font-size: clamp(30px, 6vmin, 56px);
    font-style: normal;
    line-height: 118%; /* 66.08px */
    letter-spacing: -1.12px;
    display: flex;
    align-items: center;
    gap: 2%;
    max-height: 66px;
}

.share-text h2 {
    font-size: clamp(30px, 6vmin, 56px);
    color: #E8542F;
    font-family: interBold;
    margin: 0;
}

.share-section p {
    font-family: interRegular;
    font-size: clamp(18px, 2.7vmin, 27px);
    font-style: normal;
    width: 81.5%;
    height: auto;
    text-wrap: pretty;
    margin: 2.1% 0;
}

.share-section-img {
    border-radius: 15px;
    height: 95%;
    width: auto;
}

.share-section-img video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

@media only screen and (max-width: 1500px) {
    .share-section p {
        width: auto;
    }

    .share-section h1 {
        padding: 0 5%;
    }
}

@media only screen and (max-width: 1350px) {
    .share-section main {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .share-text {
        gap: 30px;
    }
}

@media only screen and (max-width: 767px) {

    .share-section-container {
        margin-top: -1px;
    }

    .share-section main {
        display: grid;
        grid-template-columns: 1fr;
    }

    .share-section-img {
        padding-right: 0;
    }

    .share-section p {
        width: 100%;
    }

    .share-section h1 {
        padding: 0;
    }
}

/******************* BENEFITS **********************/

.benefits-section-container {
    background-color: #f3f3f3; 
    height: auto;
    padding: 8% 0;
    margin: -1px 0;
}

.benefits-section {
    width: min(90%, 1720px);
    margin: 0 auto;
}

.benefits-section main {
    display: grid;
    grid-template-columns: 50% 50%;
}

.benefits-section h1 {
    color: #141414;
    text-align: center;
    font-family: interBold;
    font-size: clamp(33px, 6.8vmin, 66px);
    letter-spacing: -1.32px;
    height: auto;
    padding: 0 15%;
    margin: 0 0 6.3% 0;
    box-sizing: border-box;
    width: 100%;
}

.benefits-picture-container {
    background-color: #141414;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 15px;
}

.benefits-picture-container video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 15px;
}

.benefits-picture-container img {
    display: none;
}

@media only screen and (max-width: 767px) {
    .benefits-section h1{
        padding: 10% 0;
        margin: 0;
    }

    .benefits-picture-container {
        height: 100%;
        width: auto;
    }

    .benefits-picture-container video {
        display: none;
    }

    .benefits-picture-container img {
        display: flex;
        object-fit: cover;
        width: 100%;
    }
}

/*****************  SERVICES SECTION ******************/

.services-section-container {
    background-color: #FFF;
    height: auto;
    padding: 7% 0;
}

.services-section {
    width: min(90%, 1720px);
    margin: 0 auto;
}

.services-section main {
    display: flex;
    gap: 8%;
}

.services-section-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.services-section-title, .services-section-title-hidden {
    color: #141414;
    font-family: interBold;
    font-size: clamp(33px, 6.8vmin, 66px);
    font-style: normal;
    line-height: 118%; /* 77.88px */
    letter-spacing: -1.32px;
    max-width: 848px;
    flex-shrink: 0;
    text-wrap: balance;
    margin-top: 0px;
    box-sizing: border-box;
}

.services-section-title-hidden {
    display: none;
}

.services-section-text p {
    color: #141414;
    max-width: 674px;
    font-family: interRegular;
    font-size: clamp(18px, 3vmin, 27px);
    font-style: normal;
    line-height: 128%; /* 34.56px */
    padding-bottom: 4.5%;
    text-wrap: balance;
    padding-right: 13%;
}

.services-section-text a {
    color: #E8542F;
    font-family: interRegular;
    font-size: clamp(18px, 3vmin, 27px);
    font-style: normal;
    font-weight: 600;
    line-height: 128%; /* 34.56px */
    text-decoration-line: underline;
    transition: all 0.5s;
}


.services-section-text a:hover {
    color: #ff8061;
    transition: transform 1s;
}

.services-section-text a span{
    padding-left: 5px;
    display: inline-block;
    transition: transform 1s;
}

.services-section-text a:hover span{
    transform: translateX(10px);
}

.services-img-container {
    max-width: 764px;
    max-height: 669px;
}

.services-img-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media only screen and (max-width: 1200px) {

    .services-section{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10% 0;
    }

    .services-section main {
        flex-direction: column;
    }

    .services-section-title-hidden {
        display:block;
        text-align: center;
        padding-bottom: 30px;
        margin: 0 auto;
    }

    .services-section-title{
        position:absolute;
        top: 0;
        height: auto;
        width: 100%;
        padding: 0 5%;
        text-align: center;
        display: none;
    }

    .services-img-container {
        margin: 0 auto;
    }

    .services-section-text {
        align-items: center;
    }

    .services-section-text p, .services-section-text a  {
        text-align: center;
        padding: 0;
    }    
    
}

/***************************** SHARE METODS *************************************/

.share-metods-section-container {
    background-color: #f3f3f3;
    height: auto;
    padding: 9.7% 0;
    color: #141414;
    padding-bottom: 15%;
    margin-top: -1px;
}

.share-metods-section {
    margin: 0 auto;
    width: min(90%, 1720px);
}

.share-metods-section h1 {
    color: #141414;
    text-align: center;
    font-family: interBold;
    font-size: clamp(33px, 7vmin, 66px);
    font-style: normal;
    line-height: 118%; /* 77.88px */
    letter-spacing: -1.32px;
    margin-top: 0;
    margin-bottom: 2.6%;
    box-sizing: border-box;
    text-align: center;
    width: 100%;
}

.share-metods-subtitle {
    text-align: center;
    font-family: interRegular;
    font-size: clamp(18px, 3.6vmin, 34px);
    padding-bottom: 6%;
}

.share-metods-article-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 60px;
    margin: 0 auto;
    padding-bottom: 5%;
    align-items: center;
    cursor: grab;
}

.share-metods-article-container article {
    max-width: 530px;
    height: auto;
    margin: 0 auto;
}

.share-metods-article-item {
    color: #141414;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 4px 4px 5px 0px rgba(186, 186, 186, 0.20);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 290px;
    max-height: 589px;
    width: 100%;
}

.share-metods-article-item img  {
    width: 90%;
    height: 100%;
    object-fit: cover;
}

.share-metods-article-description {
    padding: 0;
}

.share-metods-article-description h3 {
    font-family: interRegular;
    font-size: clamp(30px, 5vmin, 56px);
    font-style: normal;
    font-weight: 500;
    margin: 9% 0;
}

.share-metods-article-description p {
    font-family: interRegular;
    font-size: clamp(18px, 2.5vmin, 27px);
    font-style: normal;
}

.share-metods-section .swiper-pagination {
    display: flex;
    justify-content: center;
    gap: 1%;
    width: 100%;
  }

.share-metods-section .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    background-color: #f3f3f3; /* Color de fondo del punto inactivo */
    margin: 0 8px; /* Espacio entre los puntos */
    opacity: 0.5; 
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease, opacity 0.3s ease;
    border: 2px solid #000;
} 

.share-metods-section .swiper-pagination-bullet-active {
    background-color: #000; /* Color de fondo del punto activo */
    opacity: 1; 
} 

@media only screen and (max-width: 1450px){
    .share-metods-article-container {
        padding: 0;
    }

    .share-metods-article-container {
        padding-bottom: 5%;
    }
}

@media only screen and (max-width: 767px){

    .share-metods-section-container {
        padding-top: 17%;
        padding-bottom: 35%;
        margin-top: -1px;
    }

    .share-metods-section {
        margin: 0 auto;
    }

    .share-metods-article-container {
        padding-bottom: 3%;
    }

    .share-metods-article-container div img{
        width: 90%;
    }

    .share-metods-section .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
    }   
    
    .share-metods-article-description h3 {
        margin: 5% 0;
    }
}

/********************** TESTIMONIALS *******************************/

.testimonials-section-container {
    background-color: var(--background-color-dark);
    height: auto;
    padding: 5.1% 0;
    font-family: interRegular;
    border-radius: 75px 75px 0px 0px;
    margin-top: -8%;
}

.testimonials-section{
    margin: 0 auto;
    flex-shrink: 0;
    height: auto;
    width: min(90%, 1720px);
    box-sizing: border-box;
}

.testimonials-item-container {
    height: fit-content;
    display: flex;
    gap: 20px; 
    overflow-x:scroll;
    width: auto;
    cursor: grab;
    padding: 0 5px 2% 5px;
    -webkit-mask: linear-gradient(
        90deg,
        transparent,
        white 1%,
        white 99%,
        transparent
    );
    mask: linear-gradient(90deg, transparent, white 1%, white 99%, transparent);
}

.column-1, .column-2, .column-3 {
    display: grid;
    gap: 20px;
}

.testimonials-section h1{
    text-align: center;
    color: #FFF;
    font-family: interBold;
    font-size: clamp(33px, 6.7vmin, 66px);
    font-style: normal;
    line-height: 118%; /* 77.88px */
    letter-spacing: -1.32px;
    margin-bottom: 37px;
    text-wrap:pretty;
    padding: 0 20%;
    margin-bottom: 5%;
    width: 100%;
    box-sizing: border-box;
}

.testimonials-item-container::-webkit-scrollbar {
    height: 10px;   
}

.testimonials-item {
    border-radius: 15px;
    background: #34353A;
    width: 674px;
    height:auto;
    flex-shrink: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 3.5% 4.5%;
    box-sizing: border-box; 
    user-select: none;
}

.testimonials-item-profile {
    display: flex;
    align-items: center;
    padding: 0;
    gap: 20px;
}

.testimonials-item-profile div {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.testimonials-item-profile div p {
    margin: 0;
}

.testimonials-item-name {
    font-size: clamp(18px, 2vmin, 21px);
}

.testimonials-item-position {
    font-size: clamp(18px, 2vmin, 21px);
}

.testimonials-item-profile img {
    border-radius: 50%;
    max-width: 80px;
    max-height: 80px;
}

.testimonials-item-description {
    font-size: 27px;
    font-size: clamp(18px, 2vmin, 21px);
    font-style: normal;
    font-weight: 400;
    line-height: 128%;
    margin: 0;
    padding: 0;
}

@media only screen and (max-width: 1600px){
    .testimonials-item {
        width: 580px;
    }
}

@media only screen and (max-width: 767px){

    .testimonials-section-container {
        border-radius: 35px 35px 0px 0px;
        margin-top: -12%;
        padding-bottom: 12%;
    }
    
    .testimonials-item {
        width: 88vw;
    }

    .testimonials-section h1{
        padding: 2%;
        margin: 12% 0;
    }
}


/******************FAQ SECCTION*****************************/

.faq-section-container {
    background-color: var(--background-color-dark); 
    height: auto;
    padding: 8% 0 15% 0;
}

.faq-section {
    margin: 0 auto;
    width: min(90%, 1720px);
    display: grid;
    grid-template-columns: 41% 59%;
}

.faq-header {
    display: flex;
    flex-direction: column;
    height:min-content;
}

.faq-header h1 {
    color: #fff;
    font-size: clamp(33px, 6.5vmin, 66px);
    margin: 0;
    font-family: interBold;
}

.faq-header-svg {
    margin-top: 7%;
    width: 22%;
}

.faq-question-container p {
    color: #fff;
}

.faq-question-item {
    background-color: #2f2f2f;
    font-family: interRegular;
    border-radius: 15px;
    padding: 0.5% 6.3% 0.5% 6.3%;
    border: 1px solid #707070;
    margin-bottom: 2.5%;
    cursor: pointer;
    box-sizing: border-box;
}

.faq-question {
    display: flex;
    align-items: center;
    justify-content: space-between;    
}

.faq-question p {
    margin: 20px 0;
    font-size: clamp(18px, 3vmin, 35px);
}

.faq-question button {
    background-color: transparent;
    border: none;
    margin-left: 3%;
    cursor: pointer;
}

.faq-btn-rotate {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

.faq-btn-rotate-back {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }
  
  .faq-answer.visible {
    max-height: 350px; 
  }

.faq-answer p {
    font-size: clamp(15px, 2.3vmin, 24px);
    font-style: normal;
    line-height: 128%; /* 30.72px */
    padding-right: 8%;
}

.faq-title-svg {
    display:none;
}

@media only screen and (max-width: 767px){

    .faq-section-container{
        margin-bottom: 8%;
    }

    .faq-section {
        display: flex;
        flex-direction: column;
    }

    .faq-header {
        padding-bottom: 13%;
    }

    .faq-header h1 {
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .faq-question svg {
        width: 14px;
        height: 26px;
    }

    .faq-header-svg{
        display: none;
    }

    .faq-title-svg {
        display:block;
        padding-top: 5px;
    }

}

/******************* FOOTER ****************************/

.foot-section-container {
    background-color: var(--background-color-navbar);
    height: auto;
    font-family: interRegular;
    border-radius: 75px 75px 0px 0px;
    padding: 5.1% 5.21%;
    margin-top: -10%;
}

.foot-section {
    max-width: 1920px;
    margin: 0 auto;
}

.foot-section .ppol-logo {
    width: 180px;
}

.foot-links-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.foot-info {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    font-size: clamp(27px, 3.5vmin, 33px);
    color: #979797;
}

.foot-info p {
    margin: 0;
}

.foot-logo {
    text-align: center;
}

.footer-logo-1 {
    width: 65%;
}

.footer-logo-2 {
    display: none;
}

.foot-links ul {
    list-style: none;
    padding: 0;
    margin: 20% 0;
    display: flex;
    flex-direction: column;
}

.foot-links li{
    font-family: nimbusSansBold;
    font-size: clamp(10px, 3.5vmin, 33px);
    font-style: normal;
    line-height: normal;
    padding-bottom: 5%;
}


.foot-policies {
    font-family: interRegular;
    font-size: clamp(18px, 2.3vmin, 22px);
    font-style: normal;
    line-height: 140%; /* 30.8px */
    letter-spacing: -0.44px;
    display: flex;
    margin-top: 20px;
    justify-content: center;
    text-align: center;
    gap: 2%;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.foot-policies a {
    color: #979797;
}

.foot-policies a:hover {
    color: #E8542F;
}

.foot-policies-TextBig{
    font-size: 22px;
}

.foot-policies-TextSmall{
    font-size: 15px;
}

.foot-social-links {
    display: flex;
    gap: 20px;
    flex-grow: 1;
    flex-basis: 0;
    justify-content: flex-end;
}

.foot-social-links a svg{
    height: 80%;
}

.foot-social-links a svg path:first-child {
    transition: fill 0.5s;
}

.foot-social-links a svg:hover path:first-child {
    fill: #e8542f;
}

@media only screen and (max-width: 1200px){
    .footer-logo-1 {
        display: none;
    }
    
    .footer-logo-2 {
        display: block;
    }

    .foot-policies{
        display: flex;
        flex-direction: column;
    }

    .foot-social-links {
        gap: 5px;
    }

    .foot-social-links a svg{
        height: 70%;
    }
}

@media only screen and (max-width: 950px){

    .foot-section-container {
        border-radius: 35px 35px 0px 0px;
    }

    .foot-links-container {
        flex-direction: column;
        gap: 40px;
        margin: 10% 0;
    }

    .foot-social-links {
        justify-content: space-between;
    }

    .foot-logo a {
        display: flex;
        justify-content: center;
    }

    .footer-logo-1 {
        display: block;
    }
    
    .footer-logo-2 {
        display: none;
    }

    .foot-info {
        order: 1;
    }

    .foot-policies {
        margin-bottom: 10%;
        flex-direction: column;
    }
}    