.tycprivCont{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    gap: 80px;
    max-width: 1080px;
    margin-bottom: 50px;
}

.tycprivCont h2{
    font-family: gilroyBold;
    font-size: 25px;
}

.tycprivCont p, .tycprivCont li{
    font-family: interRegular;
    font-size: 12px;
}

.tycprivCont u{
    font-family: interBold;
    font-size: 16px;
}

.tycprivCont a{
    color: #E8542F;
}

.tycprivCont b{
    font-size: 13px;
}