.bg-buy-container {
    margin: 0 auto;
    background-color: #f3f3f3;
    max-width: 1920px;
}

.add-card-container {
    background-color: #f3f3f3;
    height: auto;
    padding-bottom: 18%;
    margin: 0 auto;
}

.add-card-container .navbar-container{
    padding: 0;
}

/***********CARD SECTION**********/

.section-card {
    display: grid;
    grid-template-columns: 54% 41%;
    gap: 5%;
    height: auto;
    padding-top: max(10%, 130px);
    width: min(90%, 1720px);
    margin: 0 auto;
    align-items: center;
}

.card-img {
    height: 700px;
    width: 100%;
    display: flex;
}

.card-img img {
    width: auto; 
    height: 100%; 
    object-fit: contain; 
    border-radius: 12px;
    margin: 0 auto;
}

.card-info { 
    display: flex;
    flex-direction: column;
    gap: 30px;
    height:100%;
}

.card-info-title h1 {
    font-family: interBold;
    color: #222;
    margin: 0;
    font-size: clamp(39px, 5vmin, 50px);
}

.card-info-description p{
    font-family: interRegular;
    color: #141414;
    font-size: clamp(18px, 2vmin, 21px);
    margin: 0;
}

.card-img-carousel {
    display: flex;
    justify-content: space-between;
    height: 18.7%;
    width: auto;
}

.card-img-carousel img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 6px;
    cursor: pointer;
}

.card-amount-container {
    display: flex;
    align-items: center;
    gap: 1.5%;
    color: #141414;
    font-family: interRegular;
    font-size: 21px;
}

.card-quantity {
    border: 1px solid #141414;
    width: 30%;
    max-width: 350px;
    height: auto;
    font-family: interBold;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 50px;
    display: flex;
    padding: 2% 0;
}

.card-quantity-change {
    border: none;
    font-size: clamp(23px, 3vmin, 27px);
    font-family: interBold;
    cursor: pointer;
    background-color: transparent;
    color: black;
}

.card-quantity-number {
    color: #141414;
    margin: 0;
    font-size: clamp(23px, 3vmin, 27px);
}

.card-total-price {
    font-family: interBold;
    color: #141414;
    font-size: clamp(51px,5.8vmin,57px);
    margin: 0;
    width: 100%;
}

.card-buy-btn {
    font-family: interSemiBold;
    background-color: #E8542F;
    color: #fff;
    width: 100%;
    border-radius: 50px;
    border: none;
    font-size: clamp(16px, 2.8vmin, 25px);
    height: 4vw;
    min-height: 60px;
    max-height: 78px;
    cursor: pointer;
    transition: all 0.5s;
}

.card-buy-btn:hover {
    background-color: #ff8061;
}

.section-card-title {
    display: none;
}

@media only screen and (max-width: 1400px){
    .section-card {
        display: flex;
        flex-direction: column;
    }

    .card-img {
        margin-bottom: 20px;
        width: auto;
    }

    .card-img img {
        object-fit: contain;
        width: fit-content;
        margin: 0 auto;
        width: 100%;
    }

    .card-img-carousel {
        justify-content: center;
        gap: 20px;
    }
}

@media only screen and (max-width: 767px){
    .section-card-title {
        display:contents;
    }

    .section-card-title h1 {
        margin: 0;
        color: #141414;
        font-family: interBold;
        display: flex;
        font-size: 39px;
        width: 100%;
    }

    .section-card {
        display: flex;
        flex-direction: column;
        padding-top: 100px;
    }

    .card-amount-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .card-info {
        padding-left: 0;
        gap: 30px;
    }

    .card-info-title {
        display: none;
    }

    .card-quantity {
        width: 100%;
        margin: 0 auto;
        height: 58px;
        padding: 0;
    }

    .add-card-container {
       padding-bottom: 80px;
    }

    .customer-info-container{
        padding-bottom: 110px;
    }

    .card-total-price {
        text-align: center;
    }

    .card-buy-btn {
        height: 58px;
    }

    .card-img {
        height: 430px;
        padding-top: 2%;
    }

    .card-img img {
        object-fit:contain;
    }
    
    .card-img-carousel img{
        width: 20%;
        height: auto;
    }

    .card-info-description {
        order: 1;
    }

    .card-img-carousel {
        order: 0;
    }

    .card-amount-container {
        order: 2;
    }

    .card-total-price {
        order: 3;
    }

    .card-buy-btn {
        order: 4;
    }
}

@media only screen and (max-width: 450px){
    .card-img img {
        object-fit:cover;
    }
}


/*************************************************************/

.section-personalization {
    background-color: #fff;
    color: #141414;
    font-family: interRegular;
    border-radius: 15px;
    box-shadow: 4px 4px 5px 0 rgba(186, 186, 186, 0.2);
    padding: 4% 5%;
    box-sizing: border-box;
    width: min(90%, 1720px);
    margin: 2.5% auto 0 auto;
}   

.section-personalization h1 {
    color: #141414;
    font-size: clamp(30px, 3.9vmin, 36px);
    margin: 0;
    font-family: interMedium;
    display: flex;
}

.section-personalization p {
    font-size: clamp(18px, 2vmin, 21px);
    margin-top: 2.5%;
}

@media only screen and (max-width: 767px){
    .section-personalization h1 {
        margin: 5% 0 10% 0;
    }

    .section-personalization {
        margin-top: 15%;
        margin-bottom: 10%;
    }  
}


/***************************************/

.customer-info-container{
    background-color: #f3f3f3;
    height: auto;
    padding-bottom: 13%; 
}

.customer-info {
    width: min(90%, 1720px);
    margin: 0 auto;
}

.navbar-buy-data {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
}

.navbar-buy-data svg {
    fill: black;
    position: absolute;
    width: max(10%,110px);
}

.form-buy-data-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.form-buy-title {
    color: #141414;
    font-family: interMedium;
    font-size: clamp(26px, 3.8vmin, 36px);
    font-weight: 500;
    margin: 0;
}

.form-buy-data input, .form-buy-data select, .form-buy-data button{
    border-radius: 50px;
    width: 100%;
    font-size: clamp(16px, 2.2vmin, 21px);
    box-sizing: border-box;
    padding: 2.5% 30px;
}

.form-buy-data select {
    appearance: none; 
    background-image: url('arrowSelect.svg');
    background-repeat: no-repeat; 
    background-position: right 20px center; 
    background-color: white;
    border: 1px solid #878787;
}

.floating-label-group input {
    border-radius: 50px;
    border: 1px solid #878787;
    font-size: clamp(16px, 2.2vmin, 21px);
    box-sizing: border-box;
}

.btn-back {
    border-radius: 50px;
    border: 1px solid #222;
    padding: 0;
    font-size: clamp(16px, 2.2vmin, 21px);
    padding:1% 2%;
    margin-bottom: 4%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    background-color: transparent;
}

.btn-back svg {
    height: 20px;
}

.form-colum  {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.buy-code-data {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 20px;
}

.buy-code-data .floating-label-group .code-valid{
    padding: 3.5% 30px;
    color: #19B201;
    border: 1px solid #19B201;
}

.buy-code-data .floating-label-group .code-invalid{
    padding: 3.5% 30px;
    color: #E32B2B;
    border: 1px solid #E32B2B;
}

.buy-code-data .floating-label-group .no-code{
    padding: 3.5% 30px;
    color: #878787;
    border: 1px solid #878787;
}

.form-colum .floating-label-group input, .form-colum select, .form-colum button{
    padding: 5% 30px;
    font-size: clamp(16px,2vmin,21px);
}

.form-buy-data-item, .form-bill-container{
    display: flex;
    flex-direction: column;
    gap: 38px;
    margin-bottom: 50px;
}

.form-bill-container{
    margin: 0;
}

.buy-order-container {
    padding-left: 20%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.buy-order-container span, .buy-discount-container p, .delivery-text {
    font-family: interRegular;
    font-size: clamp(16px, 2.8vmin, 27px);
} 

.buy-order-data {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.buy-order-data div, .buy-order-total {
    display: flex;
    justify-content: space-between;
}

.buy-order-total {
    font-weight: 600;
}

.buy-discount-container {
    display: flex;
    gap: 2%;
    align-items: center;
}

.buy-discount-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.buy-orden-img {
    width: 140px;
    height: 120px;
}

.buy-orden-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.data-gray {
    color: #878787;
    margin: 0;
}

.data-line-through {
    text-decoration: line-through;
    padding-right: 15px;
}

.data-orange {
    color: #E8542F;
}

.buy-btn {
    background-color: #fff;
    border: 1px solid #878787;
    color: #878787;
    cursor: pointer;
}

.buy-select-btn {
    border: 1px solid #E8542F;
    color: #E8542F;
    background-color: #fff;
    cursor: pointer;
}

.custom-select{
    color: #878787
}

.buy-shopping-error span {
    color: #E8542F;
    font-size: clamp(15px, 2.3vmin, 20px);
}

.floating-label-code {
    position: absolute;
    color: #141414;
    top: -35%;
    right: 30px;
    font-family: interRegular;
    font-size: clamp(14px, 1.5vmin, 18px);
}

.floating-label-data {
    position: absolute;
    color: #141414;
    top: -20px;
    right: 20px;
    font-family: interRegular;
    font-size: clamp(13px, 1.5vmin, 18px);
    height: 100%;
}

.label-valid {
    color: #19B201;
}

.label-invalid {
    color: #E32B2B;
}


.floating-label-group {
	position: relative;
    width: 100%;
}

	.floating-label {
        font-family: interRegular;
		font-size: clamp(16px, 2vmin, 21px);
		color: #878787;
		position: absolute;
		pointer-events: none;
		top: 32%;
		left: 30px;
		transition: all 0.3s ease;
	}

	input:focus ~ .floating-label,
	input:not(:focus):valid ~ .floating-label {
		top: -20px;
		bottom: 0px;
		left: 30px;
		font-size: clamp(14px, 1.5vmin, 16px);
		opacity: 1;
		color: #878787;
	}

    input[type="email"]:not(:focus):not([value=""]) ~ .floating-label,
    input[type="email"]:focus ~ .floating-label {
        top: -20px;
        bottom: 0px;
        left: 30px;
        font-size: clamp(14px, 1.5vmin, 16px);
        opacity: 1;
        color: #878787;
    }


@media only screen and (max-width: 1300px){
    .form-buy-data-container {
        grid-template-columns: 1fr;
    }

    .form-buy-data-item {
        gap: 30px;
    }

    .buy-order-container {
        padding-left: 0;
        margin: 10% 0;
        gap: 30px;
    }

    .form-colum {
        grid-template-columns: 1fr;
        gap: 30px;
    }

    .floating-label-group input {
        height: 50px;
    }

    .form-colum .floating-label-group input {
        padding: 0 30px;
    }

    .form-colum button{
        padding: 0;
        height: 50px;
        font-size: 16px;
    }

    .form-colum select{
        padding: 0 30px;
        height: 50px;
    }

    .btn-back {
        padding: 10px 20px;
        margin-bottom: 40px;
    }

    .buy-code-data {
        grid-template-columns: 1fr;
    }

    .buy-btn {
        height: 50px;
    }
}

/******************  NEW FOOTER *******************/

.foot-customer-container {
    background-color: #141414;
    font-family: interRegular;
    color: #878787;
    font-size: clamp(18px,2.5vmin,22px);
    margin-top: -5%;
    border-radius: 50px 50px 0px 0px;
}

.foot-customer-container .foot-section {
    display: flex;
    justify-content: center;
    gap: 25px;
}

.foot-customer-container .foot-section p{
    margin: 0;
    text-align: center;
}

.foot-customer-container {
    padding: 3% 0;
}

.foot-customer-container .foot-section {
    width: 100%;
    margin: 0 auto;
}

@media only screen and (max-width: 1000px){
    .foot-customer-container {
        margin-top: -15%;
    }

    .foot-customer-container .foot-section {
        flex-direction: column;
        gap: 10px;
        padding: 25px 0;
    }
}