.navbarMobCont{
    width: 100%;
    height: 82px;
    flex-shrink: 0;
    z-index: 21;
    position: sticky;
    top: 0;
}

.navbarMobUpCont{
    width: 100%;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--ppolBlack);
}

.navbarMobBottomCont{
    width: 100%;
    height: calc(100vh - 82px);
    display: flex;
    position: absolute;
    transition: transform 0.4s ease-in-out;
    right: 0%;
}

.navbarMobBottomShow{
    right: 100%;
}

.navbarMobLeftCont{
    width: 272px;
    height: 100%;
    background-color: var(--ppolBlack);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow-y: auto;
    position: relative;
}

.navbarMobUpSection{
    height: fit-content;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: calc(100% - 80px);
}

.navbarMobBottomSection{
    height: fit-content;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: calc(100% - 80px);
}

.navbarMobListBtn{
    width: calc(100% - 16px);
    height: 48px;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding-left: 16px;
    gap: 8px;
    color: #7F7F7F;
    border: 0;
    font-family: interMedium;
    font-size: 16px;
    margin: 0;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    cursor: pointer;
}

.navbarMobListActive{
    background-color: #414141;
}

.navbarMobListBtn:hover{
    background-color: #222222;
}

.navbarMobListCheck{
    width: 14px;
    height: 30px;
    position: absolute;
    left: calc(0% - 7px);
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.navbarMobListCheckActive{
    background-color: var(--ppolOrange);
}

.navbarMobProfile{
    background-color: transparent;
    width: 100%;
    height: 54px;
    border: 1px solid #7F7F7F;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    color: #7F7F7F;
}

.navbarMobProfile:hover{
    border-color: var(--ppolOrange);
}

.navbarMobProfileCopy{
    background-color: var(--ppolOrange);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
    color: #F5F5F5;
    border-color: var(--ppolOrange);
}

.navbarMobProfileImg{
    height: 32px;
    width: 32px;
    object-fit: cover;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    padding: 11px;
}

.navbarMobProfileUsername{
    font-size: 16px;
    font-family: interMedium;
    white-space: nowrap;
    line-height: 20px;
}

.navbarMobRightCont{
    width: calc(100% - 272px);
    height: 100%;
    background-color: rgba(43, 43, 43, 0.8);
}

.navbarMobCheckboxCont{
    width: 50px;
    height: 60%;
    background-color: transparent;
    margin: 0px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.navbarMobCheckbox{
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
    transition: transform 0.4s ease-in-out;
    -webkit-transition: transform 0.4s ease-in-out;
    -moz-transition: transform 0.4s ease-in-out;
    -ms-transition: transform 0.4s ease-in-out;
    -o-transition: transform 0.4s ease-in-out;
}

.navbarMobLine{
    width: 27px;
    height: 3px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: var(--ppolWhite);
}

.navbarMobLine1{
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
}

.navbarMobLine2{
    transition: transform 0.2s ease-in-out;
}

.navbarMobLine3{
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
}

.navbarMobCheckbox:checked ~ .navbarMobLine1{
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

.navbarMobCheckbox:checked ~ .navbarMobLine2{
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
}

.navbarMobCheckbox:checked ~ .navbarMobLine3{
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}

@media only screen and (min-width: 1001px) {
    .navbarMobCont{
        display: none;
    }
}