

.react-grid-item.react-grid-placeholder {
    filter: grayscale(1) opacity(0.3)
}

.react-resizable-handle{
    z-index: 4 !important;
    filter: invert(1);
}

.reactEasyCrop_CropArea{
    border: 1px solid transparent !important;
}

.configuration{
    transition: all 2s ease
}

/*------ SECCION GLOBAL ------*/

.main{
    display: flex;
    flex-direction: row;
}

.infoSectionContainer{
    max-width: 1030px;
    width: 90%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: transparent;
}

/*------ SECCION DE TÍTULO DE PÁGINA DE LA WEB ------*/
.main-title-container{
    width: 87%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 28px;
    border-bottom: 2px solid #D1D1D1;
    margin-top: 30px;
    margin-bottom: 35px;
}

.main-title{
    font-size: 26px;
    font-family: interMedium;
    line-height: 35px;
    margin: 0;
    color: var(--ppolBlack);
}

.main-btn{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 12px;
    gap: 9px;
    background-color: #8D8D8D;
    color: var(--ppolWhite);
    font-size: 15px;
    font-family: interSemiBold;
    margin: 0;
    border: 0;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.main-btn:hover{
    background-color: var(--ppolBlack);
}

/* ------ MODAL DE PLANTILLAS ------ */

.templatesCont{
    height: fit-content;
    max-width: 430px;
    width: 80%;
    padding: 28px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 26px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.templatesTitle{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 12px;
}

.templatesTitleText{
    color: #8D8D8D;
    font-size: 33px;
    font-family: interSemiBold;
    line-height: 35px;
    margin: 0;
}

.templatesTitleBtn{
    background-color: transparent;
    margin: 0;
    border: 1px solid transparent;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.templatesTitleBtn:hover{
    border-color: #8D8D8D;
}

.templatesText{
    width: 100%;
    flex: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.templateTextTitle{
    font-size: 12px;
    font-family: interMedium;
    color: #8D8D8D;
    width: 100%;
    text-align: left;
    line-height: 18px;
    padding: 0;
    margin: 0;
}

.templateTextDesc{
    font-size: 13.5px;
    font-family: interMedium;
    color: #141414;
    width: 100%;
    text-align: left;
    line-height: 18px;
    padding: 0;
    margin: 0;
}

.templateSelection{
    display: grid;
    width: 100%;
    height: fit-content;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 12px;
}

.templateSelectionCont{
    width: 100%;
    height: 170px;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
    background-color: #141414;
    border: 0;
}

.templateSelectionCont:hover{
    outline: 2px solid var(--ppolOrange);
}

@keyframes scaleTemplates {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
    }
    100% {
        transform: scale(1.15);
        -webkit-transform: scale(1.15);
        -moz-transform: scale(1.15);
        -ms-transform: scale(1.15);
        -o-transform: scale(1.15);
    }
}

.templateSelectionCont:hover .templateSelectionImg{
    animation: scaleTemplates 1s ease-in-out alternate;
    -webkit-animation: scaleTemplates 1s ease-in-out alternate;
    animation-fill-mode: forwards;
}

.templateSelectionImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    mask-image: linear-gradient(black 30%, transparent);
}

.templateSelectionTitle{
    position: absolute;
    bottom: 35px;
    left: 14px;
    margin: 0;
    font-family: interMedium;
    font-size: 10px;
    line-height: 18px;
    color: #FFFFFF;
}

.templateSelectionDesc{
    position: absolute;
    bottom: 17px;
    left: 14px;
    margin: 0;
    font-family: interMedium;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
}

.templateBtnSection{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.templateBtn{
    width: 100%;
    height: 48px;
    border: 1px solid;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    font-family: interMedium;
    font-size: 18px;
}

.templateBtnWhite{
    color: var(--ppolBlack);
    border-color: #D9D9D9;
    background-color: #F9F9F9;
}

.templateBtnWhite:hover{
    background-color: #E3E3E3;
    border-color: #E3E3E3;
}

.templateBtnOrange{
    color: #FFFFFF;
    border-color: var(--ppolOrange);
    background-color: var(--ppolOrange);
}

.templateBtnOrange:hover{
    background-color: #FF8061;
    border-color: #FF8061;
}

/* ------ SECCION SOPORTE ------ */

.support{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: var(--ppolWhite);
    min-height: 100vh;
}

.supportCont{
    width: 87%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    margin-bottom:35px
}

.supportSection{
    width: calc(90% - 40px);
    max-width: 640px;
    background-color: #FFFFFF;
    min-height: 175px;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    gap: 60px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    border: 1px solid #D9D9D9;
}

.supportSectionTextCont{
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 9px;
}

.supportSectionImgCont{
    height: 172px;
    width: 172px;
    background-color: transparent;
    flex-shrink: 0;
}

.supportSectionImg{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.supportSectionTitle{
    font-size: 28px;
    font-family: interMedium;
    color: #909090;
    margin: 0;
}

.supportSectionDesc{
    font-size: 20px;
    font-family: interMedium;
    color: var(--ppolBlack);
    margin: 0;
}

.supportSectionBtn{
    height: 43px;
    width: 140px;
    border: 1px solid #D9D9D9;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    background-color: transparent;
    color: var(--ppolBlack);
    font-size: 15px;
    font-family: interMedium;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.supportSectionBtn:hover{
    background-color: #E3E3E3;
    border-color: #E3E3E3;
}

/*------ SECCIÓN TUTORIALES ------*/

.tutorialSearchCont{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 32px 0px;
    border-bottom: 1px solid #DBDBDB;
    gap: 12px;
}

.tutorialSearchBtns{
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.tutorialSearchBtn{
    padding: 12px 15px;
    color: var(--ppolBlack);
    border: 1px solid #D9D9D9;
    font-size: 15px;
    font-family: interMedium;
    background-color: #FFFFFF;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
}

.tutorialSearchBtn:hover{
    background-color: #E7E7E7;
}

.tutorialSearchBtnActive{
    background-color: #141414;
    color: #FFFFFF;
}

.tutorialSearchBarCont{
    position: relative;
}

.tutorialSearchBarIcon{
    position: absolute;
    left: 12px;
    top: calc(50% - 9.5px);
}

.tutorialSearchBar{
    padding: 12px 12px 12px 40px;
    width: 250px;
    font-size: 15px;
    font-family: interMedium;
    color: var(--ppolBlack);
    border: 1px solid #D9D9D9;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
}

.tutorialVideosCont{
    max-width: 100%;
    background-color: transparent;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(3, minmax(310px, 1fr));
    gap: 45px;
    grid-auto-flow: dense;
    margin: 30px 0px;

}

.tutorialVideo{
    background-color: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    object-fit: cover;
}

.tutorialVideoBig{
    grid-column-end: span 2;
    grid-row-end: span 2;
}

.tutorialVideoSrc{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
}

.tutorialVideoTitle{
    font-size: 15px;
    font-family: interMedium;
    text-align: left;
    width: 100%;
    color: var(--ppolBlack);
    margin: 0;
}

/*------ SECCION CONTACTOS ------*/

.contactSectionCont{
    width: 90%;
    height: fit-content;
    min-height: 500px;
    max-width: 1030px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.contactFilterSection{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.contactSearchFilterCont{
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.contactSearchBarCont{
    position: relative;
}

.contactSearchBar{
    width: 100%;
    padding: 12px 12px 12px 40px;
    background-color: #FFFFFF;
    color: #141414;
    font-size: 15px;
    font-family: interMedium;
    line-height: 18px;
    border: 1px solid #D9D9D9;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    width: 255px;
}

.contactFilterCont{
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.contactFilterSelect{
    color: #141414;
    font-size: 15px;
    font-family: interMedium;
    line-height: 18px;
}

.contactFilterSelectSmall{
    width: 144px;   
}

.contactFilterSelectBig{
    width: 172px;
}

.contactSearchBarIcon{
    position: absolute;
    top: calc(50% - 9.5px);
    left: 12px;
}

.contactBtnCont{
    display: flex;
    gap: 12px;
    flex-direction: row;
}

.contactBtnWhite{
    padding: 12px 15px;
    border: 1px solid #9D9D9D;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    background-color: #FFFFFF;
    color: #141414;
    font-family: interMedium;
    font-size: 15px;
    line-height: 18px;
    height: 100%;
}

.contactBtnWhite:hover{
    background-color: #E3E3E3;
    border-color: #E3E3E3;
}

.contactBtnOrange{
    padding: 12px 15px;
    border: 1px solid var(--ppolOrange);
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    font-size: 15px;
    font-family: interSemiBold;
    color: #FFFFFF;
    background-color: var(--ppolOrange);
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactBtnOrange:hover{
    background-color: #FF8061;
    border-color: #FF8061;
}

.contactFilterFound{
    font-family: interMedium;
    font-size: 12.5px;
    line-height: 18px;
    padding: 0px 14px;
    color: #8C8E90;
    margin: 0;
}

.contactTableContainer{
    width: 100%;
    height: fit-content;
    flex-shrink: 0;
}

.contactTable{
    width: 100%;
    font-family: interMedium;
    background-color: #D9D9D9;
    border: 1px solid #D9D9D9;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    overflow: hidden;
    border-collapse: separate;
    border-spacing: 0px 1px;
}

.contactTableHeader{
    font-size: 12px;
    color: var(--ppolBlack);
    line-height: 18px;
    height: 48px;
    background-color: var(--ppolWhite);
    text-align: left;
}

.contactTableRow{
    color: var(--ppolBlack);
    background-color: #FFFFFF;
    height: 72px;
}

.contactTableCheckCont{
    width: 5%;
}

.contactTableCheck{
    cursor: pointer;
    background-color: transparent;
    width: 20px;
    height: 20px;
    appearance: none;
    border: 1px solid #8C8E90;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 10px;
}

.contactTableCheck:checked {
    background-color: transparent;
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='gray' xmlns='http://www.w3.org/2000/svg'><path d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/></svg>");
}

.contactTableCheck:disabled {
    background-color: transparent;
    background-image: none;
}

.contactTableName{
    font-size: 12.5px;
    color: var(--ppolBlack);
    width: 15%;
}

.contactTablePhoneMail{
    width: 20%;
}

.contactTablePhoneMailText{
    font-size: 12.5px;
    color: #8C8E90;
    margin: 1.5px;
}

.contactTableLabelCont{
    width: 20%;
}

.contactLabel{
    max-width: 110px;
    width: 100%;
    height: 20px;
    text-align: center;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-size: 12px;
    font-family: interMedium;
}

.contactLabelMini{
    width: 40px;
    height: 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin: 5px;
}

.contactTableMsg{
    font-size: 12.5px;
    color: #8C8E90;
    width: 30%;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.contactTableEditBtnCont{
    width: 10%;
}

.contactTablePaginationCont{
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.contactTablePagBtn{
    width: 90px;
    height: 30px;
    border: 1px solid #D9D9D9;
    background-color: #FFFFFF;
    font-family: interMedium;
    color: #878787;
    font-size: 14px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.contactTablePagBtn:hover{
    color: var(--ppolBlack);
    background-color: #E3E3E3;
    border-color: #E3E3E3;
}

.contactTablePagText{
    width: 150px;
    font-family: interMedium;
    font-size: 14px;
    text-align: center;
    color: #878787;
}

/* --- SECCION METRICAS --- */

.metricsSectionCont{
    width: 90%;
    max-width: 1028px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 80px;
}

.metricsFilterBtnCont{
    width: 100%;
    height: fit-content;
    margin: 25px 0px 15px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    overflow-y: hidden;
}

.metricsFilterBtn{
    width: 100%;
    height: 50px;
    font-family: interMedium;
    font-size: 15px;
    border: 1px solid #D9D9D9;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    background-color: #FFFFFF;
    color: var(--ppolBlack);
    min-width: 140px;
}

.metricsFilterBtnSelected{
    background-color: var(--ppolBlack);
    color: #FFFFFF;
    border-color: var(--ppolBlack);
}

.metricsFilterBtn:hover{
    border-color: var(--ppolBlack);
}

.metricsCounterSection{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 16px;
}

.metricsCounterCont{
    width: calc(100% - 44px);
    height: 135px;
    border: 1px solid #D9D9D9;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    background-color: #FFFFFF;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 9px;
    color: var(--ppolBlack);
    padding: 0px 22px;
}

.metricsCounterTitle{
    font-size: 12.5px;
    font-family: interMedium;
    margin: 0;
}

.metricsCounterDesc{
    font-size: 34px;
    font-family: interMedium;
    margin: 0;
}

.metricsCounterIcon{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.metricsCounterIconOrange{
    background-color: var(--ppolOrange);
    filter: drop-shadow(0px 4px 6px var(--ppolOrange));
    -webkit-filter: drop-shadow(0px 4px 6px var(--ppolOrange));
}

.metricsCounterIconBlue{
    background-color: #415AD2;
    filter: drop-shadow(0px 4px 6px #415AD2);
    -webkit-filter: drop-shadow(0px 4px 6px #415AD2);
}

.metricsCounterIconYellow{
    background-color: #FFCA00;
    filter: drop-shadow(0px 4px 6px #FFCA00);
    -webkit-filter: drop-shadow(0px 4px 6px #FFCA00);
}

.metricsCounterIconGreen{
    background-color: #00953B;
    filter: drop-shadow(0px 4px 6px #00953B);
    -webkit-filter: drop-shadow(0px 4px 6px #00953B);
}

.metricsDataSection{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
}

.metricContainerTitle{
    font-family: interBold;
    font-size: 14px;
    color: #141414;
    width: 100%;
    text-align: left;
    margin: 0;
    line-height: 18px;
}

.metricContainerSubtitle{
    font-family: interMedium;
    font-size: 12.5px;
    color: #141414;
    width: 100%;
    text-align: left;
    margin: 0;
    line-height: 18px;
    margin-bottom: 10px;
}

.metricContainer{
    border: 1px solid #D9D9D9;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    background-color: #FFFFFF;
    padding: 22px;
    z-index: 1;
}

.metricContainerBar{
    grid-column: 1/4;
    height: 370px;
}

.metricContainerBarMobile{
    grid-column: 1/4;
    height: 700px;
}

.metricMap{
    grid-column: 1/3;
    grid-row: 2/4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.metricPie{
    grid-column: 3;
    height: 168px;
}

.metricSingleBar{
    grid-column: 3;
    height: 168px;
}

.metricComparitionBarCont{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
}

.metricCompatitionTextCont{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.metricComparitionTitle{
    font-family: interMedium;
    font-size: 12.5px;
    color: #A0A0A0;
    margin: 0;
}

.metricComparitionDesc{
    font-family: interMedium;
    font-size: 30px;
    color: #A0A0A0;
    margin: 0;
}

.metricComparitionBar{
    width: 100%;
    height: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: #D9D9D9;
    margin-top: 10px;
}

.metricComparitionBarLeft{
    background-color: #E8542F;
    height: 100%;
    border-radius: 5px;
}

.metricVisitorTableSection{
    height: 290px;
    display: flex;
    flex-direction: column;
}

.metricVisitorTableCont{
    width: 100%;
    overflow-y: auto;
}

.metricVisitorTable{
    width: 100%;
    max-width: 100%;
    background-color: #FFFFFF;
    text-align: center;
    border: 0;
    border-collapse: collapse;
    color: #A0A0A0;
    font-family: interMedium;
    font-size: 12px;
}

.metricVisitorTableTitleRow{
    padding-bottom: 10px;
}

.metricVisitorTableDataRow{
    padding: 5px 0px;
}

.metricVisitorTablePos{
    width: 4%;
    max-width: 4%;
}

.metricVisitorTableCity{
    width: 27%;
    max-width: 27%;
}

.metricVisitorTableCountry{
    width: 27%;
    max-width: 27%;
}

.metricVisitorTableDate{
    width: 42%;
    max-width: 42%;
}

.metricVisitorTableDataRowCont:nth-child(even){
    background-color: var(--ppolWhite);
}

.metricContactLine{
    grid-column: 2/4;
    height: 290px;
}






.metricsContainer{
    background-color: transparent;
    max-width:1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns:repeat(3, 1fr);
    width:100%;
    grid-gap:20px
}

.metricContainerMedium{
    height:400px;
    border: 2px solid #FFFFFF;
    border-radius:12px;
}

.metricContainerLarge{
    height:262.5px;
    border-radius:12px;
    background-color: #FFFFFF
}

.metricContainerError{
    width: 100%;
    margin-top: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: black;
    font-family: interBold;
    font-size: 24px;
}

.metricTypeWebMobile{
    display: flex;
    align-items:center;
    justify-content:space-between;
    flex-direction: column;
}

.metricTypeWebMobileCont{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.metricTypeWebMobileTextboxCont{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 56px - 18px);
}

.metricTypeWebMobileTextbox{
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.metricTypeWebMobileTextboxTitle{
    font-family: interSemiBold;
    margin: 0;
    font-size: 15px;
    color: #868686;
}

.metricTypeWebMobileTextboxNumber{
    font-family: gilroySemiBold;
    margin: 0;
    font-size: 27px;
    color: #868686;
}

.metricTypeTable{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #FFFFFF;
}

.metricTableContainer{
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
    background-color: red;
}

.metricTable{
    max-width:100%;
    width:100%;
    background-color:#FFFFFF;
    font-size:11px;
    text-align: center;
    font-family: interSemiBold;
    color: #5A5A5A;
    border: 0;
    border-collapse: collapse;
}

.metricTableTitle{
    padding:10px 5px;
}

.metricTable tbody tr:nth-child(even){
    background-color: #F5F5F5;
}

.metricTypeCard{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10%;
}

.metricTypeBar{
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.metricsMoreInfoBtn{
    font-size: 10px;
    border: 1px solid #868686;
    border-radius: 50%;
    height: 13px;
    width: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 21px;
    margin-bottom: 13px;
    color: #868686;
    position: relative;
    font-family: interSemiBold;
}

.metricsMoreInfoSection{
    height: fit-content;
    width: fit-content;
    position: absolute;
    right: 150%;
    display: none;
    align-items: center;
    justify-content: center;
    border: 2px solid #868686;
    width: 160px;
    border-radius: 12px;
    padding: 3px;
    text-align: center;
    background-color: white;
    font-size: 10px;
    color: #868686;
}

.metricsMoreInfoBtn:hover{
    color: #E8542F;
    cursor: pointer;
    border-color: #E8542F;
}

.metricsMoreInfoBtn:hover .metricsMoreInfoSection{
    display: flex;
}

/* ------ SECCION CONFIGURACION ------ */
.configSectionCont{
    width: calc(90% - 56px);
    max-width: 614px;
    padding: 28px;
    background-color: white;
    height: fit-content;
    border: 1px solid #D9D9D9;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.configTypeBtnCont{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    flex-direction: row;
}

.configTypeBtn{
    padding: 9px;
    background-color: #F3F5F7;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    color: #8D8D8D;
    font-size: 15px;
    font-family: interMedium;
    border: 1px solid #F3F5F7;
}

.configTypeBtn:hover{
    border-color: var(--ppolBlack);
}

.configTypeBtnSelect{
    background-color: var(--ppolBlack);
    color: white;
    border-color: var(--ppolBlack);
}

.configTypeSectionCont{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 25px;
}

.configTypeTitle{
    font-size: 33px;
    font-family: interSemiBold;
    width: 100%;
    text-align: left;
    color: var(--ppolOrange);
    border-bottom: 1px solid #DDDDDD;
    margin: 0;
    padding: 10px 0px;
}

.configTypeSection{
    margin: 25px 0px 30px 0px;
    width: 100%;
    gap: 15px;
    display: flex;
    flex-direction: column;
}

.configTypeInputCont{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 6px;
    position: relative;
}

.configTypeInputTitle{
    font-size: 12px;
    font-family: interMedium;
    color: #8D8D8D;
    width: 100%;
}

.configTypeInputDesc{
    font-size: 12px;
    font-family: interRegular;
    color: #8D8D8D;
    width: 100%;
}

.configTypeInputFloatText{
    text-align: left;
    width: 100%;
    color:var(--ppolBlack);
    font-size: 13.5px;
    font-family: interSemiBold;
    position: absolute;
    top: 36px;
    left: 15px;
    margin: 0;
    width: fit-content;
}

.configTypeInput{
    height: 42px;
    width: calc(100% - 30px);
    border: 1px solid #D7D7D7;
    border-radius: 9px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 13.5px;
    font-family: interMedium;
    color: var(--ppolBlack);
    background-color: #FFFFFF;
}

.configTypeInputPw{
    width: calc(100% - 30px - 40px);
    padding: 0px 55px 0px 15px;
}

.configTypeInputFloat{
    width: calc(100% - 80px);
    padding-left: 65px;
}

.configTypeSectionSeparator{
    width: 100%;
    height: 1px;
    background-color: #DDDDDD;
}

.configTypeCheckCont{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    font-family: interRegular;
    color: #8D8D8D;
    gap: 5px;
}

.configTypeDoubleInput{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 12px
}

.configTypeInputNumber{
    width: 75%;
}

.configTypeSelect{
    width: 25%;
}

.config-option-select {
    border: none;
    border-radius: 9px 0 0 9px;
    width: 200px;
    padding: 10px;
}

.config-select {
    height: 52px;
    width: 100%;
    border: 1px solid #D7D7D7;
    border-radius: 9px;
    font-size: 15px;
    font-family: interRegular;
    color: #878787;
    display: flex;
    background-color: #fff;
}

.config-input-phone-number{
    max-height: 100%;
    width: 100%;
    border: 1px solid #D7D7D7;
    border-radius: 0 9px 9px 0;
    padding-left: 20px;
    font-size: 15px;
    font-family: interRegular;
    color: #878787;
}

.configQRSectionCont{
    width: calc(100% - 30px);
    height: 180px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    border: 1px solid #D9D9D9;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
}

.configQRSectionTextCont{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 175px;
}

.configQRSectionText{
    font-size: 13.5px;
    font-family: interMedium;
    color: var(--ppolBlack);
    margin: 0;
}

.configQRSectionQR{
    width: 176px;
    height: 176px;
}

.configTypeInputTextMiddle{
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: interRegular;
    color: #878787;
    text-decoration: underline;
    cursor: pointer;
}

.configTypeBlackCont{
    width: calc(100% - 40px);
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: var(--ppolBlack);
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    padding: 20px;
    gap: 13px;
}

.configTypeConfigBtn{
    width: 100%;
    background-color: red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 13.5px;
    font-family: interMedium;
    background-color: #FFFFFF;
    color: var(--ppolBlack);
    padding: 12px 15px;
    text-align: center;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
}

.configChangeBtn{
    width: 32px;
    height: 32px;
    border: 1px solid #8D8D8D;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.configChangeBtn:hover{
    border-color: var(--ppolOrange);
}

.configChangeBtnSelected{
    border-color: var(--ppolOrange);
}

.configTypeSectionRow{
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 4fr;
    margin-top: 10px;
    margin-bottom: 10px;
}

.configTypeChangeBtnCont{
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.configTypeColorSelectCont{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 13px;
    margin: 10px 0px;
}

.configTypeColorSelectContOverflow{
    height: 52px;
    width: 50%;
    border: 0;
    padding: 0;
    cursor: pointer;
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline-offset: -5px;
    outline: 5px solid
}

.configTypeColorSelect{
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0;
    cursor: pointer;
}



































.configConfirmBtn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0px;
    background-color: var(--ppolOrange);
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    border: 0;
    font-size: 16px;
    font-family: interMedium;
    color: #FFFFFF;
}

.configConfirmBtn:hover{
    background-color: #FF8061
}


/*--------------------------------------------------------*/

.dashboard{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--ppolWhite);
    min-height: 100vh;
}

.configuration{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: var(--ppolWhite);
    min-height: 100vh;
}

.config-top{
    width: 93%;
    height: 84px;
    border-bottom: 2px solid #DDDDDD;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #8D8D8D;
    font-family: gilroyBold;
    font-size: 30px;
    margin-bottom: 4px;
    flex-shrink: 0;
}

.config-container{
    display: flex;
    flex-direction: row;
    width: 93%;
    height: fit-content;
    gap: 20px;
}

.config-form{
    width: 100%;
    height: auto;
    color: black;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 20px;
}

.config-form-section{
    height: fit-content;
    width: 100%;
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 15px;
    position: relative;
}

.config-span{
    text-align: left;
    width: 100%;
    color:#8D8D8D;
    font-size: 18px;
    font-family: gilroyBold;
}

.config-input{
    height: 52px;
    width: calc(100% - 40px);
    border: 1px solid #D7D7D7;
    border-radius: 9px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
    font-family: interRegular;
    color: #878787;
}

.config-select-2{
    width: 300px;
    display:grid;
    border-radius: 9px;
    font-size: 15px;
    font-family: interRegular;
    color: #878787;
    background-color: #fff;
}

.QRImgContainer{
    width: fit-content;
    height: fit-content;
}

.config-qr{
    width: 200px;
    height: 200px;
    border: 2px dashed #8D8D8D;
    border-radius: 12px;
}

.config-button{
    color: white;
    font-size: 16px;
    font-family: gilroyBold;
    background-color: #8D8D8D;
    border: 0;
    border-radius: 9px;
    cursor: pointer;
    height: 44px;
    width: 100%;
    max-width: 500px;
    margin-bottom: 40px;
}

.config-button:hover{
    background-color: #E8542F;
    color: white;
}

.config-img{
    width: fit-content;
    height: auto;
    grid-column: 2;
    grid-row: 2;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 20px;
}

.config-img img{
    width: fit-content;
    height: fit-content;
}

.config-form-swapBtn{
    width: 100%;
    max-width: 150px;
    height: 50px;
    border-radius: 6px;
    border: 0;
    background-color: #8D8D8D;
    cursor: pointer;
}

.config-form-swapBtn:hover{
    background-color: #E8542F;
}

.config-form-swapBtn img{
    height: 75%;
}

.dashboard-top{
    width: 93%;
    height: 84px;
    border-bottom: 2px solid #DDDDDD;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

.dashboard-top-title{
    font-family: gilroyBold;
    font-size: 30px;
    color: #8d8d8d;
    line-height: 35px;
    margin: 0;
}

.dashboard-top-btns{
    display: flex;
    flex-direction: row;
}

.dashboard-top-btn{
    height: 35px;
    width: 132px;
    background-color: transparent;
    color: #8d8d8d;
    font-family: gilroyBold;
    font-size: 16px;
    line-height: 20px;
    border: 1px solid #8d8d8d;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin-left: 20px;
    cursor: pointer;
    position: relative;
}

.dashboard-top-btn img{
    margin-left: 12px;
    margin-right: 8px;
}

.dashboard-top-btn:hover{
    background-color: #E8542F;
    color: white;
    border: 1px solid #E8542F;
}

.dashboard-top-btn:hover .dashboard-top-icon{
    filter: brightness(200%)
}

.dashboard-link{
    width: 93%;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    color: #8d8d8d;
    font-size: 16px;
    font-family: interMedium;
}

.dashboard-link a{
    color: #8d8d8d;
}

.dashboard-navbar{
    width: 340px;
    height: 61px;
    background-color: #F5F5F5;
    border: 1px solid #dedede;
    border-radius: 12px;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 35px;
    flex-shrink: 0;
    filter: drop-shadow(0px 6px 9px rgba(47,47,47,0.2));
    z-index: 10;
}

.dashboard-navbar-btn{
    width: 33px;
    height: 33px;
    background-color: #E8542F;
    outline: 5px solid transparent;
    border: 0px;
    outline-offset: 0px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    margin-left: 11px;
    margin-right: 11px;
}

.dashboard-navbar-btn img{
    width: fit-content;
    height: fit-content;
    filter: brightness(100);
}

.dashboard-navbar-btn:hover{
    background-color: #E8542F;
    outline-color: #E2E2E2;
}

.dashboard-creation-navbar{
    background-color: #F5F5F5;
    border: 1px solid #dedede;
    position: absolute;
    height: 450px;
    top: calc(0% - 450px - 12px);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #959595;
}

.dashboard-creation-navbar p{
    font-family: interMedium;
    font-size: 24px;
    margin-top: 25px;
    margin-bottom: 5px;
}

.dashboard-creation-navbar span{
    font-family: interRegular;
    font-size: 16px;
}

.dashboard-creation-navbar-carousel{
    width: 100%;
    height: 100%;
    background-color: transparent;
    margin-bottom: 5px;
}

.dashboard-creation-navbar-carousel-btn-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.dashboard-creation-navbar-carousel-btn{
    background-color: transparent;
    width: fit-content;
    max-width: 320px;
    height: fit-content;
    max-height: 300px;
    border: 0;
    padding: 0;
    cursor: pointer;
    margin-bottom: 20px;
}

.dashboard-creation-navbar-carousel-btn img{
    width: 320px;
    height: 300px;
    max-height: 300px;
    max-width: 320px;
    object-fit: contain;
}

.close-creation-bar{
    position: absolute;
    top: calc(0% + 14px);
    right: calc(0% + 14px);
    width: 22px;
    height: 22px;
    border: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 3px solid transparent;
    cursor: pointer;
    background-color: #A0A0A0;
}

.close-creation-bar:hover{
    outline-color: #E2E2E2;
    background-color: #E8542F;
}

.close-creation-bar img{
    width: 22px;
    height: 22px;
    filter: brightness(100);
}

.close-creation-bar:hover img{
    filter: none;
}

/*.dashboard-navbar-toggle{
    width: 44px;
    height: 37px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #6D6D6D;
    font-family: interRegular;
    font-size: 13px;
    margin-left: 12px;
    margin-bottom: 2px;
}

.dashboard-navbar-input{
    height: 0;
    width: 0;
    visibility: hidden;
    margin: 0;
    margin-top: 0px;
}

.dashboard-navbar-label{
    cursor: pointer;
    text-indent: -9999px;
    width: 44px;
    height: 20px;
    background: #E8542F;
    display: block;
    border-radius: 20px;
    position:relative;
    flex-shrink: 0;
}

.dashboard-navbar-label:after{
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 14px;
    height: 14px;
    background: #fff;
    border-radius: 15px;
    transition: 0.3s;
    border: 1px solid #DEDEDE
}

.dashboard-navbar-input:checked + .dashboard-navbar-label{
    background: #415AD2;
}

.dashboard-navbar-input:checked + .dashboard-navbar-label:after{
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.dashboard-navbar-label:active:after{
    width: 20px;
}*/

.navbar-hover{
    min-height: 70px;
    bottom: calc(0% + 70px);
    background-color: #F5F5F5;
    border: 1px solid #dedede;
    position: absolute;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #808080;
    text-align: center;
}

.navbar-hover p {
    font-size: 13px;
    padding: 0 20px;
    font-family: interRegular;
}

.navbar-hover.show-navbar {
    display:flex;
}

#dashboard-top-btn1:hover .profileTemplate{
    display: flex;
}

.profileTemplate{
    width: 489px;
    height: 286px;
    position: absolute;
    top: 100%;
    right: 0%;
    background-color: white;
    z-index: 10;
    border-radius: 6px;
    border: 1px solid #D9D9D9;
    padding: 26px;
    display: none;
    align-self: center;
    justify-content: space-between;
    flex-direction: row;
}

.profileTemplateContainer{
    width: 155px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.profileTemplateTitle{
    width: 100%;
    height: 27px;
    color: #8D8D8D;
    font-size: 12px;
    font-family: interSemiBold;
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.profileTemplateDescription{
    width: 100%;
    height: 72px;
    color: #8D8D8D;
    font-size: 11px;
    font-family: interRegular;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: start;
    line-height: 110%;
}

.profileTemplateImgBtnContainer{
    width: 100%;
    height: 164px;
    background-color: #e9e9e9;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profileTemplateImg{
    width: 95px;
    height: 95px;
    margin-bottom: 21px;
}

.profileTemplateBtn{
    background-color: #E8542F;
    border: 0;
    margin: 0;
    color: white;
    font-size: 12px;
    font-family: gilroyBold;
    width: 80px;
    height: 21px;
    border-radius: 6px;
    cursor: pointer;
}

.profileTemplateBtn:hover{
    background-color: white;
    color: black;
}

.profile-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    margin: auto;
    margin-top: 20px;
    width: 390px;
}

.profile{
    /*background-color: #141414;*/
    background-color: #141414;
    height: 100%;
    width: 430px;
    border-radius: 15px;
    margin-bottom: 133px;
}

.banner-photo{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    width: 100%;
    height: 160px;
    object-fit: cover;
    flex-shrink: 0;
    position: relative;
}

.banner-photoImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 9px;
}

.banner-photoImgGradient{
    width: 100%;
    height: 160px;
    object-fit: cover;
    position: absolute;
}

.addBannerPhoto{
    width: 40px;
    position: absolute;
    height: 40px;
    display: flex;
    align-items:center;
    justify-content: center;
    background-color: transparent;
    padding: 0;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    top: 7px;
    right: 7px
}

.addBannerPhotoBtnImg{
    width: 100%;
    height: 100%;
    background-color: transparent;
    border:0;
    object-fit:contain;
}

.loadingBtn {
    width: 70px;
    height: 30px;
    background-color: #959595;
    border-radius: 9px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editBtn{
    width: 70px;
    height: 30px;
    border: 0;
    position: absolute;
    top: 7px;
    border-radius: 9px;
    left: 7px;
    background-color: transparent;
    font-family: nimbusSansBold;
    font-size: 16px;
    color: 1E1E1E;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.editBtn img{
    width: 100%;
    height: 100%;
}

.deleteItemBtn{
    width: 28px;
    height: 28px;
    position: absolute;
    top: calc(0% - 8px);
    left: calc(100% - 20px);
    cursor: pointer;
    border: 0;
    border-radius: 50%;
    background-color: #454545;
    align-items: center;
    justify-content: center;
    padding: 0;
    z-index: 4;
}

.deleteItemBtn:hover{
    background-color: white;
}

.deleteItemBtn img{
    width: 100%;
    height: 100%;
}

.deleteItemBtn:hover img{
    filter: invert(1);
}

.deleteItemBtnImg{
    width: fit-content;
    height: fit-content;
    background-color: transparent;
}

.deleteLinkBtn{
    width: 28px;
    height: 28px;
    position: absolute;
    top: calc(0% - 10px);
    left: 60%;
    cursor: pointer;
    border: 0;
    border-radius: 50%;
    background-color: #454545;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.deleteLinkBtn img{
    width: 100%;
    height: 100%;
}

.deleteLinkBtn:hover{
    background-color: white;
}

.deleteLinkBtn:hover img{
    filter: invert(1);
}

.deleteLinkBtnImg{
    width: fit-content;
    height: fit-content;
    background-color: transparent;
}

.focusItemBtn{
    width: 28px;
    height: 28px;
    position: absolute;
    top: calc(0% - 8px);
    left: calc(100% - 54px);
    cursor: pointer;
    border: 0;
    border-radius: 50%;
    background-color: #454545;
    align-items: center;
    justify-content: center;
    padding: 0;
    z-index: 4;
}

.focusItemBtn:hover{
    background-color: white;
}

.focusItemBtn:hover img{
    filter: invert(1)
}

.focusItemBtnImg{
    width: fit-content;
    height: fit-content;
    background-color: transparent;
}

.moveMapBtn{
    width: 28px;
    height: 28px;
    position: absolute;
    top: calc(0% + 60px);
    left: calc(100% - 20px);
    cursor: pointer;
    border: 0;
    border-radius: 50%;
    background-color: #454545;
    align-items: center;
    justify-content: center;
    padding: 0;
    z-index: 4;
}

.moveMapBtn:hover{
    background-color: white;
}

.moveMapBtn:hover img{
    filter: invert(1)
}

.moveMapBtnImg{
    width: fit-content;
    height: fit-content;
    background-color: transparent;
}

.moveItemBtn{
    width: 28px;
    height: 28px;
    position: absolute;
    top: calc(0% + 26px);
    left: calc(100% - 20px);
    cursor: move;
    border: 0;
    border-radius: 50%;
    background-color: #454545;
    align-items: center;
    justify-content: center;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
}

.moveItemBtn:hover, .moveItemBtn:active{
    background-color: white;
}

.moveItemBtn:hover img, .moveItemBtn:active img{
    filter: invert(1);
}

.changeTextAlignBtn{
    width: 28px;
    height: 28px;
    position: absolute;
    top: calc(0% - 8px);
    left: calc(100% - 54px);
    cursor: pointer;
    border: 0;
    border-radius: 50%;
    background-color: #454545;
    align-items: center;
    justify-content: center;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
}

.changeTextAlignBtn:hover, .changeTextAlignBtn:active{
    background-color: white;
}

.changeTextAlignBtn:hover img, .changeTextAlignBtn:active img{
    filter: invert(1);
}

.changeTextAlignVBtn{
    width: 28px;
    height: 28px;
    position: absolute;
    top: calc(0% - 8px);
    left: calc(100% - 88px);
    cursor: pointer;
    border: 0;
    border-radius: 50%;
    background-color: #454545;
    align-items: center;
    justify-content: center;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    transform: rotate(90deg);
}

.changeTextAlignVBtn:hover, .changeTextAlignVBtn:active{
    background-color: white;
}

.changeTextAlignVBtn:hover img, .changeTextAlignVBtn:active img{
    filter: invert(1);
}

.changeTextSizeBtn{
    width: 28px;
    height: 28px;
    position: absolute;
    top: calc(0% + 26px);
    left: calc(100% - 20px);
    cursor: pointer;
    border: 0;
    border-radius: 50%;
    background-color: #454545;
    align-items: center;
    justify-content: center;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
}

.changeTextSizeBtn:hover, .changeTextSizeBtn:active{
    background-color: white;
}

.changeTextSizeBtn:hover img, .changeTextSizeBtn:active img{
    filter: invert(1);
}

.editItemBtn{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 0;
    background-color: red;
    position: absolute;
    top: calc(100% - 8px);
    left: calc(50% - 12px);
    padding: 0;
}

.addProfilePhoto{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: transparent;
    position: absolute;
    top: calc(100% - 60px);
    left: calc(100% - 30px);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #808080;
    cursor: pointer;
}

.addProfilePhotoBtnImg{
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 50%;
    border: 0;
}

.profile-photo{
    width: 145px;
    height: 145px;
    border: 0;
    position: absolute;
    top: 53px;
    border-radius: 50%;
    background-color: transparent;
}

.profile-photoImg{
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 50%;
    border: 0px;
    object-fit: cover;
}

.profile-separationA{
    background-color: transparent;
    height: 40px;
    width: 100%;
}

.profile-name{
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-shrink: 0;
    position: relative;
}

.profile-name input{
    background-color: transparent;
    font-family: gilroyBold;
    font-size: 24px;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 0;
    color: white;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align:center;
}

.profile-name input:focus{
    background-color: #4A4B50;
    border: 0;
}

.profile-title{
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-shrink: 0;
    position: relative;
}

.profile-title input{
    background-color: transparent;
    font-family: gilroyMedium;
    font-size: 16px;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 0;
    color: white;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align:center;
}

.profile-title input:focus{
    background-color: #4A4B50;
    border: 0;
}

.profile-description{
    margin-top: 20px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    flex-shrink: 0;
    position: relative;
}

.profile-description textarea{
    background-color: transparent;
    font-family: interRegular;
    font-size: 15px;
    width: 100%;
    max-width: 100%;
    min-height: 18px;
    max-height: 100px;
    border: 0;
    color: white;
    resize: none;
    text-align: center;
    outline: none;
    border-radius: 9px;
    display: block;
    overflow: hidden;
    overflow-y: auto;
}

.profile-description textarea:focus{
    background-color: #4A4B50;
    border: 0;
}

.profile-social-links{
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1;
    width: 100%;
    height: fit-content;
    margin-bottom: 25px;
    margin-top: 25px;
    position: relative;
    flex-shrink: 0;
}

.profile-add-socialBtn{
    height: 44px;
    width: 44px;
    border: 0;
    border-radius: 50%;
    background-color: #D7D7D7;
    margin: 0px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-add-socialBtn:hover{
    cursor: pointer;
}

.profile-add-socialBtn span{
    color: #141414;
    font-size: 20px;
    font-family: nimbusSansBold;
}

.input-social-media-link{
    background-color: #fafafa;
    padding: 10px;
    padding-right: 140px;
    width: calc(96% - 140px);
    height: 25px;
    position: absolute;
    border:0;
    border-radius: 9px;
    z-index: 8;
    outline: none;
}

.input-social-media-addbtn{
    width: 80px;
    height: 30px;
    z-index: 9;
    border: 1px solid #A0A195;
    border-radius: 6px;
    position: absolute;
    left: calc(100% - 80px - 49px);
    background-color: #A0A195;
    color: white;
    cursor: pointer;
    font-size: 12px;
    font-family: interSemiBold;
}

.input-social-media-addbtn:hover{
    background-color: white;
    color: #A0A195;
}

.input-social-media-closebtn{
    width: 30px;
    height: 30px;
    z-index: 9;
    border: 1px solid #A0A195;
    border-radius: 50%;
    position: absolute;
    left: calc(100% - 32px - 10px);
    background-color: #A0A195;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding-top: 0;
    color: white;
}

.input-social-media-closebtn:hover{
    background-color: white;
    color: #a0a195;
}

.profile-link-href{
    height: 44px;
    width: 44px;
    border: 0;
    border-radius: 50%;
    margin: 0px 12px
}

.profile-social-link-temptext{
    font-size: 16px;
    font-family: gilroyBold;
    color: #D7D7D7;
    border: 1px solid #D7D7D7;
    border-radius: 9px;
    height: 49px;
    width: 161px;
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
}


.profile-social-link-temptext span{
    width: 105px;
    margin-right: 6px;
}

.profile-social-link-temptext img{
    width: fit-content;
    height: fit-content;
}

.profile-add-share{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 43px;
    gap: 10px;
}

.profile-preview-btns{
    width: 100%;
    height: 65px;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #141414;
    gap: 10px;
}

.profile-preview-btns button{
    height: 41px;
    width: 100%;
    color: #1E1E1E;
    background-color: #D7D7D7;
    border: 0;
    border-radius: 9px;
    font-family: nimbusSansBold;
    font-size: 16px;
}

.profile-add-btn{
    height: 45px;
    width: 50%;
    border: 0;
    border-radius: 9px;
    font-family: nimbusSansBold;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-share-btn{
    height: 45px;
    width: 50%;
    border: 0;
    border-radius: 9px;
    font-family: nimbusSansBold;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.createImgAddImgNoText{
    width: 36px;
    height: 36px;
    background-color: #454545;
    color: white;
    font-family: nimbusSansBold;
    border: 0;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    cursor: pointer;
}

.createImgAddImg{
    width: 153px;
    height: 37px;
    background-color: #454545;
    color: white;
    font-family: nimbusSansBold;
    border:0;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
}

#profile-conditional-separationLine{
    background-color: green;
    display: none;
    height: 80px;
    width: 100%;
}

.profile-created-with-ppol{
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.profile-created-with-ppol span{
    font-family: nimbusSans;
    font-size: 10px;
}

.profile-created-with-ppol img{
    width: 72px;
}

.profile-config-contact{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
}

.profile-config-contact-btn{
    background-color: #8D8D8D;
    width: 100%;
    height: 41px;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    color: white;
    font-family: nimbusSansBold;
    font-size: 16px;
}

.profile-config-contact-btn:hover{
    background-color: #E8542F;
}

/*------------------------------------------------------------------*/
/*AQUI COMIENZAN LOS ESTILOS DE LOS MÓDULOS*------------------------*/
/*------------------------------------------------------------------*/

/*------------ LOCK IMG ------------*/

.lockContainer{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(20, 20, 20, 0.75);
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
    cursor: -webkit-grab;
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lockImgContainer{
    width: 47px;
    height: 47px;
    background-color: #9D9D9D;
    border-radius: 6px;
}

.lockContainer:active{
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.lockImg{
    width: 100%;
    height: 100%;
}

.lockCarouselContainer{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(20,20,20,0.75);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
}

.deleteCarouselItem{
    position: absolute;
    top: calc(50% + 3px);
    left: calc(50% + 3px);
    width: 35px;
    height: 35px;
    border: 0;
    border-radius: 25%;
    margin: 0;
    padding: 0;
    background-color: #9D9D9D;
    cursor: pointer;
}

.moveCarouselItemR{
    position: absolute;
    bottom: calc(50% + 3px);
    left: calc(50% + 3px);
    width: 35px;
    height: 35px;
    border: 0;
    border-radius: 25%;
    margin: 0;
    padding: 0;
    background-color: #9D9D9D;
    cursor: pointer;
}

.moveCarouselItemL{
    position: absolute;
    bottom: calc(50% + 3px);
    right: calc(50% + 3px);
    width: 35px;
    height: 35px;
    border: 0;
    border-radius: 25%;
    margin: 0;
    padding: 0;
    background-color: #9D9D9D;
    cursor: pointer;
}

.changeImgCarouselItem{
    position: absolute;
    top: calc(50% + 3px);
    right: calc(50% + 3px);
    width: 35px;
    height: 35px;
    border: 0;
    border-radius: 25%;
    margin: 0;
    padding: 0;
    background-color: #9D9D9D;
    cursor: pointer;
}

.imgLink {
    position: absolute;
    top: 4px;
    width: calc(100% - 12px);
    padding: 2px;
    border: 0;
    height: 21px;
    border-radius: 6px;
    color: white;
    font-size: 12px;
    font-family: interRegular;
    background-color: #141414;
}

/*------------ TEXTO 4x0m ------------*/

.text4x0m{
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 9px;
    border: 0
}

.text4x0m input{
    background-color: transparent;
    font-family: gilroySemiBold;
    font-size: 24px;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    border: 0;
    color: white;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    outline: none;
}

.text4x0m input:focus{
    background-color: #4A4B50;
}

/*------------ TEXTO 2x0m ------------*/

.text2x0m{
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 9px;
    border: 0
}

.text2x0m input{
    background-color: transparent;
    font-family: gilroySemiBold;
    font-size: 24px;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    border: 0;
    color: white;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    outline: none;
}

.text2x0m input:focus{
    background-color: #4A4B50;
}

/*------------ TEXTO 2x1 ------------*/

.text2x1{
    background-color: #34353A;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    border: 0;
}

.text2x1 textarea{
    background-color: transparent;
    font-family: interRegular;
    font-size: 14px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    border: 0;
    color: white;
    resize: none;
    text-align: left;
    outline: none;
    text-overflow: ellipsis;    
}

/*------------ TEXTO 2x2 ------------*/

.text2x2{
    background-color: #34353A;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    border: 0
}

.text2x2 textarea{
    background-color: transparent;
    font-family: interRegular;
    font-size: 14px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    border: 0;
    color: white;
    resize: none;
    text-align: left;
    outline: none;
    text-overflow: ellipsis;
}

/*------------ TEXTO 4x2 ------------*/

.text4x2{
    background-color: #34353A;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    border: 0
}

.text4x2 textarea{
    background-color: transparent;
    font-family: interRegular;
    font-size: 14px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    border: 0;
    color: white;
    resize: none;
    text-align: left;
    outline: none;
    text-overflow: ellipsis;
}

/*------------ TEXTO 2x4 ------------*/

.text2x4{
    background-color: #34353A;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    border: 0
}

.text2x4 textarea{
    background-color: transparent;
    font-family: interRegular;
    font-size: 14px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    border: 0;
    color: white;
    resize: none;
    text-align: left;
    outline: none;
    text-overflow: ellipsis;
}

/*------------ TEXTO fxf ------------*/

.textfxf{
    background-color: #34353A;
    display: flex;
    justify-content: center;
    border-radius: 9px;
    border: 0;
}

.textfxf textarea{
    background-color: transparent;
    font-family: interRegular;
    width: calc(100% - 24px);
    max-height: calc(100% - 24px);
    border: 0;
    color: white;
    resize: none;
    outline: none;
    text-overflow: clip;
    display: flex;
    overflow-x: hidden;
    margin: 10.5px;
}

/*------------ IMAGEN 2x2 ------------*/

.img2x2{
    background-color: #34353A;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
}

.img2x2MediaCont{
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.img2x2Img{
    background-color: #34353A;
    width: 100%;
    height: 100%;
}

.img2x2Video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

/*------------ IMAGEN 2x4 ------------*/

.img2x4{
    background-color: #34353A;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
}

.img2x4MediaCont{
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.img2x4Img{
    background-color: #34353A;
    width: 100%;
    height: 100%;
}

.img2x4Video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

/*------------ IMAGEN 4x2 ------------*/

.img4x2{
    background-color: #34353A;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
}

.img4x2MediaCont{
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.img4x2Img{
    background-color: #34353A;
    width: 100%;
    height: 100%;
}

.img4x2Video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

/*------------ IMAGEN 4x4 ------------*/

.img4x4{
    background-color:#34353A;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
}

.img4x4MediaCont{
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.img4x4Img{
    background-color: #34353A;
    width: 100%;
    height: 100%;
}

.img4x4Video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

/*------------ IMAGEN fxf ------------*/

.imgfxf{
    background-color:#34353A;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
}

.imgfxfMediaCont{
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.imgfxfImg{
    background-color: #34353A;
    width: 100%;
    height: 100%;
}

.imgfxfVideo{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

/*------------ CARRUSEL 4x2m ------------*/

.carousel4x2m{
    background-color: transparent;
    width: 100%;
    height: 100%;
}

.carousel4x2mContainer{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 9px;
}

.carousel4x2mMediaCont{
    width: 100%;
    height: 100%;
    border-radius: 9px;
    background-color: #34353A
}

.carousel4x2mImg{
    width: 100%;
    height: 100%;
}

.carousel4x2mVideo{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

/*------------ CARRUSEL 4x1 ------------*/

.carousel4x1{
    background-color: transparent;
    width: 100%;
    height: 100%;
}

.carousel4x1Container{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 9px;
}

.carousel4x1MediaCont{
    width: 100%;
    height: 100%;
    background-color: #34353A;
    border-radius: inherit;
}

.carousel4x1Img{
    width: 100%;
    height: 100%;
}

.carousel4x1Video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

/*------------ CARRUSEL 4x3m ------------*/

.carousel4x3m{
    background-color: transparent;
    width: 100%;
    height: 100%;
}

.carousel4x3mObjContainer{
    width: 100%;
    height: 100%;
    background-color: #34353A;
    border-radius: 9px;
}

.carousel4x3mContainer{
    background-color: transparent;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
}

.carousel4x3mTextCont{
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0
}

.carousel4x3mLink{
    position: absolute;
    top: 16px;
    left: 16px;
    width: calc(100% - 36px);
    padding: 2px;
    border: 0;
    height: 21px;
    border-radius: 6px;
    background-color: transparent;
    color: white;
    font-size: 12px;
    font-family: interRegular;
    background-color: #141414;
    z-index: 1;
}

.carousel4x3mMediaCont{
    width: calc(100% - 20px);
    height: 147px;
    border-radius: 9px;
    background-color: #34353A;
    margin: 10px;
    margin-bottom: 0;
    flex-shrink: 0;
}

.carousel4x3mImg{
    width: 100%;
    height: 100%;
}

.carousel4x3mVideo{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

.carousel4x3mTitle{
    background-color: transparent;
    font-family: interBold;
    font-size: 17px;
    width: calc(100% - 24px);
    max-height: 40px;
    border: 0;
    color: white;
    resize: none;
    text-align: left;
    outline: none;
    text-overflow: ellipsis;
    border-radius: 9px;
    margin-left: 10px;
    margin-right: 10px;
}

.carousel4x3mDescription{
    background-color: transparent;
    font-family: interRegular;
    font-size: 13px;
    width: calc(100% - 24px);
    max-height: 48px;
    border: 0;
    color: white;
    resize: none;
    text-align: left;
    outline: none;
    text-overflow: ellipsis;
    border-radius: 9px;
    margin-left: 10px;
    margin-right: 10px;
}

.carousel4x3mPrice{
    background-color: #4A4B50;
    border: 0;
    border-radius: 9px;
    font-family: interBold;
    font-size: 18px;
    color: white;
    padding: 6px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    max-width: calc(100% - 32px);
    white-space: nowrap;
    overflow: hidden !important;
    outline: none;
    text-overflow: ellipsis;
    display: inline-block;
    margin-bottom: 10px;
}

.carousel4x3mTitle:focus, .carousel4x3mDescription:focus{
    background-color: #141414;
}

/*------------ CARRUSEL 4x1m ------------*/

.carousel4x1m{
    background-color: transparent;
    width: 100%;
    height: 100%;
}

.carousel4x1mContainer{
    width: 100%;
    height: 100%;
    background-color: #34353A;
    border-radius: 9px;
    object-fit: cover;
}

.carousel4x1mHead{
    width: calc(100% - 28px);
    height: 48px;
    background-color: transparent;
    margin-bottom: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    margin-left: 14px;
    margin-right: 14px;
    margin-top: 14px;
}

.carousel4x1mMediaCont{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: transparent;
    overflow: hidden;
    position: relative;
}

.carousel4x1mImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

.carousel4x1mTitleStars{
    margin-left: 8px;
    height: 100%;
    width: calc(100% - 48px);
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.carousel4x1mTitle{
    background-color: transparent;
    font-family: interRegular;
    font-size: 15px;
    width: calc(100% - 4px);
    height: 20px;
    border-radius: 9px;
    border: 0;
    color: white;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    outline: none;
    margin-bottom: 2px;
}

.carousel4x1mStars{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    height: fit-content;
    width: fit-content;
    gap: 2px;
}

.carousel4x1mStar{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    width: 25px;
    height: 25px;
    font-family: interRegular;
    font-size: 25px;
    cursor: pointer;
}

.carousel4x1mDescription{
    background-color: transparent;
    font-family: interRegular;
    font-size: 12px;
    width: calc(100% - 32px);
    height: 50px;
    border:0;
    border-radius: 9px;
    color: white;
    resize:none;
    text-align: left;
    outline: none;
    text-overflow: ellipsis;
    margin-left: 14px;
    margin-right: 14px;
}

.carousel4x1mTitle:focus, .carousel4x1mDescription:focus{
    background-color: #141414;
}

/*------------ CARRUSEL 4x2 ------------*/

.carousel4x2{
    background-color: transparent;
    width: 100%;
    height: 100%;
}

.carousel4x2Container{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
}

.carousel4x2Link{
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(100% - 12px);
    padding: 2px;
    border: 0;
    height: 21px;
    border-radius: 6px;
    background-color: transparent;
    color: white;
    font-size: 12px;
    font-family: interRegular;
    background-color: #141414;
    z-index: 1;
}

.carousel4x2MediaCont{
    width: 100%;
    height: 138px;
    border-radius: 9px;
    background-color: #34353A
}

.carousel4x2Img{
    width: 100%;
    height: 100%;
}

.carousel4x2Video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

.carousel4x2Title{
    background-color: transparent;
    font-family: interRegular;
    font-size: 13px;
    width: calc(100% - 4px);
    height: 32px;
    border: 0;
    color: white;
    resize: none;
    text-align: left;
    outline: none;
    text-overflow: ellipsis;
    border-radius: 9px;
}

.carousel4x2Title:focus{
    background-color: #34353A;
}

/*------------ SEPARATION ------------*/

.separation{
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    width: 100%;
    height: 100%;
}

.separationLine{
    width: 100%;
    height: 10px;
    border-radius: 5px;
}

/*------------ ERASER 4x1m ------------*/

.eraser4x1m{
    background-color: #34353A;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    width: 100%;
    height: 100%;
}

.eraser4x1mMediaCont{
    width: 50%;
    height: 100%;
    border-radius: 9px 0px 0px 9px;
    flex-shrink: 0;
    background-color: #34353A;
    position: relative;
}

.eraser4x1mImg{
    width: 100%;
    height: 100%;
}

.eraser4x1mVideo{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

.eraser4x1mLink{
    position: absolute;
    top: 3px;
    left: 3px;
    width: 46%;
    padding: 2px;
    border: 0;
    height: 21px;
    border-radius: 6px;
    background-color: #141414;
    color: white;
    font-size: 12px;
    font-family: interRegular;
    z-index: 3;
}

.eraser4x1mContainer{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 9px
}

.eraser4x1mTitle{
    background-color: transparent;
    font-family: interRegular;
    font-size: 18px;
    width: calc(100% - 30px);
    max-height: 42px;
    border: 0;
    color: white;
    resize: none;
    text-align: left;
    outline: none;
    margin: 0;
    letter-spacing: -2%;
    border-radius: 9px;
}

.eraser4x1mDescription{
    background-color: transparent;
    font-family: interRegular;
    font-size: 13px;
    width: calc(100% - 30px);
    max-height: 64px;
    border: 0;
    color: white;
    resize: none;
    text-align: left;
    outline: none;
    letter-spacing: -2%;
    border-radius: 9px;
    margin: 0;
}

.eraser4x1mTitle:focus, .eraser4x1mDescription:focus{
    background-color: #4A4B50;
}

/*------------ SECTION 4x1m ------------*/

.section4x1m{
    background-color: #34353A;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    width: 100%;
    height: 100%;
}

.section4x1mMediaCont{
    position: relative;
    width: 110px;
    height: 110px;
    border-radius: 6px;
    margin: 15px;
    flex-shrink: 0;
    background-color: #34353A;
}

.section4x1mImg{
    width: 100%;
    height: 100%;
}

.section4x1mVideo{
    width: 110px;
    height: 110px;
    object-fit: cover;
    border-radius: inherit;
}

.section4x1mLink{
    position: absolute;
    top: 17px;
    left: 17px;
    width: 102px;
    padding: 2px;
    border: 0;
    height: 21px;
    border-radius: 6px;
    background-color: #141414;
    color: white;
    font-size: 12px;
    font-family: interRegular;
    z-index: 3;
}

.section4x1mContainer{
    width: calc(100% - 140px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
}

.section4x1mTitle{
    background-color: transparent;
    font-family: interMedium;
    font-size: 16px;
    width: calc(100% - 20px);
    max-height: 40px;
    border: 0;
    color: white;
    resize: none;
    text-align: left;
    outline: none;
    margin: 0;
    letter-spacing: -2%;
    border-radius: 9px;
}

.section4x1mDescription{
    background-color: transparent;
    font-family: interRegular;
    font-size: 12px;
    width: calc(100% - 20px);
    max-height: 45px;
    border: 0;
    color: white;
    resize: none;
    text-align: left;
    outline: none;
    letter-spacing: -2%;
    border-radius: 9px;
}

.section4x1mTitle:focus, .section4x1mDescription:focus{
    background-color: #4A4B50;
}

/*------------ DATE 4x1 ------------*/

.date4x1{
    background-color: #34353A;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    width: 100%;
    height: 100%;
}

.date4x1Date{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: #141414;
    border-radius: 6px;
    margin: 15px;
}

.date4x1Day{
    background-color: transparent;
    width: 60px;
    height: 33px;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    color: white;
    font-family: interRegular;
    font-size: 27px;
    text-align: center;
    letter-spacing: -2%;
    margin-bottom: -3px;
}

.date4x1Month{
    background-color: transparent;
    width: 60px;
    height: 17px;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    color: white;
    font-family: interRegular;
    font-size: 14px;
    text-align: center;
    letter-spacing: -2%;
}

.date4x1Link{
    position: absolute;
    top: 3px;
    left: 3px;
    width: 80px;
    padding: 2px;
    border: 0;
    height: 21px;
    border-radius: 6px;
    background-color: transparent;
    color: white;                                                                                                                                                                                                
    font-size: 12px;
    font-family: interRegular;
    background-color: #141414;
    z-index: 3;
}

.date4x1Container{
    width: calc(100% - 90px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px
}

.date4x1Title{
    background-color: transparent;
    font-family: interRegular;
    font-size: 18px;
    width: calc(100% - 10px);
    height: 22px;
    border-radius: 9px;
    border: 0;
    color: white;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    outline: none;
}

.date4x1Description{
    background-color: transparent;
    font-family: interRegular;
    font-size: 15px;
    width: calc(100% - 10px);
    max-height: 38px;
    border: 0;
    color: white;
    resize: none;
    text-align: left;
    outline: none;
    text-overflow: ellipsis;
    letter-spacing: -2%;
    border-radius: 9px;
}

.date4x1Title:focus, .date4x1Description:focus{
    background-color: #4A4B50;
}

/*------------ SECTION 2x2 ------------*/

.section2x2{
    background-color: #34353A;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    width: 100%;
    height: 100%;
}

.section2x2MediaCont{
    position: relative;
    width: 100%;
    height: 95px;
    border-radius: 6px 6px 0 0;
    flex-shrink: 0;
    background-color: #34353A;
}

.section2x2Img{
    width: 100%;
    height: 100%;
}

.section2x2Video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit
}

.section2x2Link{
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(100% - 12px);
    padding: 2px;
    border: 0;
    height: 21px;
    border-radius: 6px;
    background-color: transparent;
    color: white;                                                                                                                                                                                                
    font-size: 12px;
    font-family: interRegular;
    background-color: #141414;
    z-index: 3;
}

.section2x2Container{
    background-color: transparent;
    width: 100%;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section2x2Title{
    background-color: transparent;
    font-family: interRegular;
    font-size: 18px;
    width: calc(100% - 30px);
    max-width: calc(100% - 30px);
    max-height: 63px;
    border: 0;
    color: white;
    resize: none;
    text-align: left;
    outline: none;
    letter-spacing: -2%;
    border-radius: 9px;
    margin: 0;
    overflow-x: hidden
}

.section2x2Title:focus{
    background-color: #4A4B50;
}

/*------------ SECTION 4x1 ------------*/

.section4x1{
    background-color: #34353A;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    width: 100%;
    height: 100%;
}

.section4x1MediaCont{
    position: relative;
    background-color: #34353A;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-left: 14px;
    margin-right: 14px;
    overflow: hidden;
}

.section4x1Img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit
}

.section4x1Link{
    position: absolute;
    top: 3px;
    right: 3px;
    width: calc(100% - 96px);
    padding: 2px;
    border: 0;
    height: 21px;
    border-radius: 6px;
    background-color: transparent;
    color: white;
    font-size: 12px;
    font-family: interRegular;
    z-index: 3;
    background-color: #141414;
}

.section4x1Container{
    background-color: transparent;
    width: calc(100% - 90px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.section4x1Title{
    background-color: transparent;
    font-family: interRegular;
    font-size: 18px;
    width: calc(100% - 6px);
    height: 25px;
    border-radius: 9px;
    border: 0;
    color: white;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    outline: none;
}

.section4x1Description{
    background-color: transparent;
    font-family: interRegular;
    font-size: 15px;
    width: calc(100% - 6px);
    height: 17px;
    border: 0;
    color: white;
    resize: none;
    outline: none;
    text-align: left;
    outline: none;
    text-overflow: ellipsis;
    letter-spacing: -2%;
    border-radius: 9px;
}

.section4x1Title:focus, .section4x1Description:focus{
    background-color: #4A4B50;
}

/*------------ EMBED 4x1 ------------*/

.embed4x1{
    background-color: #34353A;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    width: 100%;
    height: 100%;
}

.embed4x1Link{
    position: absolute;
    top: 3px;
    right: 3px;
    width: calc(100% - 10px);
    padding: 2px;
    border: 0;
    height: 21px;
    border-radius: 6px;
    background-color: transparent;
    color: white;
    font-size: 12px;
    font-family: interRegular;
    background-color: #141414;
    z-index: 3;
}

.embed4x1Container{
    background-color: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.embed4x1Title{
    background-color: transparent;
    font-family: interRegular;
    font-size: 15px;
    width: calc(100% - 10px);
    height: 25px;
    border-radius: 9px;
    border: 0;
    color: white;
    text-align: center;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    outline: none;
}

.embed4x1Title:focus{
    background-color: #4A4B50;
}

/*------------ SECTION 2x3m ------------*/

.section2x3m{
    background-color: #34353A;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 9px;
    width: 100%;
    height: 100%;
}

.section2x3mMediaCont{
    width: calc(100% - 24px);
    height: 166px;
    position: relative;
    margin: 12px;
    margin-bottom: 0;
    background-color: #34353A;
    border-radius: 9px;
}

.section2x3mImg{
    width: 100%;
    height: 100%;
}

.section2x3mVideo{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

.section2x3mLink{
    position: absolute;
    top: 18px;
    left: 18px;
    width: calc(100% - 40px);
    padding: 2px;
    border: 0;
    height: 21px;
    border-radius: 6px;
    background-color: #141414;
    color: white;
    font-size: 12px;
    font-family: interRegular;
    z-index: 3;
}

.section2x3mContainer{
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
}

.section2x3mTitle{
    background-color: transparent;
    font-family: interBold;
    font-size: 17px;
    max-height: 40px;
    border: 0;
    color: white;
    resize: none;
    text-align: left;
    outline: none;
    text-overflow: ellipsis;
    border-radius: 9px;
    width: calc(100% - 24px);
    margin: 0;
}

.section2x3mDescription{
    background-color: transparent;
    font-family: interRegular;
    font-size: 13px;
    width: calc(100% - 28px);
    max-height: 48px;
    border: 0;
    color: white;
    resize: none;
    text-align: left;
    outline: none;
    text-overflow: ellipsis;
    border-radius: 9px;
    width: calc(100% - 24px);
    margin: 0;
}

.section2x3mPrice{
    background-color: #4A4B50;
    border: 0;
    border-radius: 9px;
    font-family: interBold;
    font-size: 18px;
    color: white;
    padding: 6px;
    text-align: center;
    margin-left: 12px;
    margin-right: 12px;
    width: calc(100% - 36px);
    white-space: nowrap;
    overflow: hidden !important;
    outline: none;
    text-overflow: ellipsis;
    display: inline-block;
    margin-bottom: 12px;
    flex-shrink: 0;
}

.section2x3mTitle:focus, .section2x3mDescription:focus{
    background-color: #141414;
}

/*------------ FILE PDF ------------*/

.filePdf{
    background-color: #34353A;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    border: 0;
    gap:10px
}

.filePdfTitle{
    background-color: transparent;
    font-family: interRegular;
    width: calc(100% - 90px);
    max-height: 60px;
    border: 0;
    color: white;
    resize: none;
    text-align: left;
    outline: none;
    border-radius: 9px;
}

/*------------ EMBED SPOTIFY ------------*/
 
.embedSpotify{
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 9px;
    flex-direction: column;
}

.embedSpotify iframe{
    border: 0;
    border-radius: 9px;
}

.input-spotify-link{
    background-color: #fafafa;
    padding: 10px;
    width: 80%;
    border:0;
    border-radius: 9px;
    z-index: 3;
}

/*------------ EMBED SPOTIFY MINI ------------*/
 
.embedSpotifyMini{
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 9px;
    flex-direction: row;
}

.embedSpotifyMini iframe{
    border: 0;
    border-radius: 9px;
}

.input-spotifyMini-link{
    background-color: #fafafa;
    padding: 10px;
    width: 70%;
    border:0;
    border-radius: 9px;
    z-index: 2;
}

/*------------ SECTION YOUTUBE ------------*/
 
.sectionYoutube{
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #2f2f2f;
    border-radius: 9px;
    flex-direction: column;
}

.sectionYoutube iframe{
    border: 0;
    border-radius: 9px;
}

.sectionYoutubeLink{
    background-color: #fafafa;
    padding: 10px;
    width: 80%;
    border:0;
    border-radius: 9px;
    z-index: 3;
}

/*------------ SECTION VIMEO ------------*/
 
.sectionVimeo{
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparentsporita;
    border-radius: 9px;
    flex-direction: column;
}

.sectionVimeo iframe{
    border: 0;
    border-radius: 9px;
}

.sectionVimeoLink{
    background-color: #fafafa;
    padding: 10px;
    width: 80%;
    border:0;
    border-radius: 9px;
    z-index: 3;
}

/*------------ SECTION MAPS4x4 ------------*/
.map4x4, .map4x2, .map2x2{
    border-radius: 9px;
}

/*------------ SECTION TWITCH ------------*/
 
.sectionTwitch{
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #2f2f2f;
    border-radius: 9px;
    flex-direction: column;
}

.sectionTwitchLink{
    background-color: #fafafa;
    padding: 10px;
    width: 80%;
    border:0;
    border-radius: 9px;
    z-index: 3;
}

/*------------------------------------*/

.photo-text2x5{
    background-color: #34353A;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.photo-text2x5-img{
    width: 88%;
    margin-left: 6%;
    margin-right: 6%;
    margin-top: 6%;
    height: 172px;
    background-color: rgb(228, 228, 228);
    border-radius: 9px;
}

.photo-text2x5 textarea{
    width: 88%;
    margin-left: 6%;
    margin-right: 6%;
    background-color: transparent;
    border: 0;
    padding: 0;
    resize: none;
    overflow: hidden;
}

.photo-text2x5 textarea:focus{
    background-color: #4A4B50;
}

.photo-text2x5-text-title{
    height: 40px;
    font-family: nimbusSansBlack;
    font-size: 16px;
    color: white;
}

.photo-text2x5-text-description{
    height: 35px;
    font-family: nimbusSans;
    font-size: 14px;
    color: white
}

.photo-text2x5 .photo-text2x5-text-price{
    width: auto;
    min-width: 60px;
    height: auto;
    background-color: #4A4B50;
    border: 0;
    border-radius: 9px;
    font-family: nimbusSansBold;
    font-size: 20px;
    color: white;
    margin-left: 6%;
    margin-bottom: 6%;
    padding: 7px;
    text-align: center;
    margin-top: 6px;
}
 /*----------------------------------------------*/
.tempdiv{
    width: 100%;
    height: 100%;
    background-color: transparent;
    text-align: center;
}

.photo-temp2x5{
    background-color: #34353A;
    width: 97%;
    height: 100%;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.photo-temp2x5-img{
    width: 88%;
    margin-left: 6%;
    margin-right: 6%;
    margin-top: 6%;
    height: 172px;
    background-color: rgb(228, 228, 228);
    border-radius: 9px;
}

.photo-temp2x5 textarea{
    width: 88%;
    margin-left: 6%;
    margin-right: 6%;
    background-color: transparent;
    border: 0;
    padding: 0;
    resize: none;
    margin-bottom: 11px;
    overflow: hidden;
}

.photo-temp2x5 textarea:focus{
    background-color: #4A4B50;
}

.photo-temp2x5-text-title{
    height: 40px;
    font-family: nimbusSansBlack;
    font-size: 16px;
    color: white;
}

.photo-temp2x5-text-description{
    height: 35px;
    font-family: nimbusSans;
    font-size: 14px;
    color: white
}

.photo-temp2x5 .photo-temp2x5-text-price{
    width: auto;
    min-width: 60px;
    height: auto;
    background-color: #4A4B50;
    border: 0;
    border-radius: 9px;
    font-family: nimbusSansBold;
    font-size: 20px;
    color: white;
    margin-left: 6%;
    margin-bottom: 6%;
    padding: 7px;
    text-align: center;
    margin-top: 6px;
}

/*-------------------------------------------*/

.carrousel-images{
    background-color: transparent;
}

.carrousel-images div{
    height: 100%;
}

.carrousel-images img{
    width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.carousel-container2{
    background-color: red;
    padding-left: 4px;
}

.slider-image-item2 img{
    background-color: transparent;
    border-radius: 50%;
}

/*-----------------------------------------*/

.carousel-img-4x6-img-container{
    width: 138px;
    height: 247px;
    border: 0;
    border-radius: 9px;
    background-color: red;
}

.navbar-profile-mini{
    display: none;
    background-color: rgb(20, 20, 20);
    height: 70px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    position: sticky;
    top: 0;
    z-index: 20;
}

.nav{
    width: 297px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top:0px;
    background-color: #141414;
    z-index: 1;
}

.nav-top{
    background-color: transparent;
    display: grid;
    width: 73%;
}

#ppolLogo{
    margin-top: 35px;
    margin-bottom: 45px
}


.nav-bottom{
    background-color: transparent;
    display: grid;
    width: 73%;
    
}

.navBtn{
    width: 100%;
    color: #8D8D8D;
    font-size: 16px;
    font-family: gilroyBold;
    background-color: transparent;
    height: 48px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-right: 30px;
    border-radius: 6px;
}

.navBtn:hover{
    background-color: #333333;
    /*color: #6A1803;*/
}

.navBtn img{
    margin-left: 16px;
    margin-right: 8px;
    filter:invert(0.5);
    
}

/*.navBtn:hover img{
    filter: invert(0);
}*/

.navbar-profile-mini-navBtn{
    height: 100%;
    width: 90%;
    color: #8D8D8D;
    font-size: 16px;
    font-family: gilroyBold;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-right: 30px;
    border-radius: 12px;
}

.navbar-profile-mini-navBtn:hover{
    background-color: #333333;
    /*color: #6A1803;*/
}

.navbar-profile-mini-navBtn img{
    margin-left: 16px;
    margin-right: 8px;
    filter:invert(0.5);
}

.profileNavImg{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-left: 12px;
    margin-right: 8px;
}

.profileNavImg img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.profileBtn{
    width: 100%;
    height: 54px;
    margin-bottom: 50px;
    margin-top: 20px;
    background-color: transparent;
    border: 1px solid #505050;
    border-radius: 6px;
    color: #8D8D8D;
    font-family: interRegular;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.profileBtn:hover{
    background-color: #333333;
}

.profileNavNameOptions{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 145px;
}

.profileNavNameOptions span{
    outline: none;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.profileBtnOptions{
    height: 146px;
    width: 148px;
    padding: 26px;
    background-color: #141414;
    border-radius: 12px;
    display: none;
    position: absolute;
    top: calc(100% - 246px);
    left: 259px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profileBtn:hover .profileBtnOptions{
    display: flex;
}

.profileBtnOptions a{
    width: calc(100% - 32px);
}

.profileOptionsBtn{
    width: 100%;
    height: 48px;
    border-radius: 6px;
    border: 0;
    background-color: transparent;
    color: #8D8D8D;
    font-family: gilroyBold;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 16px;
    cursor: pointer;
}

.profileOptionsBtn span{
    margin-left: 8px;
}

.profileOptionsBtn:hover{
    background-color: #333333;
}

.profileOptionsBtn #profileImgNav{
    transition: transform 1s ease-in-out;;
}

.profileOptionsBtn:hover #profileImgNav{
    transform: rotate(360deg);
}



.notFoundContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: var(--ppolBlack);
}

.notFoundImg{
    width: fit-content;
    height: fit-content;
}

.notFoundRight{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.notFoundRight p{
    margin: 0;
    font-size: 80px;
    font-family: gilroyBold;
    color: #696969;
}

.notFoundRight span{
    margin-top: 14px;
    margin-bottom: 57px;
    font-size: 20px;
    font-family: interRegular;
    color: #FFFFFF;
    width: 385px;
}

.notFoundRight a{
    margin: 0;
    width: 128px;
    height: 52px;
    border: 0;
    border-radius: 9px;
    background-color: #E8542F;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-family: gilroyBold;
    color: #FFFFFF;
}

.prox{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background-color: var(--ppolWhite);
}

.proxImg{
    width: fit-content;
    height: fit-content;
    margin: auto;
}

/*-----------------------------------------------*/

.navbar-profile-mini-menu-button::before{
    content: "";
    margin-top: -8px;
}

.navbar-profile-mini-menu-button::after{
    content: "";
    margin-top: 8px;
}

.navbar-profile-mini-menu{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(8, 1fr);
    list-style-type: none;
    padding: 0;
    width: 100%;
    /*box-shadow: -80px 0px 0px 0px #141414, 80px 0px 0px 0px #141414;*/
    position: absolute;
    top: 0;
    margin-top: 70px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 20;
    background-color: rgb(20,20,20);
    color: #141414;
}

.navbar-profile-mini-menu > li{
    margin: 0;
    overflow: hidden;
}

.navbar-profile-mini li{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.navbar-profile-mini-menu-button-container{
    display: none;
    height: 100%;
    width: 30px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.navbar-profile-mini-menu-button, .navbar-profile-mini-menu-button::before, .navbar-profile-mini-menu-button::after{
    display: block;
    background-color: #F5F5F5;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    /*border-radius: 2px;*/
}

.navbar-profile-mini-menu-button-container{
    display: flex;
    padding: 10px;
}

#navbar-profile-mini-menu-toggle ~ .navbar-profile-mini-menu li{
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: opacity 5s;
}
    
#navbar-profile-mini-menu-toggle:checked ~ .navbar-profile-mini-menu li{
    height: calc((100vh - 70px) / 9);
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

/*#navbar-profile-mini-menu-toggle:checked ~ .navbar-profile-mini-menu li:nth-child(even){
    background-color: red;
}*/

/*#navbar-profile-mini-menu-toggle:checked ~ .navbar-profile-mini-menu li:nth-child(even){
    border-top: 2px solid #333;
    border-bottom: 2px solid #333;
}*/

#navbar-profile-mini-menu-toggle{
    display: none;
}
    
#navbar-profile-mini-menu-toggle:checked + .navbar-profile-mini-menu-button-container .navbar-profile-mini-menu-button::before{
    margin-top: 0px;
    transform: rotate(45deg);
}
    
#navbar-profile-mini-menu-toggle:checked + .navbar-profile-mini-menu-button-container .navbar-profile-mini-menu-button{
    background: rgba(255, 255, 255, 0);
}
    
#navbar-profile-mini-menu-toggle:checked + .navbar-profile-mini-menu-button-container .navbar-profile-mini-menu-button::after{
    margin-top: 0px;
    transform: rotate(-45deg);
}

/*-----------------------------------------------*/

.navbar-profile-mini2-menu-button::before{
    content: "";
    margin-top: -8px;
}

.navbar-profile-mini2-menu-button::after{
    content: "";
    margin-top: 8px;
}

.navbar-profile-mini2-menu{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(8, 1fr);
    list-style-type: none;
    padding: 0;
    width: 100%;
    /*box-shadow: -80px 0px 0px 0px #141414, 80px 0px 0px 0px #141414;*/
    position: absolute;
    top: 0;
    margin-top: 70px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 20;
    background-color: rgb(20,20,20);
    color: #141414;
    right: 0%;
}

.navbar-profile-mini2-menu > li{
    margin: 0;
    overflow: hidden;
}

.navbar-profile-mini2 li{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.navbar-profile-mini2-menu-button-container{
    display: none;
    height: 100%;
    width: 30px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navbar-profile-mini2-menu-button{
    display: block;
    background-color: #141414;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 800ms ease-in-out;
    /*border-radius: 2px;*/
}

.navbar-profile-mini2-menu-button-container{
    display: flex;
    padding: 10px;
}

#navbar-profile-mini2-menu-toggle ~ .navbar-profile-mini2-menu li{
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: opacity 5s;
}
    
#navbar-profile-mini2-menu-toggle:checked ~ .navbar-profile-mini2-menu li{
    height: calc((100vh - 70px) / 9);
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

/*#navbar-profile-mini2-menu-toggle:checked ~ .navbar-profile-mini2-menu li:nth-child(even){
    background-color: red;
}*/

/*#navbar-profile-mini2-menu-toggle:checked ~ .navbar-profile-mini2-menu li:nth-child(even){
    border-top: 2px solid #333;
    border-bottom: 2px solid #333;
}*/

#navbar-profile-mini2-menu-toggle{
    display: none;
}

#navbar-profile-mini2-menu-toggle:checked + .navbar-profile-mini2-menu-button-container .navbar-profile-mini2-img{
    transform:scaleX(-1);
}

/*-----------------------------------------------*/

.tooltipContainer{
    background: white;
    padding: 6px 8px;
    border: 1px solid #ccc;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 5px;
    font-family: interRegular;
    border-radius: 6px;
}

.floatInfoBg{
    background-color: rgba(47,47,47,0.9);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: interRegular;
    font-size: 12px;
    color: black;
    z-index: 22;
}

.floatInfoCont{
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    width: 40%;
    min-width: calc(350px - 60px);
    max-width: 444px;
    height: fit-content;
    max-height: calc(90% - 56px);
    border-radius: 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
    padding: 28px;
}

.floatInfoMiniCont{
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    width: 30%;
    min-width: calc(320px - 60px);
    max-width: 371px;
    border-radius: 9px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
    padding: 28px;
}

.floatInfoSectionCont{
    width: 100%;
    height: 100%;
    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 15px;
}

.floatInfoSectionMain{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 6px;
    position: relative;
}

.floatInfoSectionSplit{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    gap: 13px;
}

.floatInfoSectionFull{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}

.floatInfoCloseBtn{
    width: 33px;
    height: 33px;
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
}

.floatInfoCloseBtnImg{
    width: 30px;
    height: 30px;
    filter: brightness(0) invert(0.55);
}

.floatInfoCloseBtn:hover{
    background-color: #8D8D8D;
}

.floatInfoCloseBtn:hover .floatInfoCloseBtnImg{
    filter: brightness(100);
}

.floatInfoSectionTitle{
    font-family: interSemiBold;
    font-size: 33px;
    margin: 0;
    color: #8D8D8D;
}

.floatInfoSectionMiniTitle{
    font-family: interMedium;
    font-size: 20px;
    margin: 0;
    color: #8D8D8D;
}

.floatInfoSectionSeparation{
    width: 100%;
    border-style: solid;
    border-color: #8D8D8D;
}

.floatInfoSectionSubtitle{
    width: 100%;
    font-family: interMedium;
    font-size: 12px;
    text-align: left;
    color: #8D8D8D;
}

.floatInfoSectionDesc{
    width: 100%;
    font-family: interMedium;
    font-size: 13px;
    text-align: left;
    color: #141414;
}

.floatInfoSectionMiniText{
    font-family: interMedium;
    font-size: 13.5px;
    margin: 0;
    color: #141414;
    text-align: center;
}

.floatInfoSectionSplittedBtn{
    width: 100%;
    height: 48px;
    border-radius: 9px;
    font-family: interMedium;
    cursor: pointer;
    border: 1px solid #141414;
    background-color: #E2E2E2;
    color: #141414;
}

.floatInfoDeleteContactBtn{
    background-color: #FFE6E5;
    color: #C59797;
    font-size: 13.5px;
    border: 1px solid #C59797;
}

.floatInfoSelect{
    width: 100%;
    height: 42px;
    font-size: 13.5px;
    font-family: interMedium;
}

.floatInfoDownloadContactBtn{
    background-color: #EAFFEC;
    color: #8EC193;
    font-size: 13.5px;
    border: 1px solid #8EC193;
}

.floatInfoCancelChanges{
    background-color: #FFFFFF;
    color: #141414;
    font-size: 16px;
    border: 1px solid #D9D9D9
}

.floatInfoSaveChanges{
    background-color: #E8542F;
    color: #FFFFFF;
    font-size: 16px;
    border: 1px solid #E8542F
}

.floatInfoDefaultBtn{
    background-color: #FFFFFF;
    color: #141414;
    font-size: 13.5px;
    border: 1px solid #D9D9D9;
}

.floatInfoSectionOption{
    height: 42px;
    background-color: #FFFFFF;
    font-size: 13.5px;
    font-family: interMedium;
    color: #8D8D8D;
    cursor: pointer;
    border-radius: 9px;
}

.floatInfoSectionOption:hover{
    background-color: #E9E9E9;
    cursor: no-drop;
    color: #8D8D8D;
}

.floatInfoSectionLabel{
    width: 100%;
    height: 5px;
    border-radius: 10px;
}

.floatInfoSectionTextarea{
    width: 100%;
    padding: 15px 15px;
    resize: none;
    font-family: interMedium;
    font-size: 13.5px;
    border-radius: 9px;
    border: 1px solid #D9D9D9;
    color: #141414;
    outline: none;
    max-height: 96px;
}

.floatInfoSectionCounter{
    height: fit-content;
    width: 100%;
    text-align: right;
    font-family: interRegular;
    font-size: 14px;
    padding: 0px 10px;
}

.contactFormCont{
    background-color: #F5F5F5;
    width: 40%;
    min-width: 350px;
    max-width: 800px;
    height: fit-content;
    max-height: 90%;
    border-radius: 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
}

.contactInfoCont{
    background-color: #F5F5F5;
    width: 40%;
    min-width: 350px;
    max-width: 800px;
    height: fit-content;
    max-height: 90%;
    border-radius: 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
}

.contactInfoSectionCont{
    width: 100%;
    height: 100%;
    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}

.contactInfoSection{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    min-width: 340px;
    gap: 15px
}

.contactInfoSectionImgCont{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.contactFormClose, .contactInfoClose{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    background-color:#D9D9D9;
    outline: 5px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactFormClose:hover, .contactInfoClose:hover{
    outline-color: #D9D9D9;
    background-color: #E8542F;
}

.contactFormCloseImg{
    width: 80%;
    height: 80%;
    filter: brightness(100);
}

.contactFormSection{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
}

.contactFormSectionTitle{
    padding: 0px;
    font-size: 25px;
    font-family: interSemiBold;
    color: #8D8D8D;
    margin: 0;
}

.contactFormInput{
    width: calc(90% - 20px);
    height: 50px;
    min-height: 50px;
    border-radius: 12px;
    border: 0;
    padding: 0px 10px;
    font-family: interRegular;
    outline: 2px solid transparent;
    color: #5A5A5A;
    resize: none;
    font-size: 15px;
}

.contactFormInput:hover, .contactFormInput:focus{
    outline-color: #D9D9D9;
}

.contactFormTextarea{
    width: calc(90% - 20px);
    border-radius: 12px;
    border: 0;
    padding: 15px 10px;
    font-family: interRegular;
    outline: 2px solid transparent;
    color: #5A5A5A;
    resize: none;
    font-size: 15px;
    max-height: 80px;
}

.contactFormTextarea:hover, .contactFormTextarea:focus{
    outline-color: #D9D9D9;
}

.contactFormTextareaCounter{
    height: fit-content;
    /*width: 90%;*/
    text-align: right;
    font-family: interRegular;
    /*font-size: 14px;*/
    /*Agregado*/
    position: absolute;
    bottom: 3px;
    right: 4px;
    width: auto;
    background-color: #454545;
    border-radius: 3px;
    padding: 2px;
    font-size: 0.6rem;
    pointer-events: none
}

.formTextfxfCounter {
    font-size: 0.5rem;
    right: 20px;
    bottom: 8px;
    pointer-events: none
}

.formSection2x3mCounter {
    bottom: 50px;
    pointer-events: none
}

.formCarousel4x2Counter {
    bottom: 15px;
    pointer-events: none
}

.contactFormProfileCounter {
    position:absolute;
    pointer-events: none;
    right: 5px;
    background-color: #454545;
    font-size: 11px;
    font-family: interRegular;
    padding: 3px;
    border-radius: 5px;
}

.contactFormProfileCounterDesc {
    position:absolute;
    pointer-events: none;
    right: 5px;
    bottom: 3px;
    background-color: #454545;
    font-size: 11px;
    font-family: interRegular;
    padding: 3px;
    border-radius: 5px;
}

.contactFormBtn{
    width: calc(90%);
    height: 50px;
    min-height: 50px;
    cursor: pointer;
    border-radius: 12px;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: #E8542F;
    color: white;
    font-size: 15px;
    font-family: interBold;
    margin-bottom: 20px;
}

.contactTableCont{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 20px;
    background-color: white;
    border-radius: 12px;
    margin-bottom: 50px;
    padding: 15px 10px;
    height: 100%;
}

.contactsTable{
    width: 98%;
    font-family: interSemiBold;
    max-width: 100%;
    background-color: white;
    font-size: 12px;
    color: #5A5A5A;
    border: 0;
    border-collapse: collapse;
}

.contactTableTitleRow{
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9
}

.contactTableContactRow{
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9
}

.contactTableContactRow:nth-child(odd){
    background-color: #F5F5F5;
}

.contactTableTitleText{
    text-align: left;
    padding: 10px;
}

.contactTableContactRowText{
    padding: 0px 10px;
}

.contactLabelCont{
    height: 60px;
    padding: 10px;
}

.contactInfoSectionLabelCont{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 10px;
}

.contactInfoSectionSelect{
    width: 250px;
    height: 40px;
    border-radius: 9px;
    border: 0;
    margin: 0;
    background-color: white;
    font-family: interRegular;
}

.contactTableEditBtn{
    width: 60px;
    height: 40px;
    border-radius: 12px;
    background-color: #415ad2;
    border: 3px solid #415ad2;
    color: white;
    font-family: interRegular;
    margin: 10px;
}

.contactTableEditBtn:hover{
    cursor: pointer;
    background-color: white;
    color: #415ad2;
}

.contactInfoSectionImg{
    width: 60%;
    height: 60%;
}

.contactInfoActionBtn{
    width: 100%;
    height: 90px;
    margin-bottom: 20px;
    margin-top: 15px;
    border-radius: 6px;
    border: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-family: interSemiBold;
    gap: 10px;
}

.contactInfoActionBtn img{
    width: 30px;
    height: 30px;
    filter: invert(1);
}

.contactInfoActionBtn p{
    margin: 0;
}

.contactConfirmDelete{
    width: 100%;
    height: 40px;
    font-family: interSemiBold;
    font-size: 14px;
    border-radius: 9px;
    border: 0;
    cursor: pointer;
}

.contactConfirmDelete:hover{
    border: 2px solid black;
}

.contactsDownloadBtn{
    width: 150px;
    height: 40px;
    font-family: interSemiBold;
    font-size: 14px;
    border-radius: 9px;
    margin-bottom: 15px;
    cursor: pointer;
    background-color: #DDDDDD;
    color: #5A5A5A;
    border: 2px solid #DDDDDD;
}

.contactsDownloadBtn:hover{
    color: white;
    background-color: #E8542F;
}

.watchPassWord{
    position: absolute;
    top: calc(50% - 25px);
    right: 10px;
    height: 50px;
    width: 50px;
    padding: 0;
    border: 0;
    cursor: pointer;
    background-color: transparent;
}

.watchPassWord img{
    width: 50%;
    height: 50%;
}

.watchPassWordConfig{
    position: absolute;
    bottom: 2px;
    right: 10px;
    height: 40px;
    width: 40px;
    padding: 0;
    border: 0;
    cursor: pointer;
    background-color: transparent;
}

.watchPassWordConfig img{
    width: 60%;
    height: 60%;
}

@media only screen and (min-width:1461px){
    .contactTablePhone{
        display: none;
    }
}

@media only screen and (max-width:1460px){
    .contactTableWeb{
        display: none;
    }
}

@media only screen and (min-width:851px){
    .metricContainerBarMobile{
        display: none;
    }
}

@media only screen and (max-width:850px){
    .metricContainerBar{
        display: none;
    }
}

@media only screen and (max-width:600px){
    .contactTableTablet{
        display: none;
    }
}

@media only screen and (min-width:451px){
    .contactLabelMini{
        display: none;
    }
}

@media only screen and (max-width:450px){
    .contactLabel{
        display: none;
    }
}

@media only screen and (max-width:1450px){
    .tutorialVideosCont{
        grid-template-columns: repeat(2, minmax(300px, 1fr));
    }
}

@media only screen and (max-width:1350px){
    .contactFilterSection{
        flex-direction: column;
        gap: 12px;
    }
}

@media only screen and (max-width:1250px){
    .metricsCounterSection{
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 10px));
        gap: 20px;
    }
    
    .metricsCounterCont{
        height: 118px;
        justify-content: flex-end;
        padding: 15px;
        width: calc(100% - 30px);
        gap: 5px;
    }

    .metricsCounterTitle{
        font-size: 18px;
    }

    .metricsCounterDesc{
        font-size: 40px;
    }

    .metricsCounterIcon{
        height: 38px;
        width: 38px;
        top: 14px;
        right: 14px;
    }

    .metricsCounterIcon svg{
        height: 20px;
        width: 20px;
    }

    .metricMap{
        grid-column: 1/4;
        height: 450px;
    }

    .metricPie{
        grid-column: 1;
    }

    .metricSingleBar{
        grid-column: 2/4;
    }
}

@media only screen and (max-width: 1130px){
    .config-container{
        display: flex;
        flex-direction: column;
        height: fit-content;
        justify-content: center;
        align-items: center;
    }

    .config-img{
        display: none;
    }

    .config-form{
        align-items: center;
    }

    .metricsContainer{
        grid-template-columns:repeat(1, 1fr);
    }

    .metricContainerBig{
        grid-column: 1;
        height:400px
    }
}

@media only screen and (max-width: 1120px) {
    .tutorialSearchCont{
        flex-direction: column;
        align-items: flex-start;
    }
}

@media only screen and (max-width: 1000px) {
    .supportSectionImgCont{
        width: 125px;
        height: 125px;
    }

    .supportSection{
        gap: 10px
    }

    .supportSectionTitle{
        font-size: 18px;
    }

    .supportSectionDesc{
        font-size: 14px;
    }
}

@media only screen and (max-width: 950px){
    .dashboard-top{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        row-gap: 5px;
    }
    
    .dashboard-top-btns{
        margin-bottom: 10px;
    }
    .dashboard-top-btn:nth-child(1){
        margin: 0
    }

    .metricVisitorTableSection{
        grid-column: 1/4;
    }

    .metricContactLine{
        grid-column: 1/4;
    }
}

@media only screen and (max-width: 840px){
    .notFoundContainer{
        flex-direction: column;
    }

    .notFoundImg{
        width: 240px;
        height: 240px;
    }

    .notFoundRight{
        width: 90%;
        align-items: center;
        text-align: center;
    }

    .notFoundRight p{
        width: 100%;
        font-size: 60px;
    }

    .notFoundRight span{
        width: 100%;
        margin-bottom: 45px;
        font-size: 18px;
        max-width: 346px;
    }

    .metricPie{
        grid-column: 1/4;
    }

    .metricSingleBar{
        grid-column: 1/4;
    }
}

@media only screen and (max-width: 730px){
    .floatInfoCont, .floatConfirmCont{
        max-height: calc(90% - 100px);
        margin-top: calc(5% + 35px);
    }

    .tutorialVideosCont{
        grid-template-columns: repeat(1, minmax(300px, 1fr));
    }

    .main-title-container{
        padding: 12px 0px;
    }

    .contactSearchFilterCont{
        flex-direction: column;
    }

    .contactSearchBar{
        width: calc(100% - 54px);
    }

    .contactBtnCont button{
        width: 100%;
        padding: 12px 3px;
    }

    .configTypeInputNumber{
        width: 50%;
    }
    
    .configTypeSelect{
        width: 50%;
    }
}

@media only screen and (max-width: 480px){
    .tutorialSearchBtns{
        gap: 6px;
    }

    .tutorialSearchBtn{
        padding: 12px 6px;
    }

    .configTypeBtnCont{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 2;
    }
}

@media only screen and (max-width: 400px){
    .profile-info{
        width: 350px;
    }

    .supportSectionImgCont{
        width: 100px;
        height: 100px;
    }
}