/*body{
    background-color: blue;
}

.landingBG{
    backdrop-filter: blur(70px);
}*/

html{
    color:white;
}

a{
    text-decoration: none;
    color: white;
}

.navbar{
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 90px;
    position: sticky;
    top:0px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(70px);
    -webkit-backdrop-filter: blur(100vh);
    z-index: 1;
}

.navbar a:visited{
    color:white
}

.navbar a:hover{
    color:#E8542F
}

.navbarMini{
    display: none;
}

.navLeft, .navRight, .footImgContainer, .footLiContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footImgContainer a{
    margin-left: 10px;
    margin-right: 10px;
    filter: grayscale();
}

.footImgContainer a:hover{
    filter:none;
    margin-left: 10px;
    margin-right: 10px;
}

.footLiContainer a{
    margin-left: 10px;
    margin-right: 10px;
}

li{
    font-family: nimbusSansBold;
    font-size: 17px;
}

.ppolLogoMini{
    display: none;
}


.navLeft li{
    margin: 0px 20px;
}

.navRight li{
    margin: 0px 10px;
}

.navLeft, .navRight{
    display: flex;
    list-style: none;
}

.navLeft{
    padding: 0px;
    margin-left: 40px;
}

.navRight{
    margin-right: 40px;
    padding: 0px;
}

.ppolLogoContainer{
    display: flex;
}

.btnNav{
    height: 40px;
    width: 116px;
    border-radius: 9px;
    font-size: 16px;
    font-family: nimbusSans;
    color: white;
}

#btnLogin{
    background-color: #111111;
    border: 1px solid white
}

#btnLogin:hover{
    background-color: white;
    color:#000000;
    cursor: pointer;
}

#btnCreate{
    background-color: #E8542F;
    border: 1px solid #E8542F;
}

#btnCreateAlt{
    background-color: transparent;
    border: 1px solid white;
}

#btnCreate:hover, #btnCreateAlt:hover{
    background-color: white;
    color: #000000;
    border: 1px solid white;
    cursor: pointer;
}

.foot{
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items: center;
    height: 250px;
    color: #969696
}

.foot li:hover{
    color:white
}

.foot a:visited{
    color:#969696
}

.footP1{
    font-size: 17px;
    font-family: nimbusSans;
    margin: 0;
}

.footImgContainer{
    display: flex;
    width: 100%;
    justify-content: center;
}

.footImgContainer img{
    width: 52px;
    height: 52px;
}

.footImgContainer img:hover{
    filter: grayscale(1);
    transition: 2s;
}

.footLiContainer{
    display: flex;
    padding: 0px;
    margin: 0;
}

.footLiContainer li{
    margin:0px 20px;
    list-style: none;
}

.footP2{
    font-size: 15px;
    font-family: nimbusSans;
    margin: 0;
}

.mainSection{
    display: grid;
    grid-template-columns: 65% 35%;
    grid-gap: 0px;
    grid-template-rows: 1fr;
    height: fit-content;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 100px;
    max-width: 1120px;
    margin-bottom: 70px;
    /*background: linear-gradient(-45deg, #01242f, #22584f, #368375, #5bbbb1);
	background-size: 400% 400%;
	animation: gradient 3s ease infinite;
	height: 90vh;*/
}

/*@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}*/

.mainSection h1{
    font-family: gilroyBlack;
    font-size: 90px;
    margin-top: 0px;
    color: white;
    width: 100%;
    line-height: 100%;
    background: linear-gradient(90deg, #e8542f, #e8542f, #ee9227 );
    background-size: 200% 200%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: AnimationName 6s ease infinite;
    -moz-animation: AnimationName 6s ease infinite;
    animation: AnimationName 6s ease infinite;
    /*text-shadow: -3px 0 0 rgba(255,0,0,0.7), 3px 0 0 rgba(0,255,255,0.7);*/
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.mainSection span{
    font-family: nimbusSansLight;
    font-size: 24px;
    color: white;
}

.mainSection button{
    height: 60px;
    width: 160px;
    background-color: #E8542F;
    border: 0px;
    border-radius: 12px;
    color: white;
    font-size: 20px;
    font-family: gilroyBold;
    padding: 0;
}

.mainSection button:hover{
    background-color: #BA310F;
    cursor: pointer;
}

.titleContainer{
    grid-column: 1;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    width:100%;
    justify-content:space-between;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 50px;
}

.phoneContainer{
    grid-column: 2;
    grid-row: 1;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 511px;
    /*background: linear-gradient(to right, rgba(39, 39, 39, 0), rgba(39, 39, 39, 1))*/
}

.phoneContainer img{
    height: 115%;   
    max-width: fit-content;
}

.titleContainer a{
    margin:0;
    width: fit-content;
}

.titleContainer span{
    width: 65%;
    margin-bottom: 20px;
}

/*------------------------------------------------------------------------------------------*/

.custom-Section{
    margin-top: 120px;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 335px 1fr;
    height:fit-content;
    row-gap: 50px;
    column-gap: 2%;
    max-width: 1120px;
    margin-bottom: 70px;
}



.custom-Section-Title{
    height: 100%;
    width: 100%;
    grid-column: 1 / 3;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.custom-Section-Title p{
    font-family: gilroyExtraBold;
    font-size: 50px;
    width: 80%;
    margin: 0px;
    line-height: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
}

.custom-Section-Title span{
    font-family: nimbusSansLight;
    font-size: 24px;
    width: 88%;
    margin: 0px;
}

.custom-Section-Title b{
    font-family: nimbusSans;
    width: 80%;
    margin: 0px;
}

.custom-Section-TitleBtn{
    font-family: gilroyBold;
    font-size: 22px;
    color: white;
    border: 0;
    border-bottom: 2px solid white;
    width: 100px;
    background-color: transparent;
    cursor: pointer;
}

.custom-Section-TitleBtn-Pressed{
    font-family: gilroyBold;
    font-size: 22px;
    color: #E8542F;
    border: 0;
    border-bottom: 2px solid #E8542F;
    width: 100px;
    background-color: transparent;
    cursor: pointer;
}

.custom-Section-Card{
    width: 100%;
    height: 100%;
    border: 2px solid #2F2F2F;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
}

.custom-Section-Card img{
    width: fit-content;
    height: fit-content;
}

#custom-Section-Card1{
    grid-column: 3 / 5;
    grid-row: 1;
    align-items: center;
    justify-content: center;
}

#custom-Section-Card2{
    grid-column: 1;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: nimbusSansBold;
    text-align: center;
    color: #969696
}

#custom-Section-Card2 p{
    font-size: 140px;
    margin: 0;
    margin-bottom: -20px;
    margin-top: -25px;
}

#custom-Section-Card2 span{
    font-size: 24px;
    margin: 0;
    width: 50%
}

#custom-Section-Card3{
    grid-column: 2;
    grid-row: 2;
}

#custom-Section-Card4{
    grid-column: 3;
    grid-row: 2;
}

#custom-Section-Card5{
    grid-column: 4;
    grid-row: 2;
}

.custom-Section-Card-Icon{
    margin-top: 30px;
    margin-left: 10%;
}

.custom-Section-Card-Title{
    font-family: nimbusSansBold;
    font-size: 18px;
    width: 80%;
    margin-left: calc(10% - 3px);
    margin-right: 10%;
    margin-top: 15px;
}

.custom-Section-Card-Title b{
    color: #E8542F;
    margin-right: 5px;
}

.custom-Section-Card-Description{
    font-family: nimbusSans;
    font-size: 18px;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 15%;
    margin-top: 20px;
    color: #969696
}
/*------------------------------------------------------------------------------------------*/

.ppolMeUs{
    max-height: 80%;
    margin-left: 10%;
    margin-right: 10%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 3fr;
    grid-gap: 2%;
    margin-top: 120px;
    margin-bottom: 70px;
    max-width: 1120px;
}

.ppolMeUsTitle{
    grid-column: 1 / 3;
    grid-row: 1;
    width: 100%;
}

.ppolMeCard{
    grid-column: 1;
    grid-row: 2;
    width: 100%;
    height: 100%;
    background-color: #272727;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
}

.ppolUsCard{
    grid-column: 2;
    grid-row: 2;
    width: 100%;
    height: 100%;
    background-color: #272727;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
}

.ppolMeUsTitle span{
    font-family: gilroyExtraBold;
    font-size: 50px;
    line-height: 100%;
    margin-bottom: 20px;
}

.ppolMeUsTitle p{
    font-family: nimbusSansLight;
    font-size: 24px;
    width: 80%;
}

.ppolCardDescription{
    font-family: nimbusSans;
    font-size: 20px;
    width: 90%;
    margin: 0px;
    margin-left: 5%;
    margin-right: 5%;
}

.ppolMeUsCard1{
    margin-top: -90px;
    margin-bottom: -82px;
}

.ppolMeUsCard2{
    margin-top: -90px;
    margin-bottom: -68px;
}

.ppolMeBtn{
    width: 90%;
    height: 60px;
    background-color: #E8542F;
    color: white;
    font-family: gilroyBold;
    font-size: 20px;
    border: 0px;
    border-radius: 12px;
    margin-left: calc(50% - 45%);
    margin-right: calc(50% - 45%);
    cursor: pointer;
    margin-top: 40px;
    margin-bottom: 20px
}

.ppolMeBtn:hover{
    background-color: #BA310F;
}

.ppolUsBtn{
    width: 90%;
    height: 60px;
    background-color: #415AD2;
    color: white;
    font-family: gilroyBold;
    font-size: 20px;
    border: 0px;
    border-radius: 12px;
    margin-left: calc(50% - 45%);
    margin-right: calc(50% - 45%);
    cursor: pointer;
    margin-top: 40px;
    margin-bottom: 20px;
}

.ppolUsBtn:hover{
    background-color: white;
    color: #415AD2
}

.ppolMeBanner{
    width: 100%;
}

.ppolUsBanner{
    width: 100%;
}

.ppolBannerCard1{
    width: fit-content;
    height: fit-content;
    position: relative;
    top: -40px;
    left: 270px;
    z-index: 0;
}

.ppolBannerCard2{
    width: fit-content;
    height: fit-content;
    position: relative;
    top: -42px;
    left: 308px;
    z-index: 0;
}

.ppolBannerDescription{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-shrink: 0;
    position: relative;
}

.ppolBannerDescription img{
    margin-left: 5%;
    width: fit-content;
    margin-top: 40px;
    margin-bottom: 20px;
}

/*----------------------------------------------------------------------------------*/

.focusSection{
    margin-top: 120px;
    margin-bottom: 70px;
    width: 80%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-right: 10%;
    max-width: 1120px;
}

.focusSectionTitle{
    font-family: gilroyExtraBold;
    font-size: 50px;
    margin: 0;
    line-height: 100%;
}

.focusSectionTitleMobile{
    display: none;
}

.focusSectionWrapper{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    grid-template-rows: repeat(1, 1fr);
    margin-top: 30px;
}

.focusSectionZone{
    border-radius: 9px;
    border: 2px solid #2F2F2F;
    display: flex;
    flex-direction: column;
}

.focusSectionZone b{
    font-family: nimbusSans;
    color: #E8542F;
    font-size: 18px;
    margin-right: -8px;
}

.focusSectionZone span{
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
}

.focusSectionZoneTitle{
    font-family: nimbusSansBold;
    font-size: 18px;
    margin-bottom: 20px;
    display: flex;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
}

.focusSectionZoneTitle span{
    width: 82%;
}

.focusSectionZoneDescription{
    font-family: nimbusSans;
    font-size: 18px;
    margin-bottom: 40px;
    color: #969696
}

.focusSectionZone img{
    width: fit-content;
    height: fit-content;
    margin-left: 5%;
    margin-top: 25px;
    margin-bottom: 20px;
}

/*---------------------------------------------------------------------------------------------------*/

.faqSection{
    margin-top: 120px;
    margin-bottom: 0px;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 15px;
    max-width: 1120px;
}

.faqSectionTitle{
    grid-column: 1;
    grid-row: 1;
}

.faqSectionTitle p{
    font-family: gilroyExtraBold;
    font-size: 50px;
    margin: 0;
    margin-top: -10px;
}

.faqSectionTitle img{
    margin-top: 10px;
}

.faqSectionQuestions{
    grid-column: 2;
    display: flex;
    flex-direction: column;
    gap: 15px
}

.faqQuestion{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.faqAnswer{
    margin-bottom: 30px;
    display: flex;
    width: 100%;
    display: none;
}

.faqSectionQuestions button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #272727;
    border: 2px solid #707070;
    border-radius: 9px;
    color: white;
    height: fit-content;
}

.faqSectionQuestions button img{
    height: fit-content;
    padding-right: 20px
}

.faqSectionQuestions span{
    padding-left: 30px;
    font-family: nimbusSans;
    font-size: 22px;
    text-align: start;
    padding-right: 50px;
}

.faqAnswerPressed {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.faqAnswerPressed span{
    font-size: 18px;
    color: #969696;
}

#faqQuestion1{
    grid-column: 1 / 3;
    grid-row: 1;
}

#faqQuestion2{
    grid-column: 1 / 3;
    grid-row: 2;
}

#faqQuestion3{
    grid-column: 1 / 3;
    grid-row: 3;
}

#faqQuestion4{
    grid-column: 1 / 3;
    grid-row: 4;
}

#faqQuestion5{
    grid-column: 1 / 3;
    grid-row: 5;
}

#faqQuestionBtn{
    grid-column: 2;
    grid-row: 5;
    background-color: #E8542F;
    border: 0;
    border-radius: 9px;
    font-family: gilroyBold;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#faqQuestionBtn:hover{
    background-color: #BA310F;
}

/*-------------------------------------------------------------------------------*/

.suscriptionSection{
    margin-top: 240px;
    margin-bottom: 70px;
    margin-bottom: 70px;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    text-align: center;
    max-width: 980px;
}

.suscriptionSection p{
    font-family: gilroyExtraBold;
    font-size: 100px;
    margin: 0px;
    background-color: #111111;
    background-image: linear-gradient(to bottom, rgba(232,84,47,1), rgba(232,84,47,1), rgba(232, 84, 47, 0));
    background-size: 100%;
    background-repeat: repeat;
    -webkit-text-fill-color: transparent;
    -moz-background-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.suscriptionSection span{
    font-family: nimbusSans;
    font-size: 24px;
}

.suscriptionSectionForm{
    margin-top: 50px;
    display: grid;
    width: 100%;
    grid-template-columns: 3fr 3fr 1fr;
    grid-gap: 15px;
    grid-template-rows: 1fr;
}

.suscriptionFormInput{
    height: 55px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 9px;
    border: 0px;
    font-family: nimbusSans;
    font-size: 18px;
    color: #878787;
}

.suscriptionFormInput1{
    grid-column: 1;
    grid-row: 1;
}

.suscriptionFormInput2{
    grid-column: 2;
    grid-row: 1;
}

.suscriptionFormInput:focus{
    color: black
}

.suscriptionSectionBtn{
    border: 0px;
    border-radius: 9px;
    background-color: #E8542F;
    font-family: gilroyBold;
    font-size: 20px;
    color: white;
    grid-column: 3;
    grid-row: 1;
}

.suscriptionSectionBtn:hover{
    background-color: #BA310F;
}

/*----------------------------------------------------------------------------------*/

.communitySection{
    margin-top: 120px;
    margin-bottom: 170px;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    grid-template-rows: 1fr 2fr 0.5fr;
    max-width: 1120px;
}

.communitySectionTitle{
    grid-column: 1 / 3;
    grid-row: 1;
    display: flex;
    flex-direction: column;
}

.communitySectionTitle p{
    font-family: gilroyExtraBold;
    font-size: 50px;
    margin: 0px;
    line-height: 100%;
    margin-bottom: 20px;
}

.communitySectionTitle span{
    font-family: nimbusSans;
    font-size: 24px;
    width: 93%;
    margin-bottom: 30px;
}

#communitySectionCard1{
    grid-column: 1;
    grid-row: 2;
}

#communitySectionCard2{
    grid-column: 2;
    grid-row: 2;
}

#communitySectionCard3{
    grid-column: 3;
    grid-row: 2;
}

.communitySectionCard{
    width: 100%;
}

.communitySectionCardInner{
    position: relative;
	height: 100%;
	transform-style: preserve-3d;
	transition: transform 0.5s;
}

.communitySectionCard:hover .communitySectionCardInner{
    transform: rotateY(180deg);
}

.communitySectionCardBody{
    position: absolute;
	inset: 0;
	display: grid;
	place-items: center;
	backface-visibility: hidden;
	border-radius: 9px;
}

.communitySectionCardBodyFront span{
    font-family: gilroyExtraBold;
    font-size: 30px;
}

.communitySectionCardBodyFront img{
    width: fit-content;
}

.communitySectionCardBodyBack{
    transform: rotateY(180deg);
    background-color: #272727;
}

.communitySectionCardBodyBackContainer{
    display: flex;
    width: 95%;
    height: 95%;
    border: 2px solid #3D3D3D;
    border-radius: 9px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #969696;
}

.communitySectionCardBodyBackContainer span{
    font-family: nimbusSans;
    font-size: 20px;
    width: 80%;
    margin-bottom: 20px;
}

.communitySectionCardBodyBackContainer img{
    height: fit-content;
    width: fit-content;
}

.communitySectionCardBodyBack span{
    font-family: nimbusSans;
    font-size: 20px;
}

#communitySectionCardBodyBackContainer1 span, #communitySectionCardBodyBackContainer2 span{
    margin-bottom: 50px;
}

#communitySectionCardBodyFront1{
    background-color: #272727;
    background-image: url("../../images/ppolClubImg.png");
    background-repeat:no-repeat;
    background-position: center;
    background-size: cover;
}

#communitySectionCardBodyFront2{
    background-color: #272727;
    background-image: url("../../images/ppolExperienceImg.png");
    background-repeat:no-repeat;
    background-position: center;
    background-size: cover;
}

#communitySectionCardBodyFront3{
    background-color: #272727;
    background-image: url("../../images/ppolMoreImg.png");
    background-repeat:no-repeat;
    background-position: center;
    background-size: cover;
}

.communitySection a{
    grid-column: 2;
    grid-row: 3;
    width: fit-content;
    height: fit-content;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 30px;
}

.communitySection button{
    background-color: #E8542F;
    height: 55px;
    width: 153px;
    border: 0;
    border-radius: 9px;
    font-family: gilroyBold;
    font-size: 20px;
    color: white;
}

.communitySection button:hover{
    background-color: #BA310F
}

.section-separation{
    background-color: transparent;
    width: 100%;
    height: 10px;
}

.launch-date{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    height: fit-content;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: -8px;
    margin-top: 129px;
}

.launch-date h1{
    font-family: gilroyBlack;
    font-size: 100px;
    color: white;
    margin: 0;
    margin-top: -31px;
}

.launch-date h2{
    font-family: gilroyBlackItalic;
    font-size: 100px;
    color: #939393;
    margin: 0;
    margin-top: -28px
}

.launch-date span{
    font-family: gilroyBold;
    font-size: 100px;
    text-align: center;
    line-height: 100%;
    margin-top: 150px;
    margin-bottom: 300px;
}

.launch-date span span{
    color: #E8542F;
}

.thanks-separation{
    height: 100px;
}

.bannerSection{
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    bottom: calc(0% + 30px);
    z-index: 12;
    margin-bottom: 40px;
}

.bannerSection a{
    max-width: 95%;
    width: fit-content;
    height: fit-content;
}

.bannerSection-img{
    max-width: 100%;
    border-radius: 9px;
    box-shadow: rgba(246, 225, 189, 0.25) 0px 4px 8px -2px, rgba(246, 225, 189, 0.08) 0px 0px 0px 1px;
    /*box-shadow: rgba(255, 255, 255, 0.07) 0px 1px 2px, rgba(255, 255, 255, 0.07) 0px 2px 4px, rgba(255, 255, 255, 0.07) 0px 4px 8px, rgba(255, 255, 255, 0.07) 0px 8px 16px, rgba(255, 255, 255, 0.07) 0px 16px 32px, rgba(255, 255, 255, 0.07) 0px 32px 64px;*/
}

.bannerSection-img:nth-child(1){
    display: flex;
}

.bannerSection-img:nth-child(2){
    display: none;
}

.ppol-whatsapp-contact{
    width: 120px;
    height: 120px;
    position:fixed;
    bottom:0;
    right:0;
    text-decoration: none;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.ppol-whatsapp-contact-img{
    max-width: 72px;
    max-height: 72px;
    animation: glow 1000ms ease-out infinite alternate;
    border-radius: 50%;
}

@keyframes glow{
    0%{
        border-color: #393;
        box-shadow: 0 0 5px rgba(50,187,70,0.1), inset 0 0 5px rgba(0,255,0,0.1), 0 2px 0 #000;
    }
    100%{
        border-bottom: #6f6;
        box-shadow: 0 0 20px rgba(50,187,70,1), inset 0 0 10px rgba(0,255,0,0.4), 0 2px 0 #000;
    }
}

/*----------------------------------------------------------------------------------*/
@media only screen and (max-width: 1400px){
    .launch-date span{
        max-width: 60%;
        margin-top: 100px;
    }
}

@media only screen and (max-width: 1350px){
    .communitySection{
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr 1.4fr 1fr;
    }

    .communitySectionTitle p{
        line-height: 100%;
    }
}

@media only screen and (max-width: 1301px){
    .ppolMeBtn{
        margin-top: 65px;
    }
}

@media only screen and (max-width: 1287px){
    .mainSection h1{
        margin-bottom: 40px;
    }

    .titleContainer span{
        width: 65%;
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 1198px){
    .communitySection{
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr 1.3fr 1.3fr 1fr;
    }

    .communitySectionTitle{
        grid-column: 1 / 4;
    }

    #communitySectionCard1{
        grid-column: 1 / 3;
    }

    #communitySectionCard2{
        grid-column: 3 / 5;
    }

    #communitySectionCard3{
        grid-column: 2 / 4;
        grid-row: 3;
    }

    .communitySection a{
        grid-column: 2 / 4;
        grid-row: 4;
        display: flex;
        justify-self: center;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 1190px){
    .launch-date span{
        max-width: 80%;      
    }
}

@media only screen and (max-width: 1180px){
    .ppolMeUsCard1, .ppolMeUsCard2{
        display: none;
    }

    .ppolMeUs{
        grid-template-rows: 1fr 2fr
    }
}

@media only screen and (max-width: 1140px){
    .communitySection{
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr 1.5fr 1.5fr 1fr;
    }
}

@media only screen and (max-width: 1080px){
    .mainSection h1{
        margin-bottom: 0px;
    }

    .titleContainer span{
        width: 65%;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 1093px){
    .suscriptionSection p{
        line-height: 90%;
        margin-bottom: 60px;
        background-image: linear-gradient(to bottom, rgba(232,84,47,1), rgba(232,84,47,1), rgba(232,84,47,1), rgba(232, 84, 47, 0));
    }

    
}

@media only screen and (max-width: 1080px){

    

    .faqSection{
        margin-bottom: 180px;
    }

    .suscriptionSection{
        margin-top: 110px;
    }

    .navLeft{
        display: none;
    }

    .navRight{
        display: none;
    }

    .navbar{
        height: 70px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .navbarMini{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10%;
        padding-right: 10%;
    }

    .menu li{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #242424;
        font-family: nimbusSansBold;
        font-size: 24px;
    }

    .navBtnMini{
        height: 51px;
        width: 130px;
        margin: 10px;
        border-radius: 9px;
        font-size: 16px;
        font-family: nimbusSans;
        color: white;
        cursor: pointer;
    }

    #navBtnMini1{
        background-color: transparent;
        border: 1px solid white;
    }

    #navBtnMini1:hover{
        background-color: white;
        color: black;
    }

    #navBtnMini2{
        background-color: #E8542F;
        border: 0
    }

    #navBtnMini2:hover{
        background-color: #BA310F;
    }
    
    #navBtnMini2Alt{
        background-color: transparent;
        border: 1px solid white;
    }

    #navBtnMini2Alt:hover{
        background-color: white;
        color: black;
    }

    #navCopyright{
        color: #969696;
        font-family: nimbusSans;
        font-size: 16px;
        padding-top: 1em;
        padding-bottom: 1em;
    }

    nav ul{
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .menu-button::before{
        content: "";
        margin-top: -8px;
    }
    
    .menu-button::after{
        content: "";
        margin-top: 8px;
    }

    .menu{
        display: flex;
        flex-direction: row;
        list-style-type: none;
        padding: 0;
        width: 100%;
        border-radius: 9px;
        box-shadow: -80px 0px 0px 0px #141414, 80px 0px 0px 0px #141414;
        position: absolute;
        top: 0;
        margin-top: 70px;
        left: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .menu > li{
        margin: 0;
        overflow: hidden;
    }
    
    .menu-button-container{
        display: none;
        height: 100%;
        width: 30px;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .menu-button, .menu-button::before, .menu-button::after{
        display: block;
        background-color: white;
        position: absolute;
        height: 4px;
        width: 30px;
        transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
        border-radius: 2px;
    }
    
    .menu-button-container{
        display: flex;
        padding: 10px;
    }
    
    #menu-toggle ~ .menu li{
        height: 0;
        margin: 0;
        padding: 0;
        border: 0;
        transition: opacity 5s;
    }
        
    #menu-toggle:checked ~ .menu li{
        height: 2.5em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    #menu-toggle:checked ~ .menu li:nth-child(even){
        border-top: 2px solid #333;
        border-bottom: 2px solid #333;
    }

    #menu-toggle{
        display: none;
    }
        
    #menu-toggle:checked + .menu-button-container .menu-button::before{
        margin-top: 0px;
        transform: rotate(45deg);
    }
        
    #menu-toggle:checked + .menu-button-container .menu-button{
        background: rgba(255, 255, 255, 0);
    }
        
    #menu-toggle:checked + .menu-button-container .menu-button::after{
        margin-top: 0px;
        transform: rotate(-45deg);
    }

    .footLiContainer{
        flex-direction: column;
        padding: 20px;
    }

    .footLiContainer a{
        padding: 10px;
        width: 150%;
        text-align: center;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footLiContainer a li{
        font-size: 24px;
        font-family: nimbusSansBold;
        color: white;
    }

    .footLiContainer a li:hover{
        color: #E8542F;
    }

    .footLiContainer a:nth-child(odd){
        border-top: 2px solid #303030;
        border-bottom: 2px solid #303030;
    }

    .footP1{
        padding-top: 40px;
        padding-bottom: 10px;
    }

    .foot{
        height: fit-content;
    }

    .footP2{
        margin-bottom: 20px;
    }

    .footImgContainer img{
        width: 45px;
        height: 45px;
    }

    .footImgContainer {
        margin-bottom: 10px;
    }

    .ppolLogo2{
        display: none;
    }

    .ppolLogoMini{
        display: flex;
        /* width: (100% - 230px); */
        width: 90px;
        height: auto;
    }

    .ppolLogoNavLi{
        display: none;
    }

    .mainSection{
        grid-template-columns: 1fr;
        grid-gap: 15px;
        grid-template-rows: 1fr 1fr;
    }

    .titleContainer{
        grid-row: 1;
        grid-column: 1;
    }

    .phoneContainer{
        grid-row: 2;
        grid-column: 1;
        display: flex;
        width: 100%;
        justify-content: center;
        /*background: linear-gradient(to bottom, rgba(39, 39, 39, 0), rgba(39, 39, 39, 1))*/
    }

    .custom-Section{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
        row-gap: 12px;
    }

    .custom-Section-Title{
        grid-row: 1;
        grid-column: 1 / 3;
    }

    #custom-Section-Card1{
        grid-row: 2;
        grid-column: 1 / 3;
    }

    .custom-Section-Card-CardImg{
        max-width: 100%;
        max-height: 100%;
    }

    #custom-Section-Card2{
        grid-row: 3;
        grid-column: 1;
    }

    #custom-Section-Card3{
        grid-row: 3;
        grid-column: 2;
    }

    #custom-Section-Card4{
        grid-row: 4;
        grid-column: 1;
    }

    #custom-Section-Card5{
        grid-row: 4;
        grid-column: 2;
    }

    .focusSectionWrapper{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    #focusSectionZone1{
        grid-row: 1;
        grid-column: 1;
    }

    #focusSectionZone2{
        grid-row: 1;
        grid-column: 2;
    }

    #focusSectionZone3{
        grid-row: 2;
        grid-column: 1;
    }

    #focusSectionZone4{
        grid-row: 2;
        grid-column: 2;
    }

    
}

@media only screen and (max-width: 900px){
    .launch-date span{
        max-width: 100%;      
    }

    .communitySectionTitle{
        grid-column: 1 / 4;
    }

    .communitySectionCard img{
        max-width: 80%;
    }

    .focusSectionWrapper{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    .faqSection{
        display: flex;
        flex-direction: column;
    }

    .faqSectionTitle{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 15px;
        margin-bottom: 20px;
    }

    .suscriptionSection{
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }

    .faqSectionTitle{
        flex-direction: column;
        align-items: flex-start;
    }

    .custom-Section-Title p{
        width: 100%;
    }

    .custom-Section-Title span{
        width: 100%;
    }

    .ppolMeUsTitle p{
        width: 100%;
    }

    .faqSectionTitle p{
        line-height: 100%;
    }
}

@media only screen and (max-width: 837px){
    .mainSection h1{
        margin-bottom: 40px;
    }

    .titleContainer span{
        width: 65%;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 800px){
    .ppolMeUs{
        grid-template-rows: 0.5fr 1fr 1fr;
    }

    .ppolMeCard {
        grid-column: 1 / 3;
        grid-row: 2;
    }

    .ppolUsCard {
        grid-column: 1 / 3;
        grid-row: 3;
    }

    .launch-date h1{
        font-size: 75px;
    }

    .launch-date h2 {
        margin-top: -15px;
    }

    .bannerSection-img:nth-child(1){
        display: none;
    }
    
    .bannerSection-img:nth-child(2){
        display: flex;
    }
}

@media only screen and (max-width: 765px){
    .communitySection{
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr 1.2fr 1.2fr 1fr;
    }

    .suscriptionSectionForm{
        grid-template-rows: repeat(3, 60px);
        grid-template-columns: 1fr;
    }

    .suscriptionFormInput1{
        grid-row: 1;
        grid-column: 1;
    }

    .suscriptionFormInput2{
        grid-row: 2;
        grid-column: 1;
    }

    .suscriptionSectionBtn{
        grid-row: 3;
        grid-column: 1;
    }
    
    .launch-date span{
       font-size: 75px;      
    }
}

@media only screen and (max-width: 745px){
    .ppolMeBtn{
        margin-top: 40px;
    }
}

@media only screen and (max-width: 660px){
    .launch-date span{
        margin-top: 80px;
    }

    .launch-date{
        margin-bottom: 250px;
        margin-top: 160px;
    }

    .titleContainer{
        font-size: 45px;
    }

    .phoneContainer img{
        max-width: 80%;
        max-height: 90%;
    }

    .communitySection{
        grid-template-rows: 1fr 1fr 1fr 1fr 0.5fr
    }

    .communitySectionTitle{
        grid-column: 1 / 5;
        grid-row: 1;
    }
    
    #communitySectionCard1{
        grid-column: 1 / 5;
        grid-row: 2;
    }

    #communitySectionCard2{
        grid-column: 1 / 5;
        grid-row: 3;
    }

    #communitySectionCard3{
        grid-column: 1 / 5;
        grid-row: 4;
    }

    .communitySection a{
        grid-column: 2 / 4;
        grid-row: 5;
    }

    .custom-Section{
        grid-template-rows: 1fr 0.5fr 1fr 1fr 1fr;
    }

    .focusSectionTitle{
        font-size: 40px;
        text-align: left;
        width: 98%;
    }

    .focusSectionWrapper{
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
    }

    #focusSectionZone1 {
        grid-row: 1;
        grid-column: 1;
    }

    #focusSectionZone2 {
        grid-row: 2;
        grid-column: 1;
    }

    #focusSectionZone3 {
        grid-row: 3;
        grid-column: 1;
    }

    #focusSectionZone4 {
        grid-row: 4;
        grid-column: 1;
    }

    .communitySectionTitle p{
        font-size: 40px;
        margin-bottom: 20px;
    }

    .faqSectionTitle p{
        font-size: 40px;
    }

    .ppolMeUsTitle span {
        font-size: 40px;
    }

    .custom-Section-Title p {
        font-size: 40px;
        line-height:40px;
        margin-top: 15px
    }

    .mainSection h1 {
        font-size: 60px;
        font-family: gilroyBold;
        text-align: left;
        margin-bottom: 40px;
    }

    .titleContainer span {
        width: 100%;
        margin-bottom: 40px;
    }

    .mainSection {
        grid-template-rows: 1fr auto;
        margin-top: 50px;
    }

    .custom-Section{
        grid-template-rows: 1fr 0.9fr 0.9fr 0.9fr 0.9fr 0.9fr;
        grid-template-columns: 100%;
        grid-gap: 30px;
    }

    .custom-Section-Title{
        grid-row: 1;
        grid-column: 1;
    }

    #custom-Section-Card1{
        grid-row: 2;
        grid-column: 1;
    }

    #custom-Section-Card2{
        grid-row: 3;
        grid-column: 1;
    }

    #custom-Section-Card3{
        grid-row: 4;
        grid-column: 1;
    }

    #custom-Section-Card4 {
        grid-row: 5;
        grid-column: 1;
    }

    #custom-Section-Card5 {
        grid-row: 6;
        grid-column: 1;
    }

    .suscriptionSection p{
        font-size: 80px;
    }

    .focusSectionTitle{
        display: none;
    }

    .focusSectionTitleMobile{
        display: block;
    }

    .launch-date img{
        max-width: 100%;
    }
}

@media only screen and (max-width: 635px){
    .ppolMeBtn{
        margin-top: 65px;
    }
}

@media only screen and (max-width: 540px){
    .launch-date span{
        font-size: 60px; 
        margin-top: 0px;     
    }

    .thanks-separation-top{
        margin-top: -80px;
    }

    .thanks-separation{
        height: 0px;
        margin-bottom: -100px;
    }
}

@media only screen and (max-width: 522px){
    .ppolMeBtn{
        margin-top: 40px;
    }
}

@media only screen and (max-width: 520px){
    .launch-date h1{
        font-size: 60px;
    }      

    .launch-date h2{
        font-size: 80px;
    }
}

@media only screen and (max-width: 508px){
    .ppolMeBtn{
        margin-top: 65px;
    }
}

@media only screen and (max-width: 450px){
    .launch-date h1{
        font-size: 50px;
    } 

    .launch-date h2{
        font-size: 67px;
    }

    .communitySection{
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 434px){
    .ppolMeUsTitle span{
        line-height: 100%;
    } 

    .launch-date span{
        font-size: 50px;      
    }
}

@media only screen and (max-width: 332px){
    .communitySection{
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }
}

@media only screen and (min-width: 1401px){
    .mainSection, .custom-Section, .communitySection, .ppolMeUs, .focusSection, .faqSection{
        margin-left: calc(50% - 560px);
        margin-right: calc(50% - 560px);
    }

    .suscriptionSection{
        margin-left: calc(50% - 490px);
        margin-right: calc(50% - 490px);
    }
}