/*------ REACT DATE PICKER ------*/

.react-datepicker-wrapper{
    width: 100%;
}

/*------ LOGIN/REGISTER ------*/
.authContainer{
    width: 100%;
    min-height: 100vh;
    background-color: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.authSectionContainer{
    width: 100%;
    min-height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.authFormContainer{
    width: 375px;
    max-width: 90%;
    height: fit-content;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 80px;
    margin: 80px 0;
}

.authLogoContainer{
    width: 100%;
    height: 3vh;
}

.authLogo{
    object-fit: contain;
    margin: auto;
    width: 100%;
    height: 100%;
    filter: invert(0.8);
    -webkit-filter: invert(0.8);
}

.authTitleContainer{
    width: 100%;
    height: fit-content;
    text-align: center;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.authTitle{
    margin: 0;
    font-family: interBold;
    color: var(--ppolOrange);
    font-size: 50px;
}

.authText{
    margin: 0;
    font-family: interRegular;
    font-size: 18px;
    color: var(--ppolTextBlack);
}

.authForm{
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
}

.authFormSection{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
}

.authFormInput{
    width: calc(100% - 40px);
    padding: 18px 20px;
    border: 0;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    background: #FFFFFF;
    border: 1px solid #D7D7D7;
    color: #878787;
    font-size: 18px;
    font-family: interRegular;
}

.authFormInputPw{
    padding: 18px 60px 18px 20px;
    width: calc((100% - 80px));
}

.authFormLink{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #878787;
    font-family: interRegular;
    font-size: 16px;
    text-decoration: underline;
}

.authFormBtn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--ppolOrange);
    color: #FFFFFF;
    padding: 18px 20px;
    font-family: interMedium;
    font-size: 18px;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    margin: 0;
    border: 0;
}

.authFormBtn:hover{
    background-color: #FF8061;
}

.authFormAddBtn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: #878787;
    padding: 20px;
    font-family: interRegular;
    font-size: 16px;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    margin: 0;
    border: 1px dashed #D7D7D7;
}

.authFormAddBtn:hover{
    background-color: #E9E9E9;
    border-color: transparent;
}

.authFormErrorText{
    color: #E32B2B;
    font-size: 14px;
    font-family: interRegular;
    text-align: center;
    width: 100%;
}

.authFormInputUser{
    width: calc(100% - 105px);
    padding: 18px 20px 18px 85px;
}

.authFormInputUserText{
    position: absolute;
    left: 20px;
    font-family: interRegular;
    font-size: 18px;
    color: #878787;
}

.authFormSeparation{
    width: 100%;
    background-color: #DDDDDD;
    height: 1px;
    border: 0;
    margin: 15px 0px;
}

.authFormText{
    font-family: interRegular;
    color: #878787;
    text-align: left;
    font-size: 14px;
}

.authFormTextLink{
    font-family: interBold;
    color: #878787;
}

/*------ FOOTER ------*/
.authFooter{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    margin: 20px 0px;
}

.authFooterText{
    font-family: interRegular;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -2%;
    padding: 0;
    text-align: center;
    color: #979797;
}