/*----------QR GENERATOR----------*/

.qrGenerator{
    width:100%;
    min-height: 100vh;
    background-color: var(--ppolBlack);
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column
}

.qrGeneratorImg{
    margin-top:80px
}

.qrGeneratorTitle{
    margin:20px 0px;
    font-family: interBold;
    font-size: 60px;
    color: var(--ppolOrange);
}

.qrGeneratorInfo{
    width:90%;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    display:flex
}

.qrGeneratorInput{
    width:584px;
    height:46px;
    background-color:transparent;
    border:1px solid white;
    border-radius:24px;
    color:white;
    padding:0px 16px;
    outline:none;
    font-size:16px;
    font-family: interRegular;
}

.qrGeneratorOptions{
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin:20px 0px;
    gap:40px
}

.qrGeneratorSelect{
    width:120px;
    height:40px;
    margin:0px 4px;
    padding:0px 16px;
    background-color:white;
    border:2px solid white;
    border-radius:9px;
    cursor:pointer;
    font-family: interRegular;
    font-size: 12px;
}

.qrGeneratorBtn{
    width:120px;
    height:40px;
    margin:0px 4px;
    padding:0px 16px;
    background-color:white;
    border:2px solid white;
    border-radius:9px;
    cursor:pointer;
    font-family: interRegular;
    font-size: 12px;
}

.qrGeneratorBtn:hover{
    background-color: #E8542F;
    color: #141414;
    cursor: pointer;
}

option{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.qrCodesContainer{
    display:flex;
    flex-wrap:wrap;
    gap:20px;
    justify-content:center;
    margin-top:80px;
    margin-bottom:80px
}

.qrCodeContainer{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    gap:8px;
    font-size:15px
}

.qrCodeContainerMedia{
    max-width: 150px;
    max-height: 150px;
    width: 150px;
    height: 150px;
}

.qrContainerTitle{
    font-family: interRegular;
}

/*----------CODE GENERATOR----------*/

.codeGenerator{
    width:100%;
    min-height: 100vh;
    background-color: #141414;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column
}

.codeGeneratorImg{
    margin-top:40px
}

.codeGeneratorTitle{
    margin:40px 0px;
    font-family: interBold;
    font-size: 60px;
    color: var(--ppolOrange);
}

.codeGeneratorInfo{
    width:90%;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    display:flex;
    gap: 40px;
}

.codeGeneratorNumber{
    background-color: white;
    border: 0;
    border-radius: 9px;
    height: 44px;
    width: 150px;
    padding: 0px 10px;
    font-family: interRegular;
    font-size: 14px;
}

.codeGeneratorNumber:focus{
    outline: 3px solid white;
    outline-offset: 2px;
}

.codeGeneratorBtn{
    width:150px;
    height:44px;
    margin:0px 4px;
    padding:0px 16px;
    background-color:white;
    border:2px solid white;
    border-radius:9px;
    cursor:pointer;
    font-family: interRegular;
    font-size: 14px;
}

.codeGeneratorBtn:hover{
    color: #141414;
    outline: 3px solid white;
    outline-offset: 2px;
}

.codeGeneratorBtn:active{
    background-color: #EEEEEE;
    border:2px solid #EEEEEE;
    outline: 3px solid #EEEEEE;
}

.codesGenerated{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:10px;
    margin: 80px 0px;
}

.codeGenerated{
    font-family: interRegular;
    margin: 0;
}

.copyCodesBtn{
    width:150px;
    height:44px;
    margin:0px 4px;
    padding:0px 16px;
    background-color:#E8542F;
    border:3px solid #E8542F;
    border-radius:9px;
    cursor:pointer;
    font-family: interRegular;
    font-size: 14px;
    color: white;
}

.copyCodesBtn:hover{
    outline: 3px solid #E8542F;
    outline-offset: 2px;
    border: 3px solid white;
}

.copyCodeTitle{
    font-family: interRegular;
    font-size: 16px;
}

/*----------USER CREATOR----------*/

.userGenerator{
    width:100%;
    min-height: 100vh;
    background-color: #141414;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column
}

.userGeneratorImg{
    margin-top:80px
}

.userGeneratorTitle{
    margin:20px 0px;
    font-family: interBold;
    font-size: 60px;
    color: var(--ppolOrange);
}

.userGeneratorInfo{
    width:90%;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    display:flex;
    gap: 15px;
}

.userGeneratorInfoTitle{
    margin: 0;
    font-family: interRegular;
    font-size: 16px;
}

.userGeneratorInfoDescription{
    margin: 0;
    font-family: interRegular;
    font-size: 14px;
}

.userGeneratorInput{
    background-color: white;
    border: 0;
    border-radius: 9px;
    height: 44px;
    width: 450px;
    padding: 0px 10px;
    font-family: interRegular;
    font-size: 14px;
}

.userGeneratorInput:focus{
    outline: 3px solid white;
    outline-offset: 2px;
}

.userGeneratorBtn{
    width:150px;
    height:44px;
    margin:0px 4px;
    padding:0px 16px;
    background-color:white;
    border:2px solid white;
    border-radius:9px;
    cursor:pointer;
    font-family: interRegular;
    font-size: 14px;
}

.userGeneratorBtn:hover{
    color: #141414;
    outline: 3px solid white;
    outline-offset: 2px;
}

/*----------USER INFO----------*/

.utilities-profile-info{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px dashed white;
    padding: 20px;
    width: 390px;
    height: fit-content;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    position: relative;
}

.utilities-profile-loadinginfo{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 40px;
    border: 2px dashed transparent;
    padding: 20px;
    border-radius: 6em 9px 9px 6em;
    width: 440px;
    height: 150px;
}

.utilities-profile-title{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

.utilities-profile-bannerImg{
    margin-bottom: 50px;
    width: 100%;
    height: 160px;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    object-fit: cover;
}

.utilities-profile-photoImg{
    width: 145px;
    height: 145px;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    top: 73px;
}

.utilities-profile-verifiedIcon{
    width: 24px;
    height: 24px;
    z-index: 1;
    background-color: transparent;
    position: absolute;
    top: 185px;
    left: calc(50% + 35px);
}

.utilities-profile-button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 6px);
    height: 50px;
    border-radius: 9px;
    background-color: #415AD2;
    color: white;
    outline: 0;
    margin: 0;
    padding: 0;
    font-family: interMedium;
    font-size: 13.5px;
    cursor: pointer;
    border: 3px solid #415AD2;
}

.utilities-profile-button:hover{
    border-color: white;
}

.utilities-profile-username{
    font-family: gilroyBold;
    font-size: 22px;
    color: white;
    width: 100%;
    text-align: center;
}

.utilities-profile-code{
    font-family: gilroyMedium;
    font-size: 18px;
    color: white;
    width: 100%;
    text-align: center;
}